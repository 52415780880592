import { ToastContainer } from 'react-toastify';
import './App.css';
import Navigations from './Components/Common/Navigators/Navigations';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { RootState } from './store';

function App() {

  const user = useSelector((state: RootState) => state.user);
  document.body.className = user.darkMode ? 'dark-mode' : '';
  return (
    <>
      <ToastContainer
        closeButton
        newestOnTop
      />
      <Navigations />
    </>
  );
}

export default App;
