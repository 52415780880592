import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from './Slice/User/User';
import { jobSlice } from './Slice/Jobs/Jobs';
import { JobApi } from './Slice/Jobs/JobApi';
import { UserApi } from './Slice/User/UserApi';
import { ApplicationSlice } from './Slice/Application/ApplicationSlice';
import { OrgSlice } from './Slice/Org/Org';
import { ApplicantSlice } from './Slice/Applicant/ApplicantSlice';
import { AccountSlice } from './Slice/Account/Account';
import { CompanyJobsSlice } from './Slice/ComapanyJobs/ComapnyJob';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    job: jobSlice.reducer,
    application: ApplicationSlice.reducer,
    applicants: ApplicantSlice.reducer,
    account:AccountSlice.reducer,
    comapanyJob:CompanyJobsSlice.reducer,
    organization: OrgSlice.reducer,
    [JobApi.reducerPath]: JobApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,

  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(JobApi.middleware, UserApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;