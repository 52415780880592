import { useEffect } from 'react';
import { Formik, FormikProps } from "formik";
import SideMenu from "../SideMenu/SideMenu";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { AccountInfoValidationSchema } from "../../Forms/AccountInfo/AccountInfo";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useDispatch } from "react-redux";
import { createOrgDetails } from "../../store/Slice/Org/OrgsThunk";
import { toast } from "react-toastify";

const CreateAccountInfo = () => {
    const org = useSelector((state: RootState) => state.organization);
    const dispatch = useDispatch<AppDispatch>();
    const submit = (e: any) => {
        dispatch(createOrgDetails({ ...e, admin_id: 1, plan_id: 1 }));
    }
    useEffect(() => {
        if (org.orgDetailsIsValid) {
            toast.success('Data saved success fully')
        }
    }, [org.isLoading, org.orgDetailsIsValid])
    return <>
        <Row>
            <Col xs={3} >
                <SideMenu />
            </Col>
            <Col xs={8}>
                <h1>ACCOUNT INFO</h1>
                <Card className="border-0">
                    <CardBody>
                        <Formik onSubmit={e => { submit(e) }} initialValues={{
                            cin_number: "",
                            name: "",
                            address: "",
                            accountOwner: "",
                            email: "",
                            invoiceEmail: "",
                            individualName: "",
                            domain: ""
                        }}
                            validationSchema={AccountInfoValidationSchema}>
                            {(props: FormikProps<any>) => (
                                <Form>
                                    <Row>
                                        <Col xs={2} className="d-flex justify-content-center">
                                            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                                                <div className="m-auto">1</div>
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <h4>General Information</h4>

                                            <FormGroup>
                                                <Label for="cin_number">
                                                    Company Reg No.(CIN Number)
                                                </Label>
                                                <Input invalid={props.errors?.cin_number !== undefined}
                                                    onChange={(e: any) =>
                                                        props.setFieldValue("cin_number", e.target.value)
                                                    } />
                                                <FormFeedback>
                                                    <>{props.errors?.cin_number}</>
                                                </FormFeedback>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="name">
                                                    Company Name
                                                </Label>
                                                <Input invalid={props.errors?.name !== undefined}
                                                    onChange={(e: any) =>
                                                        props.setFieldValue("name", e.target.value)
                                                    } />
                                                <FormFeedback>
                                                    <>{props.errors?.name}</>
                                                </FormFeedback>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="address">
                                                    Address
                                                </Label>
                                                <Input invalid={props.errors?.address !== undefined}
                                                    onChange={(e: any) =>
                                                        props.setFieldValue("address", e.target.value)
                                                    } />
                                                <FormFeedback>
                                                    <>{props.errors?.address}</>
                                                </FormFeedback>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="accountOwner">
                                                    Account Owner
                                                </Label>
                                                <Input invalid={props.errors?.accountOwner !== undefined}
                                                    onChange={(e: any) =>
                                                        props.setFieldValue("accountOwner", e.target.value)
                                                    } />
                                                <FormFeedback>
                                                    <>{props.errors?.accountOwner}</>
                                                </FormFeedback>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="domain">
                                                    Domain
                                                </Label>
                                                <Input invalid={props.errors?.domain !== undefined}
                                                    onChange={(e: any) =>
                                                        props.setFieldValue("domain", e.target.value)
                                                    } />
                                                <FormFeedback>
                                                    <>{props.errors?.domain}</>
                                                </FormFeedback>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="contact">
                                                    Contact
                                                </Label>
                                                <Input invalid={props.errors?.contact !== undefined}
                                                    onChange={(e: any) =>
                                                        props.setFieldValue("contact", e.target.value)
                                                    } />
                                                <FormFeedback>
                                                    <>{props.errors?.contact}</>
                                                </FormFeedback>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="email">
                                                    Email
                                                </Label>
                                                <Input invalid={props.errors?.email !== undefined}
                                                    onChange={(e: any) =>
                                                        props.setFieldValue("email", e.target.value)
                                                    } />
                                                <FormFeedback>
                                                    <>{props.errors?.email}</>
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={2} className="d-flex justify-content-center">
                                            <div className="d-flex h4" style={{ backgroundColor: "#6A7580", color: "#fff", borderRadius: "50%", height: 50, width: 50 }}>
                                                <div className="m-auto">2</div>
                                            </div>
                                        </Col>
                                        <Col xs={10}>
                                            <h4>Email address for invoices</h4>
                                            <p>We do not print invoices on paper. We send invoices in a PDF form from instead. Type in the email address that shoud recive invoices from us and name of the individual responsible for invoices at your company.</p>
                                            <FormGroup>
                                                <Label for="invoiceEmail">
                                                    Email Address
                                                </Label>
                                                <Input invalid={props.errors?.invoiceEmail !== undefined}
                                                    onChange={(e: any) =>
                                                        props.setFieldValue("invoiceEmail", e.target.value)
                                                    } />
                                                <FormFeedback>
                                                    <>{props.errors?.invoiceEmail}</>
                                                </FormFeedback>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="individualName">
                                                    Name of the individual
                                                </Label>
                                                <Input invalid={props.errors?.individualName !== undefined}
                                                    onChange={(e: any) =>
                                                        props.setFieldValue("individualName", e.target.value)
                                                    } />
                                                <FormFeedback>
                                                    <>{props.errors?.individualName}</>
                                                </FormFeedback>
                                            </FormGroup>
                                            <div className="text-center">
                                                <Button color="primary" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    props.submitForm()
                                                }} type="submit">
                                                    {org.isLoading ? <Spinner /> : <>Save</>}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}

                        </Formik>



                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}
export default CreateAccountInfo;