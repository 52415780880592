import './CandidatesPage.css';
import { Col, Input, Row } from "reactstrap";
import { AiOutlineSearch } from 'react-icons/ai';
import { useState, lazy, Suspense } from 'react';
import CandidateProfileCardSkeleton from '../../Components/Common/CandidateProfileCard/CandidateProfileCardSkeleton';
import CandidateProfileDetailCardSkeleton from '../../Components/Common/CandidateProfileDetailCard/CandidateProfileDetailCardSkeleton';
const CandidateProfileCard = lazy(() => import("../../Components/Common/CandidateProfileCard/CandidateProfileCard"));
const CandidateProfileDetailCard = lazy(() => import("../../Components/Common/CandidateProfileDetailCard/CandidateProfileDetailCard"));
const CandidatesPage = () => {
    const [detailView, setDetailsView] = useState<boolean>(false);

    return <>
        <div>
            <div>
                <h1 style={{ textAlign: "center", fontSize: 60, fontWeight: 400 }}>HIRE 200,000+ REMOTE WORKERS
                    FROM WORLD WIDE
                </h1>
                <Row className="justify-content-center m-4">
                    <Col xs={6} className="form-group has-search">
                        <AiOutlineSearch className="fa fa-search form-control-feedback p-2" />
                        <Input type="text" className="form-control" placeholder="your next candidate?" />

                    </Col>
                </Row>
            </div>
            <div className='mt-5'>
                <h2 className='text-center'>👨‍💻 Expert Developers</h2>
            </div>
            <Row className="mt-3">
                <Col xl={detailView ? 6 : 12} md={detailView ? 4 : 12} >
                    <Row>
                        {new Array(7).fill(1).map((_: any, index: number) => {
                            return <Col md={detailView ? 12 : 4} key={index} xxl={detailView ? 6 : 3} className="p-2 candidate-profile-box" onClick={() => setDetailsView(true)}>
                                <Suspense fallback={<CandidateProfileCardSkeleton />}>
                                    <CandidateProfileCard />
                                </Suspense>
                            </Col>
                        })}
                    </Row>
                </Col>
                {detailView ?
                    <Col xl={6} md={8}>
                        <Suspense fallback={<CandidateProfileDetailCardSkeleton />}>
                            <CandidateProfileDetailCard detailView={detailView} setDetailsView={setDetailsView} />
                        </Suspense>
                    </Col>
                    :
                    null}
            </Row>
        </div >
    </>
}
export default CandidatesPage