import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface IJobs {
    is_valid: Boolean;
    data: [];
    error: boolean;
}
export const JobApi = createApi({
    reducerPath: 'jobApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://backend-dev.teqhire.com' }),

    endpoints: (builder) => ({
        getJobs: builder.query({
            query: () => '/job/',
            transformResponse: (result: IJobs, meta) => {
                return {
                    valid: result.is_valid,
                    data: result.data,
                    error: false
                };
            },
        }),
        getJobDetails: builder.query({
            query: ({ jobId }: { jobId: string }) => `/job/${jobId}`,
            transformResponse: (result, meta) => {
                return result
            }
        }),
        createJobPost: builder.mutation({
            query: ({ job }) => ({
                url: `/job/`,
                body: job,
                method: 'POST'
            }),
            transformResponse: (result, meta) => {
            },
            transformErrorResponse(baseQueryReturnValue, meta, arg) {

            },
        })
    })
});

export const { useGetJobsQuery, useGetJobDetailsQuery, useCreateJobPostMutation } = JobApi;