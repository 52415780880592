import { Button, Col, Row } from "reactstrap";
import jobs from '../../data/jobs.json';
import { useEffect } from 'react';
import './jobDescription.css';
import JobCard from "../../Components/Common/JobCard/JobCard";
import { lazy, Suspense } from 'react';
import SideMenuSkeleton from "../../Components/Common/Skeletons/SideMenuSkeleton";
import { JobDescriptionMainSkeleton } from "./jobDescriptionSkeletons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { appliedJobs, readJob } from "../../store/Slice/Jobs/JobsThunk";
import { AppDispatch, RootState } from "../../store";
import { useSelector } from "react-redux";
const SideMenu = lazy(() => import("../../Components/SideMenu/SideMenu"));
const JobDetails = lazy(() => import('./JobDetails'));

const JobDescription = () => {
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const job = useSelector((state: RootState) => state.job);
    useEffect(() => {
        dispatch(appliedJobs())
    }, [dispatch])
    useEffect(() => {
        if (!params?.jobId) {
            navigate("/not-found")
        }
        dispatch(readJob({ jobId: params?.jobId }))
    }, [dispatch, params?.jobId, navigate])
    return <><Row>
        <Col xs={3} >
            <Suspense fallback={<SideMenuSkeleton />}>
                <SideMenu />
            </Suspense>
        </Col>
        <Col xs={9}>
            <Suspense fallback={<JobDescriptionMainSkeleton />}>
                {job.isLoading === false && job.jobDetails !== null ? <JobDetails /> : null}
            </Suspense>
        </Col >

    </Row >
        {jobs.map((job: any, index: number) => {
            return <JobCard id={job.id} key={index} applications={job.applications} image={job.image} languages={job.languages} location={job.location}
                salary={job.salary} title={job.title} type={job.type} />
        })}
        <div className="d-flex justify-content-center mt-3">
            <Button color="secondary">See more</Button>
        </div>
    </>

}

export default JobDescription;