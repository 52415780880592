import { Button, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { IoLockClosed } from 'react-icons/io5';
const UnAuthorized = () => {
    return <Col className='text-center'>
        <h1><IoLockClosed /></h1>
        <h1 className='text-center'>
            403
        </h1>
        <p className="lead text-muted">Oops, an error has occurred. Access Forbidden!</p>
        <Col >
            <div className="btn-group btn-group-justified d-flex justify-content-center">
                <Link to="/" ><Button color='primary'>Return Home</Button></Link>
            </div>
        </Col>
    </Col>
}
export default UnAuthorized;

