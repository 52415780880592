import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { FiMonitor } from 'react-icons/fi';
import { Link } from "react-router-dom";
const SoftwareDevelopment = [
    "Backend", "Front-end", "Database", "Design", "DevOps", "Testing", "Graphics Design", "Hardware"
]
const RegisterNext = () => {
    return <>
        <Row className="justify-content-center">
            <h1 className="text-center">WHAT FIELDS INTEREST ME?</h1>
            <h6 className="text-center">Please select  to five areas of work that best
                align with your profile</h6>

            <Card className="mt-5">
                <CardBody className="m-3">
                    <Row className="d-flex justify-content-between">
                        <div className="w-auto">
                            <FiMonitor fontSize={40} />{" "}
                            <span>Software Development</span>
                        </div>
                        <div className="w-auto">
                            237 jobs available
                        </div>
                    </Row>
                    <Row className="mt-3">
                        {SoftwareDevelopment.map((software) => {
                            return <Col sm={3} className="p-2">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" />{' '}
                                        {software}
                                    </Label>
                                </FormGroup>
                            </Col>
                        })}
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody className="m-3">
                    <Row className="d-flex justify-content-between">
                        <div className="w-auto">
                            <img src="/svg/Vector (1).svg" alt="marketing" />{" "}
                            <span>Marketing &
                                Sales</span>
                        </div>
                        <div className="w-auto">
                            237 jobs available
                        </div>
                    </Row>
                    <Row className="mt-3">
                        {SoftwareDevelopment.map((software) => {
                            return <Col sm={3} className="p-2">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" />{' '}
                                        {software}
                                    </Label>
                                </FormGroup>
                            </Col>
                        })}
                    </Row>
                </CardBody>
            </Card>

            <Card>
                <CardBody className="m-3">
                    <Row className="d-flex justify-content-between">
                        <div className="w-auto">
                            <img src="/svg/noun-work-3632619 3.svg" alt="marketing" />{" "}
                            <span>Management</span>
                        </div>
                        <div className="w-auto">
                            237 jobs available
                        </div>
                    </Row>
                    <Row className="mt-3">
                        {SoftwareDevelopment.map((software) => {
                            return <Col sm={3} className="p-2">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" />{' '}
                                        {software}
                                    </Label>
                                </FormGroup>
                            </Col>
                        })}
                    </Row>
                </CardBody>
            </Card>

            <Card>
                <CardBody className="m-3">
                    <Row className="d-flex justify-content-between">
                        <div className="w-auto">
                            <img src="/svg/Vector (1).svg" alt="marketing" />{" "}
                            <span>Analytics</span>
                        </div>
                        <div className="w-auto">
                            237 jobs available
                        </div>
                    </Row>
                    <Row className="mt-3">
                        {SoftwareDevelopment.map((software) => {
                            return <Col sm={3} className="p-2">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" />{' '}
                                        {software}
                                    </Label>
                                </FormGroup>
                            </Col>
                        })}
                    </Row>
                </CardBody>
            </Card>
            <Row className="mt-4">
                <Col className="d-flex justify-content-center">
                    <Link to={"/profile"}>
                        <Button color="primary">Go Back to Profile</Button>
                    </Link>
                </Col>
            </Row>
        </Row>
    </>
}
export default RegisterNext;