import { createSlice } from '@reduxjs/toolkit'
import { loginUser, registeUser } from './UserThunk';
import jwt_decode from "jwt-decode";

export const userDetails: any = (token: string) => {
    const decoded = jwt_decode(token);
    return decoded;
}
export const localStorageUser = () => {
    const user = localStorage.getItem("userToken");
    return user;
}
const preUserDetails = () => {
    const token = localStorageUser();
    return token ? userDetails(token) : {}
}
export interface IUserState {
    isLoading: boolean;
    userData: {
        name?: string;
        id?: string;
        userName?: String;
        email?: string;
        contactNo?: string;
        avatar?: string;
        role?: string;
        permission?: Object;
        isVerified?: Boolean;
        account_type_id?: number | null;
        isOrg?: Boolean;
        orgId?: string;
    },
    successMsg: object;
    darkMode: boolean;
    userToken?: string | null;
    error?: string | null;
    success: boolean;
    loginRequired: boolean;
}
const userInitialState: IUserState = {
    isLoading: false,
    userData: preUserDetails(),
    userToken: localStorageUser(),
    error: null,
    successMsg: {},
    darkMode: false,
    success: false,
    loginRequired: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState: userInitialState,
    reducers: {
        setLoginRequired: (state, action) => {
            state.loginRequired = !state.loginRequired;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setToken: (state, action) => {
            state.userToken = action.payload;
        },
        setSuccessMessage: (state) => {
            state.successMsg = {};
            state.success = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
        setDarkMode: (state) => {
            state.darkMode = !state.darkMode;
        },
        logout: (state) => {
            state.userData = {};
            state.userToken = null
        }
    },
    extraReducers(builder) {
        builder.addCase(loginUser.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.isLoading = false;
            const user = userDetails(action.payload);
            console.log(user)
            state.userToken = action.payload;
            state.userData = { ...user };
            state.success = true;
        });
        builder.addCase(loginUser.rejected, (state, action) => {
            // console.log(action.payload)
            state.isLoading = false;
            state.userData = {};
            //@ts-ignore
            state.error = action.payload;
        });


        builder.addCase(registeUser.pending, (state, action) => {
            state.isLoading = true;
            state.userToken = null;
        });

        builder.addCase(registeUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = true;
            state.successMsg = action.payload
        });

        builder.addCase(registeUser.rejected, (state, action) => {
            state.isLoading = false;
            state.success = false;
            //@ts-ignore
            state.error = action.payload;
        })
    },
});

export const { setUserData, setError, setIsLoading, setToken, setSuccess, setDarkMode, setSuccessMessage, logout, setLoginRequired } = userSlice.actions;

export default userSlice.reducer;