import { useSelector } from "react-redux";
import { Card, CardBody, CardFooter } from "reactstrap";
import { RootState } from "../../../store";
import './CandidateProfileCard.css';
import Skeleton from "react-loading-skeleton";

const CandidateProfileCardSkeleton = () => {

    const darkMode = useSelector((state: RootState) => state.user?.darkMode);
    return <Card className={'candidate-profile-card ' + (darkMode ? 'dark-mode ' : ' ')}>
        <CardBody>
            <div className="w-100 candidate-image-section">
                <Skeleton className="candidate-image w-100" containerClassName="m-auto" style={{width: "fit-content" }} />
            </div>
            <p className='text-center mt-2'>
                <Skeleton count={2} />
            </p>
            <div className='text-center mt-3'>
                <Skeleton count={1} />
            </div>
            <div className='text-center mt-3'>
                <span className="badge bg-light text-dark ms-2" >
                    {/* <ProgrammingLangIcon programmingLanguage='javascript' /> javascript</span> */}
                </span>
                <span className="badge bg-light text-dark ms-2">
                    {/* <ProgrammingLangIcon programmingLanguage='typescript' /> Typescript  */}
                </span>
            </div>
        </CardBody>
        <CardFooter className={' border-0 ' + (darkMode ? 'dark-mode ' : '  bg-white ')}>
            <Skeleton count={1} />
        </CardFooter>
    </Card>
}
export default CandidateProfileCardSkeleton;