import { RiDeleteBinLine } from "react-icons/ri"
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap"
import { languagesValidationSchema } from "../../../Forms/CreateJobPost/CreateJobPost"
import { Formik, FormikProps } from "formik"
import Select, { components } from 'react-select';
import { Language } from "../../CandidateProfile/Language";
const { SingleValue, Option, MultiValueContainer } = components;
interface ILanguageFormProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    jobPost: object;
    setJobPost: React.Dispatch<React.SetStateAction<object>>;
}
const LanguagesForm = ({ current, setCurrent, jobPost, setJobPost }: ILanguageFormProps) => {
    //@ts-ignore
    const { language, proficiency } = jobPost
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        setJobPost({ ...jobPost, ...e })
    }
    const IconSingleValue = (props: any) => (
        <SingleValue {...props}>
            <Language language={props.data.image} />
            {props.data.label}
        </SingleValue>
    );

    const IconOption = (props: any) => (
        <Option {...props}>
            <Language language={props.data.image} />
            {props.data.label}
        </Option>
    );
    const ISelectContainer = (props: any) => {
        return <MultiValueContainer {...props}>
            <Language language={props.data.image} />
            <span className="m-auto p-2">{props.data.label}</span>
        </MultiValueContainer>
    }
    return <Formik
        initialValues={{ language: language, proficiency: proficiency }}
        validationSchema={languagesValidationSchema}
        onSubmit={e => { submitForm(e) }}
    >
        {(props: FormikProps<any>) => (
            <Row className="mt-3">
                <Col xs={2} className="d-flex justify-content-center">
                    <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                        <div className="m-auto">{current + 1}</div>
                    </div>
                </Col>
                <Col xs={10}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <h4>Languages</h4>
                        </div>
                        <div>
                            <Button color="primary">Add Language</Button>
                        </div>
                    </div>
                    <Row>
                        <Col sm={5}>
                            <FormGroup>
                                <Label for="language">
                                    Language
                                </Label>
                                {/* <Input type="select" onChange={e => {
                                    props.setFieldValue("language", e.target.value);
                                }} invalid={props?.errors?.language !== undefined}>
                                    <option value={""}>--select--</option>
                                    <option>English</option>
                                </Input> */}
                                <Select
                                    styles={{
                                        option: (defaultStyles, state) => ({
                                            ...defaultStyles,
                                            backgroundColor: (state.isFocused || state.isSelected) ? "#AFE581" : "#fff"
                                        }),
                                        control: (defaultStyles) => ({
                                            ...defaultStyles,
                                            borderColor: props.errors?.language ? "#dc3545" : "#ced4da",
                                        }),

                                        singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
                                    }}
                                    name="language"
                                    closeMenuOnSelect={false}
                                    onChange={(newValue) => {
                                        props.setFieldValue("language", newValue.length === 0 ? null : newValue);
                                    }}
                                    value={props?.values?.language}
                                    required
                                    isSearchable
                                    isMulti
                                    onBlur={() => {
                                        props?.setFieldTouched("language", true)
                                    }}
                                    options={[
                                        { value: "1", label: "English", image: "English" },
                                        { value: "2", label: "Croatian", image: "Croatian" }
                                    ]}
                                    components={{
                                        Option: IconOption,
                                        SingleValue: IconSingleValue,
                                        MultiValueContainer: ISelectContainer
                                    }}
                                />
                                <div className="text-danger">
                                    <>{props?.errors?.language}</>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col sm={5}>
                            <FormGroup>
                                <Label for="proficiency">
                                    Proficiency
                                </Label>
                                <Input type="select"
                                    value={props?.values?.proficiency}
                                    onChange={e => {
                                        props.setFieldValue("proficiency", e.target.value);
                                    }} invalid={props?.errors?.proficiency !== undefined}>
                                    <option value={""}>--select--</option>
                                    <option>Advanced</option>
                                </Input>
                                <FormFeedback>
                                    <>{props?.errors?.proficiency}</>
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm={2} className="d-flex">
                            <RiDeleteBinLine className="m-auto" fontSize={25} />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between">
                        <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                        <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                    </div>
                </Col>
            </Row>
        )}
    </Formik>
}
export default LanguagesForm