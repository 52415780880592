import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { BASE_URL, COMPANY_JOB_API } from "../../constants";
import { setLoginRequired } from "../User/User";

export const getCompanyJobs = createAsyncThunk<any, any, { state: RootState }>('comapnyjob/comapanyReadJob', async (_, thunkApi) => {
    try {
        const user = thunkApi.getState().user
        if (!user.userToken) {
            thunkApi.dispatch(setLoginRequired({}));
            return thunkApi.rejectWithValue({ loginRequired: true });
        }
        const account = thunkApi.getState().account;
        const readJobRes = await fetch(BASE_URL + COMPANY_JOB_API + "?org_id=" + account.accountDetails?.org_id);
        if (readJobRes.status === 200) {
            const res = await readJobRes.json();
            return res;
        }
        else {
            return new Error(await readJobRes.json())
        }
    } catch (err) {
        return thunkApi.rejectWithValue(err);
    }
})