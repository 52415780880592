import { MdOutlineModeEditOutline } from "react-icons/md";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { EditProfileEducationSchema } from "../../../Forms/EditProfile/EditProfile";
import { Form, Formik, FormikProps } from "formik";
interface IEditProfileProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    profile: Object;
    setProfile: React.Dispatch<React.SetStateAction<Object>>;
}
const Education = ({ current, setCurrent, profile, setProfile }: IEditProfileProps) => {
    //@ts-ignore
    const { schoolName, educationLevel, descipline, sinceMonth, sinceYear, untilMonth, untilYear } = profile;
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        setProfile({ ...profile, ...e })
    }
    return <Row>
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">{current + 1}</div>
            </div>
        </Col>
        <Col xs={10} >
            <div className="d-flex justify-content-between">
                <div>
                    <h4>Education</h4>
                </div>
                <div>
                    <h4><MdOutlineModeEditOutline /> Edit</h4>
                </div>
            </div>
            <Formik initialValues={{
                schoolName: schoolName,
                educationLevel: educationLevel,
                descipline: descipline,
                sinceMonth: sinceMonth,
                sinceYear: sinceYear,
                // stillStuding: false,
                untilMonth: untilMonth,
                untilYear: untilYear
            }} validationSchema={EditProfileEducationSchema} onSubmit={e => { submitForm(e) }}>
                {(props: FormikProps<any>) => (
                    <Form>
                        <FormGroup>
                            <Label for="schoolName">
                                School Name *
                            </Label>
                            <Input invalid={props.errors?.schoolName !== undefined} onChange={(e: any) => {
                                props.setFieldValue("schoolName", e.target.value)
                            }} value={props?.values?.schoolName || ''} />
                            <FormFeedback>
                                <>{props.errors?.schoolName}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="educationLevel">
                                Education Level*
                            </Label>
                            <Input invalid={props.errors?.educationLevel !== undefined}
                                value={props?.values?.educationLevel || ''}
                                onChange={(e: any) => {
                                    props.setFieldValue("educationLevel", e.target.value)
                                }} />
                            <FormFeedback>
                                <>{props.errors?.educationLevel}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="descipline">
                                Discipline*
                            </Label>
                            <Input invalid={props.errors?.descipline !== undefined}
                                value={props?.values?.descipline || ''}
                                onChange={(e: any) => {
                                    props.setFieldValue("descipline", e.target.value)
                                }} />
                            <FormFeedback>
                                <>{props.errors?.descipline}</>
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" />{' '}
                                Still studying
                            </Label>
                        </FormGroup>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="sinceMonth">
                                        Since *
                                    </Label>
                                    <Input type="select"
                                        value={props?.values?.sinceMonth || ''}
                                        invalid={props.errors?.sinceMonth !== undefined} onChange={(e: any) => {
                                            props.setFieldValue("sinceMonth", e.target.value)
                                        }}  >
                                        <option value="">--select--</option>
                                        {Array.from(Array(12).keys()).map((month: number) => {
                                            return <option key={month} value={month + 1}>{month + 1}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>
                                        <>{props.errors?.sinceMonth}</>
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="sinceYear">
                                        &nbsp;
                                    </Label>
                                    <Input type="select"
                                        value={props?.values?.sinceYear || ''}
                                        invalid={props.errors?.sinceYear !== undefined} onChange={(e: any) => {
                                            props.setFieldValue("sinceYear", e.target.value)
                                        }}  >
                                        <option value="">--select--</option>
                                        {Array.from(Array(32).keys()).map((month: number) => {
                                            return <option key={month} value={month + 1}>{month + 1}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>
                                        <>{props.errors?.sinceYear}</>
                                    </FormFeedback>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="untilMonth">
                                        Until *
                                    </Label>
                                    <Input type="select"
                                        value={props?.values?.untilMonth || ''}
                                        invalid={props.errors?.untilMonth !== undefined} onChange={(e: any) => {
                                            props.setFieldValue("untilMonth", e.target.value)
                                        }}  >
                                        <option value="">--select--</option>
                                        {Array.from(Array(31).keys()).map((month: number) => {
                                            return <option key={month} value={month + 1}>{month + 1}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>
                                        <>{props.errors?.untilMonth}</>
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="untilYear">
                                        &nbsp;
                                    </Label>
                                    <Input type="select"
                                        value={props?.values?.untilYear || ''}
                                        invalid={props.errors?.untilYear !== undefined} onChange={(e: any) => {
                                            props.setFieldValue("untilYear", e.target.value)
                                        }}  >
                                        <option value="">--select--</option>
                                        {Array.from(Array(32).keys()).map((month: number) => {
                                            return <option key={month} value={month + 1}>{month + 1}</option>
                                        })}
                                    </Input>
                                    <FormFeedback>
                                        <>{props.errors?.untilYear}</>
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-between mt-4">
                            <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                            <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                        </div>
                    </Form>
                )}
            </Formik>

        </Col>
    </Row>
}
export default Education;