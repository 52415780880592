import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Card, CardBody } from "reactstrap";
import './CandidateProfileDetailCard.css';
import Skeleton from "react-loading-skeleton";

const CandidateProfileDetailCardSkeleton = () => {
    const darkMode = useSelector((state: RootState) => state.user?.darkMode);
    return <Card className={' detail-view ' + (darkMode ? 'dark-mode ' : ' ')}>
        <CardBody className='profile-body'>
            <div className='info'>
                <Skeleton circle className='avatar' containerClassName="avatar" />
            </div>
            <div className='mt-3'>
                <h2 className='username text-center'>
                    <Skeleton />
                </h2>
            </div>
            <div className='profile-bio text-center mt-4'>
                <Skeleton count={5} />
            </div>


            <div className='w-100 mt-3'>
                <table className="profile_info">
                    <tbody>
                        {new Array(15).fill(1).map((_: any, index: number) => {
                            return <tr className="link">
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

            <div className="mt-4">
                <h2 className='text-center'>
                    <Skeleton />
                </h2>
                {new Array(3).fill(1).map((_: any, index: number) => {
                    return <p className="p-2 border-bottom">
                        <Skeleton />
                    </p>
                })}
            </div>
            <div className='mt-4'>
                <h2 className='text-center'>
                    <Skeleton />
                </h2>
                <p className="border-bottom p-3">
                    <Skeleton />
                </p>
            </div>
        </CardBody>
    </Card >
}
export default CandidateProfileDetailCardSkeleton;