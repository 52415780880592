import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Col, Input, Nav, NavItem, Row } from "reactstrap";
import { RootState } from "../../../store";

const Footer = () => {
    const darkMode = useSelector((state: RootState) => state.user.darkMode);
    const darkText = darkMode ? 'dark-mode-text' : '';
    return <>
        <footer className={"mt-5 p-5 " + (darkMode ? 'dark-mode' : '')}>
            <div className="d-flex justify-content-between flex-wrap">
                <img src="/Logo.png" alt="logo" />
                <div className="d-flex" style={{ gap: 25 }}>
                    <img src="/svg/Facebook.svg" alt="facebook" />
                    <img src="/svg/instagram.svg" alt="instagram" />
                    <img src="/svg/Twitter.svg" alt="twiiter" />
                </div>
            </div>
            <Row className="mt-5">
                <Col xs={6}>
                    <Row>
                        <Col xs={3}>
                            <p><b>Services</b></p>
                            <Nav className="mr-auto footer" navbar>
                                <NavItem>
                                    <NavLink className={darkText} to="/">Email Marketing</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={darkText} to="/jobs">Campaigns</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={darkText} to="/candidates">Brands</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={darkText} to="/profile">Offline</NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col xs={3}>
                            <p><b>About</b></p>
                            <Nav className="mr-auto footer" navbar>
                                <NavItem>
                                    <NavLink className={darkText} to="/">Our story</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={darkText} to="/jobs">Benifits</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={darkText} to="/candidates">Team</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={darkText} to="/profile">Careers</NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col xs={3}>
                            <p><b>Legal</b></p>
                            <Nav className="mr-auto footer  " navbar>
                                <NavItem>
                                    <NavLink className={darkText} to="/">Terms & Conditions</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={darkText} to="/jobs">Privacy Policy</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={darkText} to="/candidates">Terms of use</NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Col>
                <Col xs={6}>
                    <p><b>Subscribe now</b></p>
                    <div className="input-group mb-3">
                        <Row className="w-75">
                            <Input type="text" className="subscribe-email" placeholder="Enter your email address" />
                        </Row>
                        <div className="subsribe-button">
                            <Button className="" color="primary">Subscribe</Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-5">
                © TeqHire {new Date().getFullYear()}
            </Row>
        </footer>
    </>
}

export default Footer;