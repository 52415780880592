import { useEffect } from 'react';
import { IShieldWarrierProps } from "../../../types/Types";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from "../../../store";
import { setLoginRequired } from "../../../store/Slice/User/User";

const ShieldWarrier = ({ children }: IShieldWarrierProps) => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    useEffect(() => {
        if (!user?.userToken) {
            navigate("/login");
            dispatch(setLoginRequired({}))
        }
        if (user?.userData?.account_type_id && user?.userData?.account_type_id === 6) {
            navigate("/unauthorized");
        }
    }, [dispatch, user?.userToken, user?.userData?.account_type_id, navigate])
    return <>{children}</>
}
export default ShieldWarrier;