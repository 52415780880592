import * as Yup from "yup";
export const EditProfileValidationSchema = Yup.object({
    mobile: Yup.string().nullable().required("Phone no. is required").length(10, "Mobile No. should be 10 characters long."),
    email: Yup.string().nullable()
        .email("Invalid format of email")
        .required("Email is required"),
    linkedIn: Yup.string().nullable().url("Must be valid url").optional(),
    fname: Yup.string().required("First name is required."),
    lname: Yup.string().required("Last name is required.")
});

export const EditProfileWorkExperienceSchema = Yup.object({
    // positionName: Yup.string().nullable().required("Name of position is required."),
    positionName: Yup.array().max(1, "Sorry but you need to select only one position name.").of(Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required()
    })).required("Position name is required."),
    // companyName: Yup.string().nullable().required("Company Name is required."),
    companyName: Yup.array().max(1, "Sorry but you need to select only one company name.").of(Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required()
    })).required("Company name is required."),
    since: Yup.string().nullable().required("Since is required."),
    Until: Yup.string().nullable().required("Until is required."),
    skills: Yup.string().nullable().required("Skills is required."),
    summary: Yup.string().optional()
});
export const EditLanguagesValidaitonSchema = Yup.object({
    languages: Yup.array().of(Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required()
    })).required("Languages is required.")
})

export const EditSkillsValidaitonSchema = Yup.object({
    skills: Yup.array().of(Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required()
    })).required("Skills are required."),
    additionalSkills: Yup.array().of(Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required()
    })).required("Additional skills are required.")
})

// export const EditPortfolioValidationSchema = Yup.object().shape({
//     portfolio: Yup.array()
//         .of(
//             Yup.object().shape({
//                 link: Yup.string()
//                     .ensure().url("")
//                     .required("Link is required"),
//             })
//         )
//         .required("Portfolio are required.")
// })


export const EditPortfolioValidationSchema = Yup.object().shape({
    portfolio: Yup.array(
        Yup.string().url("Link is invalid.").required("Link is required.")
    ).min(1, "atleast 1")
        .required("Portfolio are required.")
});

export const EditCVValidationSchema = Yup.object().shape({
    CV: Yup.mixed().required("Please upload CV."),
    summary: Yup.string().optional()
})
// export const EditPortfolioValidationSchema = Yup.object().shape({
//     portfolio: Yup.array().of(Yup.string().url("Link must be url.")),
// });

export const EditProfileAboutMe = Yup.object({
    aboutMe: Yup.string().nullable().required("About is required.")
});

export const EditProfileEducationSchema = Yup.object({
    schoolName: Yup.string().nullable().required("School Name is required."),
    educationLevel: Yup.string().nullable().required("Education Level is required."),
    descipline: Yup.string().nullable().required("Descipline is required."),
    sinceMonth: Yup.string().nullable().required("Month is required."),
    sinceYear: Yup.string().nullable().required("Year is required."),
    // stillStuding:Yup.boolean().when("")
    untilMonth: Yup.string().nullable().required("Month is required."),
    untilYear: Yup.string().nullable().required("Year is required.")
})