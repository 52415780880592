import { Link } from "react-router-dom";
import { useState } from 'react';
import { Button, Card, CardBody, Col, Collapse, Row, Spinner } from "reactstrap";
import { ProgrammingLangIcon } from "../../CandidateProfile/Language";
import { LuClock9 } from 'react-icons/lu';
import { IoGameControllerOutline } from 'react-icons/io5'
import './JobCard.css';
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { MdOutlineDrafts } from "react-icons/md";
import { BsBookmark } from "react-icons/bs";
import TimeAgo from "timeago-react";
import { useDispatch } from "react-redux";
import { applyToJob } from "../../../store/Slice/Jobs/JobsThunk";
interface IJobCardProps {
    id: number;
    title: string,
    salary: string,
    location: string,
    type: string,
    languages: [],
    image: string,
    applications: string,
    currency?: string;
    createdAt?: string;
    details?: any;
    applied?: boolean;
}
const JobCard = (props: IJobCardProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const darkMode = useSelector((state: RootState) => state.user?.darkMode);
    const [detailView, setDetailsView] = useState<boolean>();
    const job = useSelector((state: RootState) => state.job);
    const applied = job.appliedJobs?.find(applied => applied.job_id.toString() === props.id.toString());
    return <><Card className={" mt-4 jobCard  " + (darkMode ? 'dark-mode ' : ' ') + (detailView ? 'jobCard-radius' : ' ')} onClick={() => setDetailsView(!detailView)}
    >
        <CardBody className="job-card-body">
            <div className="d-flex justify-content-between align-items-center">
                <div className="post-image d-flex align-items-center">
                    <div className={"box-1 bg-white p-3 shadow rounded d-flex align-items-center " + (!detailView ? "box-transform" : "")}>
                        {/* <img src="https://dummyimage.com/100x100/f5f5f5/000" alt="image" /> */}
                        <img src={props.image ? props.image : '/company/Company.png'} alt='img' className="logo-image" height={100} width={100} />
                    </div>
                </div>
                <div className="post-desc">
                    <h2 className="fw-bolder job-title fs-4">{props.title}</h2>
                    <div className="">
                        <span className="fs-6">Windy, Remote</span>{" "}<span color="primary" className="badge bg-danger text-uppercase fw-bolder">Urgent</span>
                    </div>
                    <div className="mt-2">
                        Benefits:{" "}
                        <span>
                            <LuClock9 aria-hidden="true" />{" "}
                            <IoGameControllerOutline aria-hidden="true" />{" "}
                            <MdOutlineDrafts aria-hidden="true" />
                        </span>
                    </div>
                </div>
                <div className="post-salary">
                    <div className="lan-flag text-center">
                        {props?.languages?.map((lang: any, index: number) => {
                            return <span className="badge bg-light text-dark ms-1" key={index}>
                                <ProgrammingLangIcon programmingLanguage={lang} />
                                {" "}
                                {lang}
                            </span>
                        })}
                    </div>
                    <div className="salary mt-2 text-capitalize text-center">
                        salary : <span>{props.salary} {props.currency}</span>
                    </div>
                </div>
                <div className="post-time">
                    <span className="">
                        <TimeAgo
                            datetime={props.createdAt ? props.createdAt : Date.now()}
                            locale='as-IN'
                        /></span>
                </div>
                <div className="post-details">
                    <div>
                        <Link onClick={() => { document.documentElement.scrollTop = 0; }} to={`/job-description/${props.id}`}><Button type="button" color="primary">See more</Button></Link>
                        <div className="text-center mt-3">{props.applications} Applicants</div>
                    </div>
                </div>
                <div className="bookmark">
                    <BsBookmark />
                </div>
            </div>
        </CardBody>
    </Card >

        <Collapse isOpen={detailView}>
            <Card className={"job-card-details " + (darkMode ? 'dark-mode ' : ' ')}>
                <CardBody>
                    <div className="d-flex justify-content-around align-items-center">
                        <div className="p-5">
                            <div className="float-end post-image d-inline-block job-detail-job-card align-items-center p-3">
                                <div className="box-1 bg-white p-3 rounded  align-items-center d-flex justify-content-center">
                                    <img src={props.image ? props.image : '/company/company.png'} alt='img' className="company-image-detail" height={100} width={100} />
                                </div>

                                <h2 className="text-center m-3">Google</h2>
                                <div>
                                    {applied === undefined || props.applied === false ? <Button color="primary" className="w-100" onClick={() => {
                                        dispatch(applyToJob({
                                            job_id: props.id,
                                            "account_id": 1,
                                            "resume": "https://www.linkedin.com/in/yash-pritwani-2904",
                                            "status": true
                                        }));
                                    }}>{job?.applying ?
                                        <Spinner /> :
                                        <>Apply Now</>
                                        }</Button> : <Button className="mt-3 text-center w-100" disabled>✅ You Applied</Button>}
                                </div>
                                <div className="mt-3 text-center">
                                    {props.applications ? <>✅ {props.applications}+ applied</> : null}
                                </div>
                            </div>

                            <h3>About Google</h3>

                            <p>Localcoin is a rapidly-growing company that is revolutionizing the way people buy and sell digital currencies.
                                Our mission is to empower individuals to join the cryptocurrency economy and make it more accessible for everyone.
                                As Canada's largest Bitcoin ATM network,
                                we offer an expanding product line that enables crypto-enthusiasts to easily participate in the digital currency world.
                            </p>

                            <p> We are passionate about what we do and we believe that success comes from being creative and innovative.
                                As a member of our team, you will work alongside a talented and dynamic group of individuals who will support your growth and
                                development in your career. We are a startup that fosters a culture of transparency, collaboration, and innovation.
                                If you are a self-starter looking for a challenging and rewarding role in a dynamic and fast-paced environment, we want
                                to hear from you. Join us and be a part of the future of digital currencies!
                            </p>
                            <h3>Job description</h3>
                            <div dangerouslySetInnerHTML={{ __html: props.details }} />
                            {/* <p>
                            At Vexl, we believe that without the freedom to transact we have no other rights. We're hard-core bitcoiners and we understand that for new money to push out the old, it must be accessible to all - no permissions, no regulations, no organizations spying on you. We're building something that has the potential to restart the Bitcoin history and make it peer-to-peer again.
                        </p>
                        <p>
                            Now you can join a team of the youngest company in SatoshiLabs Holding - Vexl - and be able to work on this mission directly with its founders Marek Palatinus and Pavol Rusnák!
                        </p> */}


                            <h3>Job Details</h3>
                            {/* <JobDetailsSkeleton /> */}
                            <Row>
                                <Col><b>Salary</b></Col>
                                <Col><b>Job Type</b></Col>
                            </Row>
                            <Row>
                                <Col>${props.salary}/yr -{props.currency}</Col>
                                <Col>Full Time</Col>
                            </Row>

                            <h3 className="mt-3">Job Location</h3>
                            <Row>
                                <Col><b>Irelend</b></Col>
                            </Row>


                        </div>
                    </div>
                </CardBody>
            </Card >
        </Collapse>

    </>
}
export default JobCard;