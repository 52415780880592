import { useState, useEffect } from 'react';
import icons from 'devicon/devicon.json';
interface ILanguage {
    language: string
}
export const Language = ({ language }: ILanguage) => {
    var languages = require('language-list')();
    const langCode = languages.getLanguageCode(language);
    const [icon, setIcon] = useState(undefined);
    useEffect(() => {
        if (langCode === undefined) return;
        import("language-icons/icons/" + langCode + ".svg").then(icon => {
            setIcon(icon.default);
        }).catch(err => {
            setIcon(undefined);
        })
    }, [langCode])

    return <>{icon ? <img src={icon} alt={language} style={{ maxHeight: 40 }} /> : undefined}</>
}
interface IProgrammingLangProps {
    programmingLanguage: string;
}
export const ProgrammingLang = ({ programmingLanguage }: IProgrammingLangProps) => {
    const findIcon = icons.find(icon => icon.name === programmingLanguage)
    const [icon, setIcon] = useState(undefined);
    useEffect(() => {
        if (findIcon === undefined) return;
        import(`devicon/icons/${programmingLanguage}/${programmingLanguage}-original.svg`).then(icon => {
            setIcon(icon.default);
        }).catch(err => {
            setIcon(undefined);
        })
    }, [programmingLanguage, findIcon])
    return <>{icon ? <img src={icon} alt={programmingLanguage} style={{ maxHeight: 20 }} /> : undefined}</>
}

export const ProgrammingLangIcon = ({ programmingLanguage }: IProgrammingLangProps) => {
    const findIcon = icons.find(icon => icon.name === programmingLanguage)
    const [icon, setIcon] = useState(undefined);
    useEffect(() => {
        if (findIcon === undefined) return;
        import(`devicon/icons/${programmingLanguage}/${programmingLanguage}-original.svg`).then(icon => {
            setIcon(icon.default);
        }).catch(err => {
            setIcon(undefined);
        })
    }, [programmingLanguage, findIcon])
    return <>{icon ? <img src={icon} alt={programmingLanguage} width="20px" height="20px" /> : undefined}</>
}