import { createSlice } from '@reduxjs/toolkit'
import { createOrg, createOrgDetails, getOrgDetails, getOrgs, updateOrg } from './OrgsThunk';

interface IOrgs {
    orgs: any[],
    isLoading: boolean;
    error: string | null;
    success: boolean;
    orgDetails: any;
    isValid: boolean;
    orgDetailsIsValid: boolean;
}
const initialStates: IOrgs = {
    isLoading: false,
    error: null,
    orgDetails: {},
    orgs: [],
    success: false,
    isValid: false,
    orgDetailsIsValid: false
}
export const OrgSlice = createSlice({
    name: "org",
    initialState: initialStates,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getOrgs.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
            state.success = false;
        });
        builder.addCase(getOrgs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orgs = action.payload.data;
            state.isValid = action.payload.is_valid;
            state.success = true;
        });
        builder.addCase(getOrgs.rejected, (state, action) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload?.error;
            state.orgs = [];
            state.success = false;
        });

        builder.addCase(getOrgDetails.pending, (state, action) => {
            state.isLoading = true;
            state.success = false;
        });

        builder.addCase(getOrgDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orgDetails = action.payload.data;
            state.orgDetailsIsValid = action.payload.is_valid;
            state.success = true;
        });
        builder.addCase(getOrgDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.success = true;
            state.orgDetails = {};
            // @ts-ignore
            state.orgDetailsIsValid = action.payload?.is_valid;
        });

        builder.addCase(createOrg.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(createOrg.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orgs.push(action.payload);
        });
        builder.addCase(createOrg.rejected, (state, action) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload.error;
        });

        builder.addCase(createOrgDetails.pending, (state, action) => {
            state.isLoading = true;
            state.orgDetailsIsValid = false;
        });

        builder.addCase(createOrgDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orgDetailsIsValid = true;
            // state.orgs.push(action.payload);
        });
        builder.addCase(createOrgDetails.rejected, (state, action) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload.error;
            state.orgDetailsIsValid = false;
        });

        builder.addCase(updateOrg.pending, (state, aciton) => {
            state.isLoading = true;
            state.success = false;
        });

        builder.addCase(updateOrg.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = true;
        });

        builder.addCase(updateOrg.rejected, (state, action) => {
            state.success = false;
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload.error;
        })
    },
});

export const { setError, setIsLoading, setSuccess } = OrgSlice.actions;

export default OrgSlice.reducer;