import { Col } from "reactstrap"

const Notes = () => {
    return <> <Col xl={6} sm={6} className="pe-md-5">
        Notes
    </Col>
        <Col xl={3}>

        </Col>
    </>
}
export default Notes;