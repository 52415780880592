import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col, Row } from "reactstrap";

const JobCardSkeleton = () => {
    // const loadCards = Array(amount).fill(1);
    // const loader = () => {
    //     return loadCards.map((_, i) => (

    //     ));
    // }
    return <Card className="mt-3 mb-3">
        <Col xs={12} style={{ borderRadius: 15 }} >


            <CardBody className="p-0">
                <Row>
                    <Col xl={2} className="">
                        <div className={"job-card-image  p-2 d-flex justify-content-center h-100"} style={{ border: "1px #D9D9D9" }}>
                            <Skeleton circle width={60} height={60} containerClassName="m-auto" style={{ height: "fit-content", width: "fit-content" }} />
                        </div>
                    </Col>
                    <Col xl={4} className="p-3">
                        <Skeleton count={5} />
                    </Col>
                    <Col xl={2} className="p-3">
                        <div className="p-0 m-auto">
                            <Row className="p-0">
                                <Skeleton count={4} />
                            </Row>
                            <Row>
                                <Skeleton count={1} />
                            </Row>
                        </div>
                    </Col>
                    <Col xl={1} className="p-3">

                        <Skeleton count={1} />
                    </Col>
                    <Col xl={2} className="p-3">
                        <div className="m-auto">
                            <Skeleton count={2} />
                        </div>
                    </Col>
                    <Col xl={1} className="p-3">
                        <Skeleton count={1} />
                    </Col>
                </Row>

            </CardBody>
        </Col>

    </Card>
}
export default JobCardSkeleton;