import { Route, Routes } from 'react-router-dom';
import HomePage from '../../../Pages/HomePage/HomePage';
import JobsPage from '../../../Pages/JobPage/JobsPage';
import CandidatesPage from '../../../Pages/Candidate/CandidatesPage';
import LoginPage from '../../../Pages/Login/LoginPage';
import RegisterPage from '../../../Pages/Login/RegisterPage';
import NotFoundPage from '../../../Pages/NotFoundPage';
import MainNavigation from './MainNavigations';
import { Container } from 'reactstrap';
import Footer from './Footer';
import JobDescription from '../../../Pages/JobDescription/JobDescription';
import Company from '../../../Pages/CompanyProfile/Company';
import Advertisement from '../../../Pages/Advertisement/Advertisement';
import CreateAccount from '../../../Pages/Account/CreateAccount';
import EditMyProfile from '../../../Pages/MyProfile/EditMyProfile';
import CreateProfile from '../../../Pages/Profile/CreateProfile';
import AfterRegister from '../../../Pages/Profile/AfterRegister';
import CreateAccountJobPost from '../../CreateAccountJobPost/CreateAccountJobPost';
import KanBan from '../../../Pages/KanBan/KanBan';
import MainProfile from '../../CandidateProfile/MainProfile';
import Profile from '../../CandidateProfile/CandidateComponents/Profile';
import Notes from '../../CandidateProfile/CandidateComponents/Notes';
import Messages from '../../CandidateProfile/CandidateComponents/Messages';
import ThanksPage from '../../../Pages/ThanksPage/ThanksPage';
import UnAuthorizedPage from '../../../Pages/UnAuthorized/UnAuthorizedPage';
import CompanyJobsPage from '../../../Pages/CompanyJobsPage/CompanyJobsPage';
import RecruiterLogin from '../../../Pages/Login/RecruiterLogin';
import RecruiterRegister from '../../../Pages/Login/RecruiterRegister';
import CompanyProfile from '../../CandidateProfile/CompanyProfile';
import AuthorityShield from '../Shield/AuthorityShield';
import ShieldWarrier from '../Shield/ShieldWarrier';
import Members from '../../../Pages/CompanyMembers/Members';
import AddMember from '../../../Pages/CompanyMembers/AddMember';

export const routes = {
    HOME: '/',
    COMPANY_MENU: '/company',
    OUR_JOBS: '/company/our-jobs/',
    MEMBERS: '/company/members/',
    ADD_MEMBERS: '/company/add-member/',
    COMPANY_NOT_FOUND: 'company/*',
    JOBS: '/jobs',
    JOB: '/job-description/:jobId',
    COMPANY: '/company-profile/:companyId',
    ACCOUNTINFO: '/account-info/',
    MY_PROFILE_EDIT: '/my-profile-edit/',
    ADS: '/ad',
    CANDIDATES: '/candidates',
    CREATE_PROFILE: "/create-profile",
    CREATE_ACCOUNT_JOB_POST: "/create-job-post",
    REGISTER_NEXT: "/after-register/",
    PROFILE: '/profile',
    NOTES: '/profile/notes',
    MESSAGES: '/profile/messages',
    KANBAN: '/kanban',
    LOGIN: '/login',
    RECRUITER_LOGIN: '/recruiter-login',
    RECRUITER_SIGNUP: '/recruiter-signup',
    REGISTER: '/register',
    THANKS: '/thanks',
    UNAUTHORIZED: '/unauthorized',
    NOT_FOUND: '*',
};
const Navigations = () => {
    // const user = useSelector((state: RootState) => state.user);
    // const navigate = useNavigate();
    // const dispatch = useDispatch<AppDispatch>();
    // // useEffect(() => {
    // //     if (user.loginRequired) {
    // //         navigate("/login");
    // //         dispatch(setLoginRequired({}))
    // //     }
    // // }, [user.loginRequired, navigate, dispatch])
    return <>
        <MainNavigation />
        <Container className='main-container p-0'>
            <AuthorityShield>
                <Routes>
                    <Route path={routes.HOME} index element={<HomePage />} />
                    <Route path={routes.JOBS} element={<JobsPage />} />

                    <Route path={routes.JOB} element={<JobDescription />} />
                    <Route path={routes.COMPANY} element={<Company />} />
                    <Route path={routes.CANDIDATES} element={<ShieldWarrier><CandidatesPage /></ShieldWarrier>} />
                    <Route path={routes.ACCOUNTINFO} element={<CreateAccount />} />
                    <Route path={routes.MY_PROFILE_EDIT} element={<EditMyProfile />} />
                    <Route path={routes.KANBAN} element={<KanBan />} />
                    <Route path={routes.CREATE_PROFILE} element={<CreateProfile />} />
                    <Route path={routes.REGISTER_NEXT} element={<AfterRegister />} />
                    <Route path={routes.CREATE_ACCOUNT_JOB_POST} element={<CreateAccountJobPost />} />
                    <Route path={routes.ADS} element={<Advertisement />} />
                    <Route path={routes.PROFILE} element={<MainProfile />}>
                        <Route path={''} element={<Profile />} />
                        <Route path={routes.NOTES} element={<Notes />} />
                        <Route path={routes.MESSAGES} element={<Messages />} />
                    </Route>
                    <Route path={routes.COMPANY_MENU} element={<ShieldWarrier><CompanyProfile /></ShieldWarrier>}>
                        <Route path={routes.OUR_JOBS} element={<CompanyJobsPage />} />
                        <Route path={routes.MEMBERS} element={<Members />} />
                        <Route path={routes.ADD_MEMBERS} element={<AddMember />} />
                        <Route path={routes.NOT_FOUND} element={<NotFoundPage />} />
                    </Route>

                    <Route path={routes.UNAUTHORIZED} element={<UnAuthorizedPage />} />
                    <Route path={routes.THANKS} element={<ThanksPage />} />
                    <Route path={routes.LOGIN} element={<LoginPage />} />
                    <Route path={routes.REGISTER} element={<RegisterPage />} />
                    <Route path={routes.RECRUITER_LOGIN} element={<RecruiterLogin />} />
                    <Route path={routes.RECRUITER_SIGNUP} element={<RecruiterRegister />} />
                    <Route path={routes.NOT_FOUND} element={<NotFoundPage />} />
                </Routes>
            </AuthorityShield>
        </Container>
        <Footer />
    </>
}

export default Navigations;