import { Col, Row } from "reactstrap";
import SideMenuMain from "../Common/SideMenuMain/SideMenuMain";
import { Outlet } from "react-router-dom";

const MainProfile = () => {
    return <Row>
        <Col xl={3} sm={6}>
            <SideMenuMain />
        </Col>
        <Outlet />
    </Row>
}
export default MainProfile;