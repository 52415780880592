import { useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { RootState } from "../../../store";
interface IPreviewProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    createJobPost: () => void;
    jobPost: object;
}
const Preview = ({ current, setCurrent, createJobPost, jobPost }: IPreviewProps) => {
    const submitForm = () => {
        createJobPost();
    }
    const job = useSelector((state: RootState) => state.job);
    return <Row className="mt-3">
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">{current + 1}</div>
            </div>
        </Col>
        <Col xs={10}>
            <h4>Preview</h4>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div>
                                    <img src="/jobs/Submit.png" alt="submit" />
                                </div>
                                <div className="ms-5">
                                    <p><b>{
                                        //@ts-ignore
                                        jobPost?.title}
                                    </b>
                                    </p>
                                    <p>{
                                        //@ts-ignore
                                        jobPost.highlights
                                    }</p>

                                    <p>
                                        {
                                            //@ts-ignore
                                            jobPost?.salary}{" "}
                                        {
                                            //@ts-ignore
                                            jobPost.currency
                                        }
                                    </p>
                                    <p>
                                        <div dangerouslySetInnerHTML={{
                                            __html:
                                                //@ts-ignore
                                                jobPost.job_details
                                        }} />

                                    </p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="d-flex justify-content-between mt-3">
                        <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                        <Button type="submit" color="primary" onClick={() => { submitForm() }}>
                            {job.isLoading ? <Spinner>
                                Loading...
                            </Spinner> : <>Submit</>}

                        </Button>

                    </div>
                </Col>
            </Row>
        </Col >
    </Row >
}
export default Preview;