import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { AppDispatch, RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginRequired } from "../../../store/Slice/User/User";
import { IAuthorityShieldProps } from "../../../types/Types";

const AuthorityShield = (props: IAuthorityShieldProps) => {
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        if (user.loginRequired) {
            navigate("/login");
            dispatch(setLoginRequired({}))
        }
        // if(user?.)
    }, [user.loginRequired, navigate, dispatch]);

    return <>{props.children}</>
}
export default AuthorityShield;