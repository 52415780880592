import { Form, Formik, FormikProps } from "formik";
import { TfiInfoAlt } from "react-icons/tfi";
import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import { jobRolesValidationSchema } from "../../../Forms/CreateJobPost/CreateJobPost";
import company_benefit from '../../../data/company_benefit.json';
import { IoIosArrowDown } from "react-icons/io";
import Select, { components } from 'react-select';
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
const { SingleValue, Option, MultiValueContainer } = components;
interface IChooseRoleProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    jobPost: object;
    setJobPost: React.Dispatch<React.SetStateAction<object>>;
}
const ChooseRole = ({ current, setCurrent, jobPost, setJobPost }: IChooseRoleProps) => {
    const darkMode = useSelector((state: RootState) => state.user.darkMode);
    //@ts-ignore
    const { roles } = jobPost;
    const submitForm = (e: any) => {
        setJobPost({ ...jobPost, ...e });
        setCurrent(current => current + 1);
    }
    const IconSingleValue = (props: any) => (
        <SingleValue {...props}>
            {/* <ProgrammingLang programmingLanguage={props.data.image} /> */}
            <img src={props.data.image} alt={props.data.image} />
            {" "}{props.data.label}
        </SingleValue>
    );

    const IconOption = (props: any) => (
        <Option {...props}>
            {/* <ProgrammingLang programmingLanguage={props.data.image} /> */}
            <img src={props.data.image} alt={props.data.image} />
            {" "} {props.data.label}
        </Option>
    );
    const ISelectContainer = (props: any) => {
        return <MultiValueContainer {...props}>
            <span className="badge bg-light text-dark" >
                {/* <ProgrammingLangIcon programmingLanguage={props.data.image} /> */}
                <img src={props.data.image} alt={props.data.image} />
                {" "}
                {props.data.label}
            </span>
        </MultiValueContainer>
    }
    return <Formik
        initialValues={{ roles: roles }}
        validationSchema={jobRolesValidationSchema}
        onSubmit={e => { submitForm(e); }}
    >
        {(props: FormikProps<any>) => (
            <Form>
                <Row className="mt-3">
                    <Col xs={2} className="d-flex justify-content-center">
                        <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                            <div className="m-auto">{current + 1}</div>
                        </div>
                    </Col>
                    <Col xs={10}>
                        <h4>Choose the role</h4>

                        <FormGroup>
                            <Label for="role">
                                What role would like to see in your new hire?
                            </Label>
                            <Card className={(darkMode ? 'dark-mode' : "")}>
                                <Row className="p-3">
                                    <Col xs={2} className="p-2 text-center"><TfiInfoAlt fontSize={25} /></Col>
                                    <Col xs={10}> Not sure what role to include? Skip ahead to ger help from Toptal domain experts.</Col>
                                </Row>
                            </Card>
                            <Select
                                className={"mt-4" + darkMode ? 'dark-mode' : ""}
                                styles={{
                                    option: (defaultStyles, state) => ({
                                        ...defaultStyles,
                                        backgroundColor: (state.isFocused || state.isSelected) ? "#AFE581" : (darkMode ? ' var(--mainCardColor) ' : "#fff")
                                    }),
                                    control: (defaultStyles) => ({
                                        ...defaultStyles,
                                        borderColor: props.errors?.roles ? "#dc3545" : "#ced4da",
                                    }),

                                    singleValue: (defaultStyles) => ({ ...defaultStyles, color: darkMode ? "dark-mode-text" : "#fff" }),
                                }}
                                name="roles"
                                closeMenuOnSelect={false}
                                onChange={(newValue) => {
                                    props.setFieldValue("roles", newValue.length === 0 ? null : newValue);
                                }}
                                value={props?.values?.roles}
                                required
                                isSearchable
                                isMulti
                                onBlur={() => {
                                    props?.setFieldTouched("roles", true)
                                }}
                                options={
                                    company_benefit.map(benifits => {
                                        return { value: benifits.name, label: benifits.name, image: benifits.svg }
                                    })
                                    // [
                                    //     {value: "react", label: "React", image: "react" },
                                    //     {value: "javascript", label: "javascript", image: "javascript" },
                                    //     {value: "typescript", label: "TypeScript", image: "typescript" },
                                    //     {value: "nodejs", label: "Node.js", image: "nodejs" },
                                    //     {value: "amazonwebservices", label: "Aamazon Web Services", image: "amazonwebservices" },
                                    //     {value: "mongodb", label: "MongoDB", image: "mongodb" },
                                    //     {value: "nextjs", label: "Next.js", image: "nextjs" },
                                    //     {value: "python", label: "Python", image: "python" },
                                    //     {value: "aftereffects", label: "After Effects", image: "aftereffects" },
                                    //     {value: "android", label: "Android", image: "android" },
                                    //     {value: "angularjs", label: "Angular", image: "angularjs" },
                                    //     {value: "java", label: "Java", image: "java" },
                                    //     {value: "aarch64", label: "Aarch64", image: "aarch64" },
                                    //     {value: "apache", label: "Apache", image: "apache" },
                                    //     {value: "apple", label: "Apple", image: "apple" },
                                    //     {value: "bootstrap", label: "Bootstrap", image: "bootstrap" },
                                    //     {value: "c", label: "c", image: "c" },
                                    //     {value: "cplusplus", label: "c++", image: "cplusplus" },

                                    // ]
                                }
                                components={{
                                    Option: IconOption,
                                    SingleValue: IconSingleValue,
                                    MultiValueContainer: ISelectContainer
                                }}
                            />
                            <div className="text-danger">
                                <>{props?.errors?.roles}</>
                            </div>
                        </FormGroup>

                        <div className="mt-3">
                            <div>
                                <span>Popular roles <IoIosArrowDown /></span>
                            </div>
                            {company_benefit.map((e: any, index: number) => {
                                return <button key={index} className="benefits"><img src={e.svg} alt={e.svg} />{e.name}</button>
                            })}
                        </div>
                        <div className="d-flex mt-3 justify-content-between">
                            <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                            <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        )}
    </Formik >
}
export default ChooseRole;