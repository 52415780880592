import { createSlice } from '@reduxjs/toolkit'
import { addCompanyMember, getAccountDetails, getComapnyMembers } from './AccountThunk';

interface IAccount {
    id: string;
    fname: string;
    lname: string;
    email: string;
    mobile: string;
    gender: string;
    birthdate: string;
    address: string;
    city: string;
    pin: string;
    state: string;
    status: string;
    account_type_id: string;
    permissions: {
        permissions: []
    };
    verified: boolean;
    org_id: string;
    org_verified: boolean;
    createdAt: string | Date;
    updatedAt: string | Date;
}
interface IAccountState {
    isLoading: boolean,
    success: boolean,
    error?: string | null,
    accounts: IAccount[] | [],
    accountsValid: boolean;
    accountDetails: IAccount | null,
    accountDetailsValid: boolean,
    accountCreationValid: boolean,
    accountCreationData: IAccount | null,
}
const initialState: IAccountState = {
    isLoading: false,
    success: false,
    error: null,
    accounts: [],
    accountsValid: false,
    accountDetails: null,
    accountDetailsValid: false,
    accountCreationValid: false,
    accountCreationData: null
}
export const AccountSlice = createSlice({
    name: "Account",
    initialState,
    reducers: {
        setAccountCreation: (state, action) => {
            state.accountCreationValid = !state.accountCreationValid;
        }
    },
    extraReducers(builder) {
        builder.addCase(getAccountDetails.pending, (state, action) => {
            state.isLoading = true;
            state.accountDetailsValid = false;
        });
        builder.addCase(getAccountDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.accountDetailsValid = true;
            state.accountDetails = action.payload.data;
            state.success = true
        });
        builder.addCase(getAccountDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.success = false;
            state.accountDetailsValid = false;
            //@ts-ignore
            state.error = action.payload.err;
            state.accountDetails = null;
        });
        builder.addCase(getComapnyMembers.pending, (state, action) => {
            state.isLoading = true;
            state.accountsValid = false;
        });
        builder.addCase(getComapnyMembers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.accountsValid = true;
            state.accounts = action.payload.data;
            state.success = true
        });
        builder.addCase(getComapnyMembers.rejected, (state, action) => {
            state.isLoading = false;
            state.success = false;
            state.accountsValid = false;
            //@ts-ignore
            state.error = action.payload.err;
            state.accounts = [];
        });
        builder.addCase(addCompanyMember.pending, (state, action) => {
            state.isLoading = true;
            state.accountCreationValid = false;
            state.error = null;
        });
        builder.addCase(addCompanyMember.fulfilled, (state, action) => {
            state.isLoading = false;
            state.accountCreationValid = true;
            state.accountCreationData = action.payload.data;
            state.success = true
        });
        builder.addCase(addCompanyMember.rejected, (state, action) => {
            state.isLoading = false;
            state.success = false;
            state.accountCreationValid = false;
            //@ts-ignore
            state.error = action.payload.err;
            state.accountCreationData = null;
        });
    },
});


export const { setAccountCreation } = AccountSlice.actions