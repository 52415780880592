import { useEffect } from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";
import './Company.css';
import jobs from '../../data/jobs.json'
import JobCard from "../../Components/Common/JobCard/JobCard";
import company_benefit from '../../data/company_benefit.json';
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getOrgDetails } from '../../store/Slice/Org/OrgsThunk';
import { useSelector } from 'react-redux';


const Company = () => {
    const dispatch = useDispatch<AppDispatch>();
    const company = useSelector((state: RootState) => state.organization);
    useEffect(() => {
        dispatch(getOrgDetails({ orgId: '1' }))
    }, [dispatch])
    return <>
        <Row className="justify-content-center">
            <h2 className="text-center">COMPANY PROFILE</h2>
            <Col xs={6} className="mt-5">
                <Row>
                    <Col xs={4}>
                        <div className="company-logo d-flex">
                            <img src="/company/Company.png" className="m-auto" alt="company" />
                        </div>
                    </Col>
                    <Col xs={8} className="d-flex">
                        <Row className="m-auto">
                            <h4>{company?.orgDetails?.name}</h4>
                            <p>andrea.hlubuckova@bolt.eu</p>
                            <p>{company?.orgDetails?.contact}</p>
                            <p>{company?.orgDetails?.address}{company?.orgDetails?.city}{company?.orgDetails?.state}</p>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <p>Find us on social media</p>
                    <div className="mt-3">
                        <div className="d-flex" style={{ gap: 25 }}>
                            <img src="/svg/Facebook.svg" className="shared" alt="facebook" />
                            <img src="/svg/instagram.svg" className="shared" alt="instagram" />
                            <img src="/svg/Twitter.svg" className="shared" alt="twiiter" />
                            <img src="/svg/Internet.svg" className="shared" alt="Internet" />
                            <img src="/svg/linkedin.svg" className="shared" alt="LinkedIn" />
                        </div>
                    </div>
                </Row>

                <Row className="mt-5">
                    <h4>About the company</h4>
                    <Card className="mt-4">
                        <CardBody>
                            Bolt is one of the fastest growing startups in the world with over 30M happy customers in 30 countries, from Europe to Mexico to Africa. We’re building the future of transport – one platform that connects you with cars, motorcycles, scooter sharing, and anything else you need to move around in your city. Fast, convenient and affordable for everyone, as transport should be.

                        </CardBody>
                    </Card>
                </Row>

                <Row className="mt-5">
                    <h4>Company Benifits</h4>
                    <div className="">
                        {company_benefit.map((e: any, index: number) => {
                            return <button key={index} className="benefits">{e.name}</button>
                        })}
                    </div>
                </Row>
            </Col>
        </Row>
        <Row className="mt-5">
            <h2 className="text-center">Open Positions</h2>
            {jobs.map((job: any, index: number) => {
                return <JobCard id={job.id} key={index} applications={job.applications} image={job.image} languages={job.languages} location={job.location}
                    salary={job.salary} title={job.title} type={job.type} />
            })}
        </Row>
    </>
}
export default Company;