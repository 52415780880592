import { createSlice } from "@reduxjs/toolkit";
import { createApplications, getApplicationDetails, getApplications } from "./ApplicationThunk";

const initialState = {
    isLoading: false,
    success: true,
    error: null,
    applications: [],
    application: {}
}
export const ApplicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getApplications.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
            state.success = false;
        });
        builder.addCase(getApplications.fulfilled, (state, action) => {
            state.isLoading = false;
            state.applications = action.payload;
            state.success = true;
        });
        builder.addCase(getApplications.rejected, (state, action) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload?.error;
            state.applications = [];
            state.success = false;
        });

        builder.addCase(createApplications.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
            state.success = false;
        });
        builder.addCase(createApplications.fulfilled, (state, action) => {
            state.isLoading = false;
            state.application = action.payload;
            state.success = true;
        });
        builder.addCase(createApplications.rejected, (state, action) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload?.error;
            state.application = {};
            state.success = false;
        });

        builder.addCase(getApplicationDetails.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
            state.success = false;
        });
        builder.addCase(getApplicationDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.application = action.payload;
            state.success = true;
        });
        builder.addCase(getApplicationDetails.rejected, (state, action) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload?.error;
            state.application = {};
            state.success = false;
        });

        // builder.addCase(updateApplicationDetails.pending, (state, action) => {
        //     state.isLoading = true;
        //     state.error = null;
        //     state.success = false;
        // });
        // builder.addCase(updateApplicationDetails.fulfilled, (state, action) => {
        //     state.isLoading = false;
        //     state.application = action.payload;
        //     state.success = true;
        // });
        // builder.addCase(updateApplicationDetails.rejected, (state, action) => {
        //     state.isLoading = false;
        //     //@ts-ignore
        //     state.error = action.payload?.error;
        //     state.application = {};
        //     state.success = false;
        // });
    },
});


export default ApplicationSlice.reducer;