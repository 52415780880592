import { Button, Col, Row } from "reactstrap";
// import CompanyJobCard from "../../Components/Common/CompanyJobCard/CompanyJobCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useEffect, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { getCompanyJobs } from "../../store/Slice/ComapanyJobs/CompanyJob";
import CompanyJobCardLoader from "../../Components/Common/CompanyJobCard/CompanyJobCardLoader";
const CompanyJobCard = lazy(() => import("../../Components/Common/CompanyJobCard/CompanyJobCard"));

const CompanyJobsPage = () => {
    const companyJobs = useSelector((state: RootState) => state.comapanyJob);
    const company = useSelector((state: RootState) => state.account);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        if (company.accountDetailsValid)
            dispatch(getCompanyJobs({}));
    }, [dispatch, company.accountDetailsValid])
    return (
        <>
            <Row>
                <Col className="text-end p-4">
                    <Link to="/create-job-post"> <Button color="primary">Post Job</Button></Link>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <h2>CURRENT JOB OPENINGS</h2>
                </Col>
            </Row>
            {!companyJobs.isLoading ? companyJobs.jobs.map((job: any, i: number) => {
                return <Suspense fallback={<CompanyJobCardLoader />} key={i}>
                    <CompanyJobCard id={job.id} applications={job.count} image={job.image}
                        languages={job.skills?.list}
                        location={job.location}
                        currency={job.currency}
                        applied={job?.applied}
                        salary={job?.salary} title={job?.title} type={job.type} createdAt={job.createdAt}
                        details={job?.job_details} />
                </Suspense>

            }) : null}
            {/* <CompanyJobCard /> */}
            <Row className="mt-5">
                <Col className="text-center">
                    <h2>PREVIOUS JOB OPENINGS</h2>
                </Col>
            </Row>
            {/* <CompanyJobCard /> */}
        </>
    )
}
export default CompanyJobsPage;