import { RiHomeWifiLine, RiParentLine, RiTeamLine } from "react-icons/ri";
import { IoFastFoodOutline, IoGameControllerOutline, IoRibbonOutline } from "react-icons/io5";
import { TbHeartPlus, TbHeartbeat, TbSunset2 } from "react-icons/tb";
import { GiMeditation } from 'react-icons/gi';
import { BsBook, BsCupHot } from "react-icons/bs";
import { SlScreenSmartphone } from "react-icons/sl";
import { CiBadgeDollar, CiDumbbell, CiParking1 } from 'react-icons/ci';
import { AiOutlineWoman } from "react-icons/ai";
import { IoIosBicycle } from 'react-icons/io';
import { SiFuturelearn } from 'react-icons/si';
import { FiSun } from "react-icons/fi";
import { BiDollarCircle } from "react-icons/bi";
import { MdAlarm, MdOutlineFastfood, MdOutlinePets, MdOutlineTheaterComedy } from 'react-icons/md';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { RootState } from "../../../store";
import { BASE_URL } from "../../../store/constants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { setLoginRequired } from "../../../store/Slice/User/User";
// import { useDispatch } from "react-redux";
const yourBenifits = [
    {
        icon: <MdAlarm fontSize={25} />,
        data: "Flexible work hours",
    },
    {
        icon: <RiTeamLine fontSize={25} />,
        data: "Team building activities",
    },
    {
        icon: <IoGameControllerOutline fontSize={25} />,
        data: "Gamezone",
    },
    {
        icon: <IoRibbonOutline fontSize={25} />,
        data: "Equity",
    },
    {
        icon: <TbSunset2 fontSize={25} />,
        data: "Extra days of holidays",
    },
    {
        icon: <TbHeartbeat fontSize={25} />,
        data: "Mental wellness budget",
    },
    {
        icon: <RiHomeWifiLine fontSize={25} />,
        data: "Home office",
    },
    {
        icon: <GiMeditation fontSize={25} />,
        data: "Relax zone",
    },
    {
        icon: <TbHeartPlus fontSize={25} />,
        data: "Diversity support",
    },
    {
        icon: <RiParentLine fontSize={25} />,
        data: "Parental benefits",
    },
    {
        icon: <MdOutlineTheaterComedy fontSize={25} />,
        data: "Financial contribution for cultural events",
    },
    {
        icon: <BsCupHot fontSize={25} />,
        data: "Cafeteria",
    },
    {
        icon: <SlScreenSmartphone fontSize={25} />,
        data: "Gadget of your choice"
    },
    {
        icon: <CiBadgeDollar fontSize={25} />,
        data: "Financial bonuses"
    },
    {
        icon: <AiOutlineWoman fontSize={25} />,
        data: "Women encouraged to apply"
    },
    {
        icon: <MdOutlinePets fontSize={25} />,
        data: "Pet friendly office"
    },
    {
        icon: <CiDumbbell fontSize={25} />,
        data: "Sports budget"
    },
    {
        icon: <IoIosBicycle fontSize={25} />,
        data: "Cyclist Welcome"
    },
    {
        icon: <IoFastFoodOutline fontSize={25} />,
        data: "Office meals"
    },
    {
        icon: <BsBook fontSize={25} />,
        data: "Learning budget"
    },
    {
        icon: <FiSun fontSize={25} />,
        data: "Work life balance"
    },
    {
        icon: <CiParking1 fontSize={25} />,
        data: "Parking"
    },
    {
        icon: <MdOutlineFastfood fontSize={25} />,
        data: "Free snacks"
    },
    {
        icon: <BiDollarCircle fontSize={25} />,
        data: "Salary bonuses"
    },
    {
        icon: <SiFuturelearn fontSize={25} />,
        data: "Career growth"
    }
]
interface IEditProfileProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>
    profile: object;
}
const ChooseBenifits = ({ current, setCurrent, profile }: IEditProfileProps) => {
    // const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    // const dispatch =useDispatch<AppDispatch>();
    const submitForm = () => {
        const createAcc = async () => {
            //@ts-ignore
            profile.password = 'Yash2904@';
            //@ts-ignore
            profile.account_type_id = 1;
            const data = {
                data: [profile]
            }
            // if (user.userToken) {
            //     dispatch(setLoginRequired({}))
            //     navigate("/login");
            // }
            const api = await fetch(BASE_URL + '/account', {
                body: JSON.stringify(data),
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.userToken
                }
            });
            if (await api.ok) {
                navigate("/profile");
            }
        }
        createAcc()
    }
    return <Row>
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">9</div>
            </div>
        </Col>
        <Col xs={10} >
            <div className="d-flex justify-content-between">
                <div>
                    <h4>Choose your benefits</h4>
                </div>
                <div>
                    <h4><MdOutlineModeEditOutline /> Edit</h4>
                </div>
            </div>
            <Row>
                <p>"What perks do you find particularly interesting?"</p>
            </Row>
            <Row>
                {yourBenifits.map((benifit: any, index: number) => {
                    return <Button key={index} className="w-auto m-2 benifitBtn">{benifit.icon}&nbsp;{benifit.data}</Button>
                })}
            </Row>
            <Form>
                <img src="radar.png" alt="radar" />
                <Col>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" />{' '}
                            Receive email notifications about new and exciting
                            job opportunities! We promise not to spam you, protecting privacy rules and fighting spam.
                        </Label>
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" />{' '}
                            Stay in the loop for potential career opportunities that match your skills and experience. Check the box to discreetly give us permission to keep your profile on our radar and discuss potential job openings with relevant companies according to our privacy rules.
                        </Label>
                    </FormGroup>
                </Col>
            </Form>
            <Row className="mt-5 justify-content-center" >
                <div className="d-flex justify-content-between mt-4">
                    <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                    <Button type="submit" color="primary" onClick={() => { submitForm() }}>Next</Button>
                </div>
            </Row>
        </Col>
    </Row>
}
export default ChooseBenifits;