import { Badge, ListGroup, ListGroupItem } from "reactstrap"
import User from "../Common/UserProfile/User"
import { BiMessageAlt, BiMoon, BiSun } from 'react-icons/bi';
import { SlNotebook } from 'react-icons/sl';
import { GrNotification } from 'react-icons/gr';
import { TbApps } from 'react-icons/tb';
import { AiOutlineCalendar, AiOutlineLogout, AiOutlinePlusCircle } from 'react-icons/ai';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineCelebration, MdHelpOutline } from 'react-icons/md';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { RiTeamLine } from 'react-icons/ri';
import { RxDownload } from 'react-icons/rx';
import { CgCloseR } from 'react-icons/cg';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { logout, setDarkMode } from "../../store/Slice/User/User";
const CompanySideMenu = () => {
    const location = useLocation();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    return <>
        <User avatarUrl="/Avatar.png" contactNumber="+420775430021" email={user?.userData?.email ? user?.userData?.email : ''}
            //@ts-ignore
            userName={user?.userData.fname + ' ' + user?.userData?.lname} />
        <ListGroup className="job-menus mt-5">
            <ListGroupItem active={location?.pathname === "/company/" ? true : false}>
                <Link to={"/company/"}>
                    <TbApps />{" "}
                    <span>Application</span>
                </Link>
            </ListGroupItem>
            <ListGroupItem active={location?.pathname === "/create-job-post" ? true : false}>
                <Link to={"/create-job-post"}>
                    <SlNotebook />{" "}
                    <span>Job post</span>
                </Link>
            </ListGroupItem>
            <ListGroupItem active={location?.pathname === "/company/our-jobs" ? true : false}>
                <Link to={"/company/our-jobs"}>
                    <SlNotebook />{" "}
                    <span>Jobs</span>
                </Link>
            </ListGroupItem>
            <ListGroupItem active={location?.pathname === "/company/members" ? true : false}>
                <Link to={"/company/members"}>
                    <RiTeamLine />{" "}
                    <span>Members</span>
                </Link>
            </ListGroupItem>
            <ListGroupItem active={location?.pathname === "/company/add-member" ? true : false}>
                <Link to={"/company/add-member"}>
                    <AiOutlineUserAdd />{" "}
                    <span>Add Member</span>
                </Link>
            </ListGroupItem>
            <ListGroupItem active={location?.pathname === "/company/candidates" ? true : false}>
                <Link to={"/company/candidates"}>
                    <SlNotebook />{" "}
                    <span>Find Candidates</span>
                </Link>
            </ListGroupItem>
            <ListGroupItem active={location?.pathname === "/company/messages" ? true : false}>
                <Link to="/company/messages" className="justify-content-between d-flex">
                    <div>
                        <BiMessageAlt />{" "}
                        <span>Messages</span>
                    </div>
                    <Badge pill bg="danger" className="bg-danger">
                        2
                    </Badge>
                </Link>
            </ListGroupItem>
            <ListGroupItem className="d-flex justify-content-between">
                <div>
                    <GrNotification />{" "}
                    <span> Notification</span>
                </div>
                <Badge pill bg="danger" className="bg-danger">
                    2
                </Badge>
            </ListGroupItem>
            <ListGroupItem className="d-flex justify-content-between">
                <div>
                    <AiOutlineCalendar />{" "}
                    <span>Schedules</span>
                </div>
                <div>
                    <AiOutlinePlusCircle />
                    <Badge pill bg="danger" className="bg-danger">
                        2
                    </Badge>
                </div>
            </ListGroupItem>
            <ListGroupItem>
                <IoSettingsOutline />{" "}
                <span>Settings</span>
            </ListGroupItem>
        </ListGroup>
        <ListGroup className="job-menus mt-5">
            <ListGroupItem>
                <MdOutlineCelebration />{" "}
                <span>Claim your price</span>
            </ListGroupItem>
        </ListGroup>
        <ListGroup className="job-menus mt-5">
            <ListGroupItem>
                <RxDownload />{" "}
                <span>Download PDF</span>
            </ListGroupItem>
            <ListGroupItem>
                <CgCloseR />{" "}
                <span>Reject application</span>
            </ListGroupItem>
        </ListGroup>
        <ListGroup className="job-menus mt-5">
            <ListGroupItem>
                <MdHelpOutline />{" "}
                <span>Help Center</span>
            </ListGroupItem>
            <ListGroupItem onClick={() => {
                dispatch(logout());
                localStorage.clear();
                navigate('/login')
            }}>
                <AiOutlineLogout />{" "}
                <span>Log out</span>
            </ListGroupItem>
            <ListGroupItem onClick={() => { dispatch(setDarkMode()) }}>
                {user?.darkMode ?
                    <>
                        <BiSun />
                        {" "}
                        <span>Day out</span>
                    </> :
                    <> <BiMoon />
                        {" "}
                        <span>Night out</span></>
                }


            </ListGroupItem>
        </ListGroup >
    </>
}

export default CompanySideMenu;