import { Button, Col, Row } from "reactstrap";
import jobs from '../../data/jobs.json'
import JobCard from "../Common/JobCard/JobCard";

const Ads = () => {
    return <>
        <Row className="justify-content-center">
            <h1 className="text-center">ADVERTISEMENTS</h1>
            <Col xs={6} className="mt-5">
                <Row className="d-flex justify-content-evenly home-top-btns">
                    <Col><Button>All advertisements <img src="/svg/noun-arrow-down.svg" alt="1" /></Button></Col>
                    <Col> <Button >All users <img src="/svg/noun-arrow-down.svg" alt="1" /></Button></Col>
                    <Col><Button >All brands <img src="/svg/noun-arrow-down.svg" alt="1" /></Button></Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    <Button color="primary" className="w-50"> +  Add New Advertising</Button>
                </Row>
            </Col>
        </Row>
        <Row className="mt-5">
            {jobs.map((job: any, index: number) => {
                return <JobCard id={job.id} key={index} applications={job.applications} image={job.image} languages={job.languages} location={job.location}
                    salary={job.salary} title={job.title} type={job.type} />
            })}
        </Row>
    </>
}
export default Ads;