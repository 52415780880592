import { createSlice } from "@reduxjs/toolkit";
import { createApplicants, getApplicants, getApplicantsDetails, updateApplicants } from "./ApplicantThunk";

interface IApplicantState {
    isLoading: boolean;
    isSuccess: boolean;
    error: string | null;
    isValid: boolean;
    applicants: [],
    isValidApplicantsDetail: boolean;
    applicantDetail: object | {}
}
const initialState: IApplicantState = {
    isLoading: false,
    isSuccess: false,
    error: null,
    isValid: false,
    applicants: [],
    isValidApplicantsDetail: false,
    applicantDetail: {}
}

export const ApplicantSlice = createSlice({
    name: "applicants",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getApplicants.pending, (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(getApplicants.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isValid = action.payload.is_valid;
            state.applicants = action.payload.data
        });
        builder.addCase(getApplicants.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            //@ts-ignore
            state.isValid = action.payload.is_valid ? action.payload.is_valid : false;
            state.applicants = [];
        });

        builder.addCase(getApplicantsDetails.pending, (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(getApplicantsDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isValidApplicantsDetail = action.payload.is_valid;
            state.applicantDetail = action.payload.data
        });
        builder.addCase(getApplicantsDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            //@ts-ignore
            state.isValidApplicantsDetail = action.payload.is_valid ? action.payload.is_valid : false;
            state.applicantDetail = {};
        });


        builder.addCase(createApplicants.pending, (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(createApplicants.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isValid = action.payload.is_valid;
            //@ts-ignore
            state.applicants.push(action?.payload?.data);
            state.isValid = action.payload.is_valid
        });
        builder.addCase(createApplicants.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            //@ts-ignore
            state.error = action.payload.err;
            //@ts-ignore
            state.isValid = action.payload.is_valid ? action.payload.is_valid : false;
            state.applicants = [];
        });

        builder.addCase(updateApplicants.pending, (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(updateApplicants.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isValid = action.payload.is_valid;
            //@ts-ignore
            state.applicants.push(action?.payload?.data);
            state.isValid = action.payload.is_valid
        });
        builder.addCase(updateApplicants.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            //@ts-ignore
            state.error = action.payload.err;
            //@ts-ignore
            state.isValid = action.payload.is_valid ? action.payload.is_valid : false;
            state.applicants = [];
        });

    },
});

export default ApplicantSlice.reducer;