import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setUserData } from './User';

export const UserApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://backend-dev.teqhire.com' }),
    endpoints: (builder) => ({
        registerUser: builder.mutation({
            query: (register) => ({
                url: "/register",
                body: register,
                method: "POST"
            }),
            transformResponse: (result) => {
                return result
            }
        }),
        loginUser: builder.mutation({
            query: (login) => ({
                url: "/login/",
                body: login,
                method: "POST",
            }),
            onQueryStarted: async (login, { dispatch, queryFulfilled }) => {
                const { data } = await queryFulfilled;
                dispatch(setUserData(data))
            },
            transformErrorResponse: (result, meta) => {
                return result;
            }
        }),
    }),
});
export const { useLoginUserMutation, useRegisterUserMutation } = UserApi;