import Select from 'react-select';
import { Form, Formik, FormikProps } from "formik";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { EditProfileWorkExperienceSchema } from "../../../Forms/EditProfile/EditProfile";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
interface IEditProfileProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    profile: Object;
    setProfile: React.Dispatch<React.SetStateAction<Object>>;
}
const WorkExperience = ({ current, setCurrent, profile, setProfile }: IEditProfileProps) => {
    //@ts-ignore
    const { positionName, companyName, since, Until,skills, summary } = profile;
    const experienceInitalValues = {
        positionName: positionName,
        companyName: companyName,
        since: since,
        Until: Until,
        skills:skills,
        summary: summary
    }
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        setProfile({ ...profile, ...e });
    }
    return <Row>
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">{current + 1}</div>
            </div>
        </Col>
        <Col xs={10} >
            <div className="d-flex justify-content-between">
                <div>
                    <h4>Work Experience</h4>
                </div>
                <div>
                    <h4><MdOutlineModeEditOutline /> Edit</h4>
                </div>
            </div>
            <Formik initialValues={experienceInitalValues} validationSchema={EditProfileWorkExperienceSchema} onSubmit={e => { submitForm(e) }}>
                {(props: FormikProps<any>) => (
                    <Form>
                        <FormGroup>
                            <Label for="positionName">
                                Name of position
                            </Label>
                            <Select
                                styles={{
                                    option: (defaultStyles, state) => ({
                                        ...defaultStyles,
                                        backgroundColor: (state.isFocused || state.isSelected) ? "#AFE581" : "#fff"
                                    }),
                                    control: (defaultStyles) => ({
                                        ...defaultStyles,
                                        borderColor: props.errors?.positionName ? "#dc3545" : "#ced4da",
                                    }),
                                    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
                                }}
                                name="positionName"
                                closeMenuOnSelect={false}
                                onChange={(newValue) => {
                                    props.setFieldValue("positionName", newValue.length === 0 ? null : newValue);
                                }}
                                value={props?.values?.positionName || ''}
                                required
                                isSearchable
                                isMulti
                                onBlur={() => {
                                    props?.setFieldTouched("positionName", true)
                                }}
                                options={[
                                    { value: "1", label: "Front-end Developer", },
                                    { value: "2", label: "FullStack Developer", }
                                ]}
                            />
                            <div className="text-danger">
                                <>{props?.errors?.positionName}</>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label for="companyName">
                                Company name*
                            </Label>
                            {/* <Input invalid={props.errors?.companyName !== undefined} onChange={(e: any) =>
                                props.setFieldValue("companyName", e.target.value)
                            } /> */}
                            <Select
                                styles={{
                                    option: (defaultStyles, state) => ({
                                        ...defaultStyles,
                                        backgroundColor: (state.isFocused || state.isSelected) ? "#AFE581" : "#fff"
                                    }),
                                    control: (defaultStyles) => ({
                                        ...defaultStyles,
                                        borderColor: props.errors?.companyName ? "#dc3545" : "#ced4da",
                                    }),
                                    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
                                }}
                                name="companyName"
                                value={props?.values?.companyName || ''}
                                closeMenuOnSelect={false}
                                onChange={(newValue) => {
                                    props.setFieldValue("companyName", newValue.length === 0 ? null : newValue);
                                }}
                                required
                                isSearchable
                                isMulti
                                onBlur={() => {
                                    props?.setFieldTouched("companyName", true)
                                }}
                                options={[
                                    { value: "1", label: "Google", },
                                    { value: "2", label: "Facebook", }
                                ]}
                            />
                            <div className="text-danger">
                                <>{props?.errors?.companyName}</>
                            </div>
                        </FormGroup>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="since">
                                        Since
                                    </Label>
                                    <Input type="select" invalid={props.errors?.since !== undefined} onChange={(e: any) =>
                                        props.setFieldValue("since", e.target.value)
                                    }
                                        value={props?.values?.since || ''}>
                                        <option value="">--select--</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </Input>
                                    <FormFeedback>
                                        <>{props.errors?.since}</>
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for="Until">
                                        Until
                                    </Label>
                                    <Input type="select" invalid={props.errors?.Until !== undefined} onChange={(e: any) =>
                                        props.setFieldValue("Until", e.target.value)
                                    }
                                        value={props?.values?.Until || ''}
                                    >
                                        <option value="">--select--</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </Input>
                                    <FormFeedback>
                                        <>{props.errors?.Until}</>
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" />{' '}
                                        Still working here
                                    </Label>
                                </FormGroup>
                            </Col>

                        </Row>
                        <FormGroup>
                            <Label for="skills">
                                Used skills ( 5 remaining )
                            </Label>
                            <Input invalid={props.errors?.skills !== undefined}
                                value={props?.values?.skills || ''}
                                onChange={(e: any) =>
                                    props.setFieldValue("skills", e.target.value)
                                } />
                            <FormFeedback>
                                <>{props?.errors?.skills}</>
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">
                                Summary (optional)
                            </Label>
                            <div className="editor">
                                <CKEditor
                                    //@ts-ignore
                                    editor={ClassicEditor}
                                    onChange={(event, editor) => {
                                        //@ts-ignore
                                        props.setFieldValue("summary", editor.getData());
                                    }}
                                    data={props?.values?.summary}
                                />
                            </div>
                        </FormGroup>
                        <p>Fields marked by * are mantadory</p>
                        <div>
                            <Button className="w-75">+Add Work Experience</Button>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                            <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                        </div>
                    </Form>
                )}

            </Formik>
        </Col>
    </Row>
}
export default WorkExperience;