import { Card, CardBody } from "reactstrap";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const CompanyJobCardLoader = () => {
    const darkMode = useSelector((state: RootState) => state.user?.darkMode);
    return <>
        <Card className={" mt-4 jobCard-company p-3 " + (darkMode ? '' : '')} >
            <CardBody className="job-card-body">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="company-post-desc">
                        {/* <h2 className="fw-bolder company-job-title fs-4">{props.title}</h2> */}
                        <Skeleton />
                        <div className="">
                            <Skeleton />
                        </div>
                        <div className="mt-2">
                            <span>
                                <Skeleton />
                            </span>
                        </div>
                    </div>
                    <div className="company-post-salary">
                        <div className="lan-flag text-center">
                            <Skeleton />
                        </div>
                        <div className="salary mt-2 text-capitalize text-center">
                            <span><Skeleton /></span>
                        </div>
                    </div>
                    <div className="company-post-time" style={{ width: 100 }}>
                        <span><Skeleton /></span>
                    </div>
                    <div className="company-post-details">
                        <div>
                            <Skeleton />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card >
    </>
}

export default CompanyJobCardLoader;