import { Button, Col, Row } from "reactstrap";
import { columnsFromBackend } from "./KanbanData";
import { useState } from "react";
import styled from '@emotion/styled';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import TaskCard from "./TaskCard";
import './KanBan.css';
import SideBarContainer from "./SideBarContainer";
import { TfiClose } from 'react-icons/tfi';
const Container = styled.div`
  display: flex;
`;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #E9ECEF;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
`;

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Title = styled.div`
  color: #10957d;
  background: #AFE581;
  border-radius: 5px;
  align-self: flex-start;
`;
const KanBanBoard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [columns, setColumns] = useState(columnsFromBackend);

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
        }
    };
    return <Row className="justify-content-center">
        {/* <h1 className="text-center">BOLT WORKSPACE</h1> */}
        <div className="d-flex">
            <Row className="d-flex overflow-auto p-0 kanban-overflow">
                <Col xs={12} className="p-0">
                    <Col xs={12}>
                        <DragDropContext
                            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                        >
                            <Container>
                                <TaskColumnStyles>
                                    {Object.entries(columns).map(([columnId, column], index) => {
                                        return (
                                            <Droppable key={columnId} droppableId={columnId}>
                                                {(provided, snapshot) => (
                                                    <TaskList
                                                        className="taskList p-0"
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        <Title className="w-100 p-4">
                                                            <Button className="w-100">{column.title}</Button>
                                                        </Title>
                                                        <div style={{
                                                            background: "#E9ECEF"
                                                        }} className="p-1">
                                                            {column.items.map((item, index) => (
                                                                <TaskCard key={item} item={item} index={index} setIsOpen={() => setIsOpen(true)} />
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>

                                                    </TaskList>
                                                )}
                                            </Droppable>
                                        );
                                    })}
                                </TaskColumnStyles>
                            </Container>
                        </DragDropContext>
                    </Col>
                </Col>
            </Row>
            {isOpen ? <Row className="d-flex overflow-auto p-0 w-100 z-3 shadow" style={{ borderRadius: 15 }} >
                <Col className="pt-4" style={{ background: "#fff" }}>
                    <div className="d-flex justify-content-end">
                        <TfiClose style={{ cursor: "pointer" }} onClick={() => setIsOpen(false)} />
                    </div>
                    <SideBarContainer setIsOpen={() => setIsOpen(true)} />
                </Col>
            </Row> : null}
        </div>
    </Row >
}
export default KanBanBoard;
