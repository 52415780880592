import Skeleton from "react-loading-skeleton";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const CandidateBasicsSkeleton = () => {
    return <Card className="mt-3">
        <CardBody className="p-4">
            <Skeleton />
        </CardBody>
    </Card>
}
const CandidateBasicExperience = () => {
    return <Card className="mt-3 p-0">
        <CardHeader className="p-4 profile-card">
            <Row>
                <Col><Skeleton /></Col>
                <Col><Skeleton /></Col>
            </Row>
        </CardHeader>
        <CardBody className="p-4">
            <Skeleton />
        </CardBody>
    </Card>
}
const CandidateBasicsEducation = () => {
    return <Card className="mt-3">
        <CardBody>
            <Row>
                <Col>
                    <Skeleton count={3} />

                </Col>
                <Col>
                    <Skeleton />
                </Col>
            </Row>
        </CardBody>
    </Card>
}

const CandidateBasicsAttachments = () => {
    return <Row className="mt-3">
        <Col xs={9}>
            <Skeleton />

        </Col>
        <Col xs={3} >
            <Skeleton />
        </Col>
    </Row>
}
const CandidateBasicLanguages = () => {
    return <Row className="m-3">
        <Col><Skeleton /></Col>
        <Col><Skeleton /></Col>
        <Col><Skeleton /></Col>
    </Row>
}
const CandidateBasicSkills = () => {
    return <Skeleton />
}

const CandidateBasicMainLoader = () => {
    return <>
        <Row>
            <Skeleton className="h3" />
            <CandidateBasicsSkeleton />
        </Row>
        <Row className="mt-5">
            <Skeleton className="h3" />
            <CandidateBasicExperience />
        </Row>
        <Row className="mt-5">
            <Skeleton className="h3" />
            <CandidateBasicsEducation />
        </Row>
        <Row className="mt-5">
            <Skeleton className="h3" />
            <CandidateBasicsAttachments />
        </Row>
    </>
}
const CandidateBasicsLanguangesSkills = () => {
    return <>
        <Row>
            <Skeleton className="h3" />
            <Card className="mt-3">
                <CandidateBasicLanguages />
            </Card>
        </Row>
        <Row className="mt-5">
            <Skeleton className="h3" />
            <Card className="mt-3 p-4">
                <CandidateBasicSkills />
            </Card>
        </Row>
    </>
}
export { CandidateBasicsSkeleton, CandidateBasicExperience, CandidateBasicsEducation, CandidateBasicsAttachments, CandidateBasicLanguages, CandidateBasicSkills, CandidateBasicMainLoader, CandidateBasicsLanguangesSkills };