import { Card, CardBody, Col, Row } from "reactstrap";
import CreateJobPost from "./FormComponents/CreateJobPost";
import ChooseRole from "./FormComponents/ChooseRole";
import ChooseSkills from "./FormComponents/ChoosSkills";
import LanguagesForm from "./FormComponents/LanguagesForm";
import BoostJobPost from "./FormComponents/BoostJobPost";
import JobDetailsForm from "./FormComponents/JobDetailsForm";
import SocialMedia from "./FormComponents/SocialMedia";
import Preview from "./FormComponents/Preview";
import { useEffect } from 'react';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { createJobs } from "../../store/Slice/Jobs/JobsThunk";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const JobPost = () => {
    const [current, setCurrent] = useState(0);
    const [filesObject, setFilesObject] = useState<Object[]>([]);
    const [jobPost, setJobPost] = useState<object>({});
    const dispatch = useDispatch<AppDispatch>();
    const job = useSelector((state: RootState) => state.job);
    const darkMode = useSelector((state: RootState) => state.user.darkMode);
    const navigate = useNavigate();
    const createJob = () => {
        dispatch(createJobs({ ...jobPost }));
        // console.log(job.jobs.length)
    }
    useEffect(() => {
        if (job.isLoading === false && job.jobId !== null) {
            console.log(job.jobId);
            navigate('/job-description/' + job.jobId)
        }
    }, [job.isLoading, job.jobId, navigate])
    const JobsPostForms = [
        <CreateJobPost current={current} setCurrent={setCurrent} jobPost={jobPost} setJobPost={setJobPost} />,
        <ChooseRole current={current} setCurrent={setCurrent} jobPost={jobPost} setJobPost={setJobPost} />,
        <ChooseSkills current={current} setCurrent={setCurrent} jobPost={jobPost} setJobPost={setJobPost} />,
        <LanguagesForm current={current} setCurrent={setCurrent} jobPost={jobPost} setJobPost={setJobPost} />,
        <BoostJobPost current={current} setCurrent={setCurrent} />,
        <JobDetailsForm current={current} setCurrent={setCurrent} jobPost={jobPost} setJobPost={setJobPost} filesObject={filesObject} setFilesObject={setFilesObject} />,
        <SocialMedia current={current} setCurrent={setCurrent} jobPost={jobPost} setJobPost={setJobPost} />,
        <Preview current={current} setCurrent={setCurrent} createJobPost={createJob} jobPost={jobPost} />]
    return <Row className="justify-content-center">
        <Col xs={8}>

            <Card className={"border-0 p-4" + (darkMode ? ' dark-mode' : '')}>
                <h1 className="text-center mt-4">CREATE YOUR ACCOUNT</h1>
                <CardBody>
                    {JobsPostForms?.[current]}
                </CardBody>
            </Card>
        </Col>
    </Row>
}
export default JobPost;