import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { BASE_URL, ORG_API } from "../../constants";


export const getOrgs = createAsyncThunk<any, undefined, { state: RootState }>('org/getOrgs', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.userToken;

        const getOrg = await fetch(BASE_URL + ORG_API, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (getOrg.status === 200) {
            return await getOrg.json();
        }
    } catch (err) {
        return thunkAPI.rejectWithValue({
            error: {
                err
            }
        })
    }
});

export const getOrgDetails = createAsyncThunk<any, { orgId: string }, { state: RootState }>('org/getOrgDetail', async ({ orgId }, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.userToken;
        const getOrgDetail = await fetch(BASE_URL + ORG_API + orgId, {
            headers: {
                "Authorization": "Bearer " + token
            }
        });
        if (getOrgDetail.status === 200) {
            return await getOrgDetail.json();
        }
    } catch (err) {
        return thunkAPI.rejectWithValue({
            error: {
                err
            }
        })
    }
});

export const createOrg = createAsyncThunk<any, any, { state: RootState }>('org/createOrg', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.userToken;
        const createOrganization = await fetch(BASE_URL + ORG_API, {
            method: "POST",
            body: JSON.stringify(_),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
        if (createOrganization.status === 200) {
            return await createOrganization.json();
        }
    } catch (err) {
        return thunkAPI.rejectWithValue({
            error: {
                err
            }
        })
    }
});

export const createOrgDetails = createAsyncThunk<any, any, { state: RootState }>('org/createOrgDetails', async (orgData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.userToken;
        const data = {
            data: [orgData]
        }
        const createOrganization = await fetch(BASE_URL + ORG_API, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
        if (createOrganization.status === 200) {
            return await createOrganization.json();
        }
    } catch (err) {
        return thunkAPI.rejectWithValue({
            error: {
                err
            }
        })
    }
});

export const updateOrg = createAsyncThunk<any, any, { state: RootState }>('org/updateOrg', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.userToken;
        const updateOrganization = await fetch(BASE_URL + ORG_API, {
            method: "PUT",
            body: JSON.stringify(_),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
        if (updateOrganization.status === 200) {
            return await updateOrganization.json();
        }
    } catch (err) {
        return thunkAPI.rejectWithValue({
            error: {
                err
            }
        })
    }
})