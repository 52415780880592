import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

const NotFoundPage = () => {
    return <Row className="justify-content-center">
        <Col xs={6} className="mt-5">
            <Row className="d-flex justify-content-evenly home-top-btns">
                <div className="four_zero_four_bg">
                    <h1 className="text-center h1 ">404</h1>
                </div>
            </Row>
            <Row className="justify-content-center mt-5">
                <Row className="contant_box_404 text-center">
                    <h3 className="h2">
                        Look like you're lost
                    </h3>

                    <p>the page you are looking for not available!</p>

                    <Link to="/" ><Button color="primary">Go to Home</Button></Link>
                </Row>
            </Row>
        </Col>
    </Row>
}
export default NotFoundPage;