import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col, ListGroup, Row } from "reactstrap";

const SideMenuSkeleton = () => {
    return <>
        <Card className="border-0">
            <CardBody>
                <Row>
                    <Col xs={3}>
                        <Skeleton height={60} width={60} circle />
                    </Col>
                    <Col xs={9}>
                        <Skeleton count={3} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <ListGroup>
            <Skeleton count={15} className="p-2 m-1" />
        </ListGroup>
    </>
}
export default SideMenuSkeleton;