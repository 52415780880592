import Skeleton from "react-loading-skeleton";
import { Col, Row } from "reactstrap";

const JobDescriptionTitleSkeleton = () => {
    return <Skeleton className="h1" />
}

const JobCompanySkeleton = () => {
    return <>
        <Col xs={3}>
            <div className="company-logo p-2 h-100">
                <Skeleton height={"100%"} />
            </div>
        </Col>
        <Col xs={9}>
            <Skeleton className="h3" />
            <div className="d-flex logo">
                <Skeleton />
            </div>
            <div className="d-block">
                <Skeleton count={2} />
            </div>
        </Col>
    </>
}

const JobDescriptionSkeleton = () => {
    return <Col xs={8}>
        <Skeleton height={250} />
    </Col>
}
const JobDetailsSkeleton = () => {
    return <><Row>
        <Col><Skeleton count={2} /></Col>
        <Col><Skeleton count={2} /></Col>
    </Row>
    </>
}
const JobDescriptionMainSkeleton = () => {
    return <>
        <Skeleton className="h1" />
        <Row>
            <JobCompanySkeleton />
        </Row>
        <Row className="mt-5">
            <Skeleton className="h1" />
            <Row>
                <JobDescriptionSkeleton />
            </Row>
        </Row>
        <Row className="mt-5">
            <Skeleton className="h3" />
            <JobDetailsSkeleton />
        </Row>
        
    </>
}
export { JobDescriptionTitleSkeleton, JobCompanySkeleton, JobDescriptionSkeleton, JobDetailsSkeleton, JobDescriptionMainSkeleton };