import Skeleton from "react-loading-skeleton";
import { Card, CardBody, Col, Row } from "reactstrap";

const ExploreCategoriesSkeleton = () => {
    return <Card className="" >
        <CardBody className="justify-content-around d-flex">
            <Row className="w-100">
                <Col xs={6}>
                    <Skeleton circle width={60} height={60} containerClassName="m-auto" style={{ height: "fit-content", width: "fit-content" }} />
                </Col>
                <Col xs={6}>
                    <Skeleton count={2} />
                </Col>
            </Row>
        </CardBody>
    </Card>
};
export default ExploreCategoriesSkeleton;