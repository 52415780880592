import { Button, Col, FormFeedback, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { toast } from 'react-toastify';
import { RiUser3Fill } from "react-icons/ri";
import { BsRocketTakeoffFill, BsGoogle, BsLinkedin } from "react-icons/bs";
import { Link, Navigate } from "react-router-dom";
import "./RegisterProfile.css";
import { Formik, Form, FormikProps } from "formik";
import { LoginValidationSchema } from "../../Forms/RegisterProfile/RegisterProfile";
import { useDispatch } from 'react-redux'
import { loginUser } from "../../store/Slice/User/UserThunk";
import { AppDispatch, RootState } from "../../store";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { setError } from "../../store/Slice/User/User";
const Login = () => {
    // const [isVisible, setIsVisible] = useState<boolean>(false);

    const { error, isLoading, userToken, darkMode } = useSelector(
        (state: RootState) => state.user
    );
    const dispatch = useDispatch<AppDispatch>();
    const initialValues = {
        email: "",
        password: "",
    };
    useEffect(() => {
        //@ts-ignore
        if (error?.msg) {
            //@ts-ignore
            toast.error(error.msg);
            dispatch(setError(null))
        }
    }, [error, dispatch]);
    if (userToken && error === null) {
        //@ts-ignore
        localStorage.setItem("userToken", userToken);
        return <Navigate to="/" />
    }
    const onSubmit = (e: { email: string, password: string }) => {
        dispatch(loginUser(e))
    };
    return (
        <>
            <Row className="justify-content-center">

                <Row className={"justify-content-center " + (darkMode ? 'dark-mode ' : ' ')}>

                    <Col xs={8} className={'card ' + (darkMode ? 'dark-mode ' : ' ')}>
                        <h1 className="text-center mt-5">CREATE A PROFILE ON TEQHIRE</h1>
                        <Row className="m-3 justify-content-center">
                            <Col xs={8}>
                                <Row>
                                    <Col xs={6}>
                                        <RiUser3Fill />
                                        Do not have an account?{" "}
                                        <Link to={"/create-profile"} className="top-options">
                                            Signup
                                        </Link>
                                    </Col>
                                    <Col xs={6}>
                                        <BsRocketTakeoffFill />{" "}
                                        <Link to={"/recruiter-login"} className="top-options">
                                            Im looking for an employee{" "}
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-center m-3">
                            Create a profile via
                        </Row>

                        <Row className="m-4">
                            {/* <Col>
                                <Button color="primary" className="primary">
                                    {" "}
                                    <FaFacebook /> Facebook
                                </Button>
                            </Col> */}
                            <Col sm={6}>
                                <Button className="w-100">
                                    <BsGoogle />
                                    Google
                                </Button>
                            </Col>
                            <Col sm={6}>
                                <Button color="primary" className="primary w-100">
                                    <BsLinkedin /> in LinkedIn
                                </Button>
                            </Col>
                            {/* <Col>
                                <Button>
                                    <BsApple /> in Apple
                                </Button>
                            </Col> */}
                        </Row>
                        <Row className="m-3">
                            <p className="text-center">or</p>
                        </Row>

                        <Row className="p-5">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={LoginValidationSchema}
                                onSubmit={e => onSubmit(e)}
                            >
                                {(props: FormikProps<any>) => (
                                    <Form>

                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input
                                                invalid={props?.errors?.email !== undefined}
                                                onChange={(e: any) =>
                                                    props.setFieldValue("email", e.target.value)
                                                }
                                            />
                                            <FormFeedback>
                                                <>{props.errors?.email}</>
                                            </FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="password">
                                                Password
                                            </Label>
                                            <Input
                                                type="password"
                                                invalid={props?.errors?.password !== undefined}
                                                onChange={(e: any) =>
                                                    props.setFieldValue("password", e.target.value)
                                                }
                                            />

                                            <FormFeedback>
                                                <>{props?.errors?.password}</>
                                            </FormFeedback>
                                        </FormGroup>
                                        <Row className="mt-5">
                                            <Col className="d-flex justify-content-center">

                                                <Button color="primary" type="submit" disabled={isLoading}>
                                                    {isLoading ?
                                                        <Spinner>
                                                            Loading...
                                                        </Spinner> :
                                                        <>Login</>}
                                                </Button>


                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <p className="text-center">
                                                Registration in free and you can cancel your account at
                                                any time
                                            </p>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Row>
                    </Col>
                </Row>
            </Row>
        </>
    );
};
export default Login;
