import { Col, FormFeedback, FormGroup, Input, Label, Row, Card, Button } from "reactstrap"
interface IBookJobPostProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>
}
const BoostJobPost = ({ current, setCurrent }: IBookJobPostProps) => {
    return <Row className="mt-3">
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">{current + 1}</div>
            </div>
        </Col>
        <Col xs={10}>
            <h4>Boost your job post  </h4>
            <Card className="p-4 mt-5">
                <FormGroup check >
                    <Label for="role">

                    </Label>
                    <Row>
                        <Col xs={8}>
                            <Input type="checkbox" />
                            <Label>Blinking effect: Urgently Looking, New job, women encouraged to apply, deadline</Label>
                        </Col>

                        <Col xs={4} className="text-center" style={{ color: "rgb(175, 229, 129)", fontWeight: 600 }}> +10000ISK</Col>
                    </Row>
                    <FormFeedback>

                    </FormFeedback>
                </FormGroup>
            </Card>
            <Card className="p-4 mt-3">
                <FormGroup check >
                    <Label for="role">

                    </Label>
                    <Row>
                        <Col xs={8}>
                            <Input type="checkbox" />
                            <Label>Bigger logo and bigger job post card</Label>
                        </Col>

                        <Col xs={4} className="text-center" style={{ color: "rgb(175, 229, 129)", fontWeight: 600 }}> +10000ISK</Col>
                    </Row>
                    <FormFeedback>

                    </FormFeedback>
                </FormGroup>
            </Card>
            <Card className="p-4 mt-3">
                <FormGroup check >
                    <Label for="role">

                    </Label>
                    <Row>
                        <Col xs={8}>
                            <Input type="checkbox" />
                            <Label>Running text on your post card</Label>
                        </Col>

                        <Col xs={4} className="text-center" style={{ color: "rgb(175, 229, 129)", fontWeight: 600 }}> +10000ISK</Col>
                    </Row>
                    <FormFeedback>

                    </FormFeedback>
                </FormGroup>
            </Card>

            <Card className="p-4 mt-3">
                <FormGroup check >
                    <Label for="role">

                    </Label>
                    <Row>
                        <Col xs={8}>
                            <Input type="checkbox" />
                            <Label>Booster Diversity for FREE </Label>
                        </Col>

                        <Col xs={4} className="text-center" style={{ color: "rgb(175, 229, 129)", fontWeight: 600 }}> +10000ISK</Col>
                    </Row>
                    <FormFeedback>

                    </FormFeedback>
                </FormGroup>
            </Card>

            <Card className="p-4 mt-3">
                <FormGroup check >
                    <Label for="role">

                    </Label>
                    <Row>
                        <Col xs={8}>
                            <Input type="checkbox" />
                            <Label>Sticky your post so it stays on TOP of the front page for 1 entire month</Label>
                        </Col>

                        <Col xs={4} className="text-center" style={{ color: "rgb(175, 229, 129)", fontWeight: 600 }}> +10000ISK</Col>
                    </Row>
                    <FormFeedback>

                    </FormFeedback>
                </FormGroup>
            </Card>

            <Card className="p-4 mt-3">
                <FormGroup check >
                    <Label for="role">

                    </Label>
                    <Row>
                        <Col xs={8}>
                            <Input type="checkbox" />
                            <Label>Ultra stick to top for 1 month (Always on top) </Label>
                        </Col>

                        <Col xs={4} className="text-center" style={{ color: "rgb(175, 229, 129)", fontWeight: 600 }}> +10000ISK</Col>
                    </Row>
                    <FormFeedback>

                    </FormFeedback>
                </FormGroup>
            </Card>
            <Row>
                <Col className="text-end mt-4">
                    <h3>Total 15000ISK</h3>
                </Col>
            </Row>
            <div className="d-flex justify-content-between">
                <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                <Button type="submit" color="primary" onClick={() => { setCurrent(current => current + 1) }}>Next</Button>
            </div>
        </Col>
    </Row>
}
export default BoostJobPost;