import { Form, Formik, FormikProps } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { AddComapnyMemberValidationSchema } from "../../Forms/AddComapnyMember/AddComapnyMember";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { addCompanyMember } from "../../store/Slice/Account/AccountThunk";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { setAccountCreation } from "../../store/Slice/Account/Account";

const AddMember = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const account = useSelector((state: RootState) => state.account);
    const submitForm = (e: any) => {
        console.log(e);
        dispatch(addCompanyMember(e));
    }
    useEffect(() => {
        if (account?.error) {
            toast.error(account.error);
        }
        if (account?.accountCreationValid) {
            dispatch(setAccountCreation({}));
            toast.success("Member added successfully");
            navigate("/company/members");
        }
    }, [account?.error, account?.accountCreationValid, navigate, dispatch])
    return <>
        <Row>
            <Col className="text-end p-4">
                <Link to="/company/members"> <Button color="primary">Members</Button></Link>
            </Col>
        </Row>
        <Row className="p-4">
            <Card>
                <CardHeader className="bg-white border-0 mt-4" ><h2 className="text-center">Add Member</h2></CardHeader>
                <CardBody>
                    <Formik initialValues={{
                        fname: '',
                        lname: '',
                        email: '',
                        password: '',
                        mobile: '',
                        gender: '',
                        address: '',
                        city: '',
                        state: '',
                        pin: '',
                    }} validationSchema={AddComapnyMemberValidationSchema} onSubmit={(e) => { submitForm(e) }}>
                        {(props: FormikProps<any>) => (
                            <Form>
                                <FormGroup>
                                    <Label for="fname">
                                        First Name
                                    </Label>
                                    <Input onChange={e => {
                                        props.setFieldValue("fname", e.target.value);
                                    }}
                                        value={props?.values?.fname || ''}
                                        invalid={props?.errors?.fname !== undefined} />
                                    <FormFeedback>
                                        <>{props?.errors?.fname}</>
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="lname">
                                        Last Name
                                    </Label>
                                    <Input onChange={e => {
                                        props.setFieldValue("lname", e.target.value);
                                    }}
                                        value={props?.values?.lname || ''}
                                        invalid={props?.errors?.lname !== undefined} />
                                    <FormFeedback>
                                        <>{props?.errors?.lname}</>
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">
                                        Email
                                    </Label>
                                    <Input type="email" onChange={e => {
                                        props.setFieldValue("email", e.target.value);
                                    }}
                                        value={props?.values?.email || ''}
                                        invalid={props?.errors?.email !== undefined} />
                                    <FormFeedback>
                                        <>{props?.errors?.email}</>
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">
                                        Password
                                    </Label>
                                    <Input type="password" onChange={e => {
                                        props.setFieldValue("password", e.target.value);
                                    }}
                                        value={props?.values?.password || ''}
                                        invalid={props?.errors?.password !== undefined} />
                                    <FormFeedback>
                                        <>{props?.errors?.password}</>
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="mobile">
                                        Mobile
                                    </Label>
                                    <Input onChange={e => {
                                        props.setFieldValue("mobile", e.target.value);
                                    }}
                                        value={props?.values?.mobile || ''}
                                        invalid={props?.errors?.mobile !== undefined} />
                                    <FormFeedback>
                                        <>{props?.errors?.mobile}</>
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="gender">
                                        Gender
                                    </Label>
                                    <Input type="select" onChange={e => {
                                        props.setFieldValue("gender", e.target.value);
                                    }}
                                        value={props?.values?.gender || ''}
                                        invalid={props?.errors?.gender !== undefined} >
                                        <option>--select--</option>
                                        <option>Male</option>
                                        <option>Female</option>
                                        <option>Other</option>
                                    </Input>
                                    <FormFeedback>
                                        <>{props?.errors?.gender}</>
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="address">
                                        Address
                                    </Label>
                                    <Input onChange={e => {
                                        props.setFieldValue("address", e.target.value);
                                    }}
                                        value={props?.values?.address || ''}
                                        invalid={props?.errors?.address !== undefined} />
                                    <FormFeedback>
                                        <>{props?.errors?.address}</>
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="city">
                                        City
                                    </Label>
                                    <Input onChange={e => {
                                        props.setFieldValue("city", e.target.value);
                                    }}
                                        value={props?.values?.city || ''}
                                        invalid={props?.errors?.city !== undefined} />
                                    <FormFeedback>
                                        <>{props?.errors?.city}</>
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="state">
                                        State
                                    </Label>
                                    <Input onChange={e => {
                                        props.setFieldValue("state", e.target.value);
                                    }}
                                        value={props?.values?.state || ''}
                                        invalid={props?.errors?.state !== undefined} />
                                    <FormFeedback>
                                        <>{props?.errors?.state}</>
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="pin">
                                        Pincode
                                    </Label>
                                    <Input onChange={e => {
                                        props.setFieldValue("pin", e.target.value);
                                    }}
                                        value={props?.values?.pin || ''}
                                        invalid={props?.errors?.pin !== undefined} />
                                    <FormFeedback>
                                        <>{props?.errors?.pin}</>
                                    </FormFeedback>
                                </FormGroup>
                                <CardFooter className="bg-white border-0" >
                                    <Button type="submit" color="primary">Save</Button>
                                </CardFooter>
                            </Form>

                        )}
                    </Formik>

                </CardBody>
            </Card>
        </Row>
    </>
}
export default AddMember;