import { useCallback, useEffect, useRef, useState } from "react";
import './SearchDropDown.css';
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
// import { Input } from "reactstrap";
const searchFilter = (searchValue: any, list: any, searchBy = 'name') => {
    let lowerCaseQuery = searchValue.toLowerCase();
    let filteredList = searchValue
        ? list.filter((x: any) => x[searchBy].toLowerCase().includes(lowerCaseQuery))
        : list;
    return filteredList;
};
const list = [
    { id: 1, type: 'Engineer', name: '🤓 Engineer' },
    { id: 2, type: 'Executive', name: '💼 Executive' },
    { id: 3, type: 'Senior', name: '👵 Senior' },
    { id: 4, type: 'Developer', name: '🤓 Developer' },
    { id: 5, type: 'Finance', name: '💰 Finance' },
    { id: 6, type: 'Sys Admin', name: '♾️ Sys Admin' },
    { id: 7, type: 'JavaScript', name: '☕️ JavaScript' },
    { id: 8, type: 'Backend Developer', name: '🍑 Backend' },
    { id: 9, type: 'Cloud', name: '☁️ Cloud' },
    { id: 10, type: 'Golang', name: '🐀 Golang' },
    { id: 11, type: 'Medical', name: '🚑 Medical' },
    { id: 12, type: 'Front-end Developer', name: '🎨 Front End' },
    { id: 13, type: 'Full stack Developer', name: '🥞 Full Stack' },
    { id: 14, type: 'Ops', name: '♾️ Ops' },
    { id: 15, type: 'Design', name: '🎨 Design' },
    { id: 16, type: 'React', name: '⚛️ React' },
    { id: 18, type: 'InfoSec', name: '🔑 InfoSec' },
    { id: 19, type: 'Marketing', name: '🚥 Marketing' },
    { id: 20, type: 'Mobile', name: '📱 Mobile' },
    { id: 21, type: 'Content Writing', name: '✍️ Content Writing' },
    { id: 22, type: 'SaaS', name: '📦 SaaS' },
    { id: 23, type: 'Recruiter', name: '🎯 Recruiter' },
    { id: 24, type: 'Full Time', name: '⏰ Full Time' },
    { id: 25, type: 'API', name: '🤖 API' },
];
interface ISearchDropDownProps {
    visible: Boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    searchValue: string;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>
}
const SearchDropDown = ({ visible, setVisible, searchValue, setSearchValue }: ISearchDropDownProps) => {
    // const [visible, setVisible] = useState(true);
    // const [searchValue, setSearchValue] = useState('');
    const darkMode = useSelector((state: RootState) => state.user?.darkMode);
    const [filter, setFilter] = useState('');
    const [, setSelectedItem] = useState(null);
    const dropdownRef = useRef(null);

    const selectItem = (item: any) => {
        setSearchValue(item.type);
        setSelectedItem(item.id);
        setVisible(false);
        setFilter('');
        // setSearchValue('');
    };
    const handleClick = useCallback((e: any) => {
        // if (selectedItem) { }
        //@ts-ignore
        if (dropdownRef?.current?.contains(e.target)) {
            return;
        }
        // if (visible) {
        setVisible(false);
        // }
    }, [setVisible]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, [handleClick]);
    return <>
        <div ref={dropdownRef} className={`search-drop-down col-5 dropdown ${visible ? 'v active' : ''} ${darkMode ? ' dark-mode' : ''}`} style={{ display: visible ? 'block' : 'none' }}>
            {/* <Input value={filter} onChange={(e: any) => { setFilter(e.target.value) }} style={{ visibility: visible ? 'visible' : 'hidden' }} /> */}
            {visible && (
                <ul>
                    {!list && (
                        <li key="zxc" className="dropdown_item p-3">
                            no result
                        </li>
                    )}
                    {list &&
                        searchFilter(filter, list).map((x: any) => (
                            <li
                                key={x.id}
                                onClick={() => { selectItem(x); }}
                                className="dropdown_item p-3"
                            >
                                <div className="item_text1" >{x.name}</div>
                            </li>
                        ))}
                </ul>
            )}
        </div>
    </>
}
export default SearchDropDown;