import { Form, Formik, FormikProps } from "formik";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Select from 'react-select';
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { EditLanguagesValidaitonSchema } from "../../../Forms/EditProfile/EditProfile";
interface IEditProfileProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    profile: Object;
    setProfile: React.Dispatch<React.SetStateAction<Object>>;
}
const EditLanguages = ({ current, setCurrent, profile, setProfile }: IEditProfileProps) => {
    //@ts-ignore
    const { languages } = profile;
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        setProfile({ ...profile, ...e });
    }
    return <Row>
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">{current + 1}</div>
            </div>
        </Col>
        <Col xs={10} >
            <div className="d-flex justify-content-between">
                <div>
                    <h4>Languages</h4>
                </div>
                <div>
                    <h4><MdOutlineModeEditOutline /> Edit</h4>
                </div>
            </div>
            <Formik initialValues={{
                languages: languages
            }} validationSchema={EditLanguagesValidaitonSchema} onSubmit={e => { submitForm(e) }}>
                {(props: FormikProps<any>) => (
                    <Form>
                        <FormGroup>
                            <Label for="languages">
                            </Label>
                            <Select
                                styles={{
                                    option: (defaultStyles, state) => ({
                                        ...defaultStyles,
                                        backgroundColor: (state.isFocused || state.isSelected) ? "#AFE581" : "#fff"
                                    }),
                                    control: (defaultStyles) => ({
                                        ...defaultStyles,
                                        borderColor: props.errors?.languages ? "#dc3545" : "#ced4da",
                                    }),
                                    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
                                }}
                                name="languages"
                                closeMenuOnSelect={false}
                                onChange={(newValue) => {
                                    props.setFieldValue("languages", newValue.length === 0 ? null : newValue);
                                }}
                                value={props?.values?.languages}
                                required
                                isSearchable
                                isMulti
                                onBlur={() => {
                                    props?.setFieldTouched("languages", true)
                                }}
                                options={[
                                    { value: "1", label: "English (International)", },
                                    { value: "2", label: "Uktranian (native)", }
                                ]}
                            />
                            <div className="text-danger">
                                <>{props?.errors?.languages}</>
                            </div>
                        </FormGroup>

                        <div className="d-flex justify-content-between mt-4">
                            <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                            <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Col>
    </Row>

}
export default EditLanguages;