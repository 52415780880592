import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Button, Col, Input, Row } from "reactstrap";
import { useSelector } from "react-redux";
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import MemberDetails from "../../Components/Common/MemberDetails/MemberDetails";
import { AppDispatch, RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { getComapnyMembers } from '../../store/Slice/Account/AccountThunk';
import { BsDot } from 'react-icons/bs';

const columns: TableColumn<{}>[] = [
    {
        name: 'Name',
        selector: (row: any) => row?.fname + ' ' + row?.lname,
        sortable: true,
    },
    {
        name: "Profile",
        selector: (row: any) => row?.profile_picture,
        cell(row, rowIndex, column, id) {
            console.log(row)
            return <img
                className='p-3'
                //@ts-ignore
                src={row.profile_picture ? row.profile_picture : '/Avatar.png'} alt="profile" />
        },
        sortable: false
    },
    {
        name: 'Email',
        selector: (row: any) => row?.email,
        sortable: true,
    },
    {
        name: 'MobileNo.',
        selector: (row: any) => row?.mobile,
        sortable: true,
    },
    {
        name: 'JoiedOn.',
        selector: (row: any) => row?.createdAt,
        sortable: true,
    },
    {
        name: 'Gender',
        selector: (row: any) => row?.gender,
        sortable: true,
    },
    {
        name: 'Status',
        selector: (row: any) => row?.status,
        sortable: true,
    },
    {
        name: 'Verified',
        selector: (row: any) => row?.verified,
        sortable: true,
        cell(row, rowIndex, column, id) {
            return <BsDot size={50} fontSize={15} color='var(--mainColor)' accentHeight={200} />
        },
    },

];
const Members = () => {
    const darkMode = useSelector((state: RootState) => state.user.darkMode)
    const accounts = useSelector((state: RootState) => state.account);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (accounts.accountDetailsValid)
            dispatch(getComapnyMembers({}));
    }, [dispatch, accounts.accountDetailsValid]);
    const [search, setSearch] = useState<string>('');
    return <>
        <Row>
            <Col className="text-end p-4">
                <Link to="/company/add-member"> <Button color="primary">Add Member</Button></Link>
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                <h2>Members</h2>
            </Col>
            <div className="data-table">
                <DataTable
                    striped
                    theme={darkMode ? 'dark' : undefined}
                    fixedHeader
                    subHeader={<h1>Header</h1>}
                    subHeaderComponent={<>
                        <div>
                            <Input type='search' placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </>}
                    title="Members"
                    pagination
                    selectableRows
                    paginationComponentOptions={{
                        selectAllRowsItem: true,
                        selectAllRowsItemText: "ALL"

                    }}
                    responsive
                    columns={columns} data={accounts.accounts.filter(acc =>
                        acc?.fname?.toUpperCase().includes(search.toUpperCase()) ||
                        acc?.lname?.toUpperCase().includes(search.toUpperCase()) ||
                        acc?.mobile?.toUpperCase().includes(search.toUpperCase()) ||
                        acc?.email?.toUpperCase().includes(search.toUpperCase())
                    )}
                    expandableRows
                    expandableRowsComponent={(props: ExpanderComponentProps<{}>) => <>
                        <MemberDetails data={props.data} />
                    </>}
                />
            </div>
        </Row>
    </>
}
export default Members;