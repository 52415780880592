import { MdOutlineModeEditOutline } from "react-icons/md";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { EditProfileValidationSchema } from "../../../Forms/EditProfile/EditProfile";
import { Form, Formik, FormikProps } from "formik";

interface IEditProfileProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    profile: Object;
    setProfile: React.Dispatch<React.SetStateAction<Object>>;
}
const ContactInfo = ({ current, setCurrent, profile, setProfile }: IEditProfileProps) => {
    //@ts-ignore
    const { mobile, email, linkedIn, lname, fname } = profile;
    const submitForm = (e: any) => {
        // console.log('submitted');
        setCurrent(current => current + 1);
        // console.log(e)
        setProfile({ ...profile, ...e })
    }
    return <Row>
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">{current + 1}</div>
            </div>
        </Col>
        <Col xs={10} >
            <div className="d-flex justify-content-between">
                <div>
                    <h4>Contact Information</h4>
                </div>
                <div>
                    <h4><MdOutlineModeEditOutline /> Edit</h4>
                </div>
            </div>
            <Formik onSubmit={e => { submitForm(e) }} initialValues={
                {
                    lname: lname,
                    fname: fname,
                    mobile: mobile,
                    email: email,
                    linkedIn: linkedIn
                }
            }
                validationSchema={EditProfileValidationSchema}>
                {(props: FormikProps<any>) => (
                    <Form>
                        <FormGroup>
                            <Label for="fname">
                                First Name *
                            </Label>
                            <Input invalid={props?.errors?.fname !== undefined}
                                value={props?.values?.fname || ''}
                                onChange={(e: any) =>
                                    props.setFieldValue("fname", e.target.value)
                                } />
                            <FormFeedback>
                                <>{props.errors?.fname}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="lname">
                                Last Name *
                            </Label>
                            <Input invalid={props?.errors?.lname !== undefined}
                                value={props?.values?.lname || ''}
                                onChange={(e: any) =>
                                    props.setFieldValue("lname", e.target.value)
                                } />
                            <FormFeedback>
                                <>{props.errors?.lname}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="mobile">
                                Mobile *
                            </Label>
                            <Input invalid={props?.errors?.mobile !== undefined}
                                value={props?.values?.mobile || ''}
                                onChange={(e: any) =>
                                    props.setFieldValue("mobile", e.target.value)
                                } />
                            <FormFeedback>
                                <>{props.errors?.mobile}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="email">
                                Email *
                            </Label>
                            <Input invalid={props?.errors?.email !== undefined}
                                value={props?.values?.email || ''}
                                onChange={(e: any) =>
                                    props.setFieldValue("email", e.target.value)
                                } />
                            <FormFeedback>
                                <>{props.errors?.email}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="LinkedIn">
                                LinkedIn
                            </Label>
                            <Input invalid={props?.errors?.linkedIn !== undefined}
                                value={props?.values?.linkedIn || ''}
                                onChange={(e: any) =>
                                    props.setFieldValue("linkedIn", e.target.value)
                                } />
                            <FormFeedback>
                                <> {props?.errors?.linkedIn}</>
                            </FormFeedback>
                        </FormGroup>
                        <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                    </Form>
                )}
            </Formik>
        </Col>
    </Row >
}
export default ContactInfo;