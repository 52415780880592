import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import SideMenu from "../SideMenu/SideMenu";
import User from "../Common/UserProfile/User";
import './MyProfileEdit.css';
import ContactInfo from "./FormComponents/ContactInfo";
import AboutMe from "./FormComponents/AboutMe";
import WorkExperience from "./FormComponents/WorkExperience";
import Education from "./FormComponents/Education";
import EditLanguages from "./FormComponents/EditLanguages";
import MySkills from "./FormComponents/MySkills";
import UploadCV from "./FormComponents/UploadCV";
import Porfolio from "./FormComponents/Portfolio";
import ChooseBenifits from "./FormComponents/ChooseBenifits";

const MyProfileEdit = () => {
    const [current, setCurrent] = useState(0);
    const [profile, setProfile] = useState<Object>({});
    const [filesObject ,setFilesObject ] =useState<Object[]>([]);
    const forms = [
        <ContactInfo current={current} setCurrent={setCurrent} profile={profile} setProfile={setProfile} />,
        <AboutMe current={current} setCurrent={setCurrent} profile={profile} setProfile={setProfile} />,
        <WorkExperience current={current} setCurrent={setCurrent} profile={profile} setProfile={setProfile} />,
        <Education current={current} setCurrent={setCurrent} profile={profile} setProfile={setProfile} />,
        <EditLanguages current={current} setCurrent={setCurrent} profile={profile} setProfile={setProfile} />,
        <MySkills current={current} setCurrent={setCurrent} profile={profile} setProfile={setProfile} />,
        <UploadCV current={current} setCurrent={setCurrent} profile={profile} setProfile={setProfile}
         filesObject={filesObject} setFilesObject={setFilesObject} />,
        <Porfolio current={current} setCurrent={setCurrent} profile={profile} setProfile={setProfile} />,
        <ChooseBenifits current={current} setCurrent={setCurrent} profile={profile}/>
    ];
    return <>
        <Row>
            <Col xs={3} >
                <SideMenu />
            </Col>
            <Col xs={8}>
                <Col xs={12} className="d-flex justify-content-center">
                    <User avatarUrl="/Avatar.png" contactNumber="+420775430021" email="radmilacolic@gmail.com" userName="Radmila Colic" />
                </Col>
                <Row className="mt-5">
                    <Card className="border-0">
                        <CardBody className="profile-cards">
                            {forms?.[current]}
                        </CardBody>
                    </Card>
                </Row>
            </Col>
        </Row>
    </>
}
export default MyProfileEdit;