import { useCallback, useEffect, useRef, useState } from "react";
import { searchFilter } from "../../../utils/helper/searchFilter";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
const list = [
    { id: 1, name: '🌏 Worldwide', type: '🌏 Worldwide' },
    { id: 2, name: '⛰️ North America', type: '⛰️ North America' },
    { id: 3, name: '💃 Latin America', type: '💃 Latin America' },
    { id: 4, name: '🇪🇺 Europe', type: '🇪🇺 Europe' },
    { id: 5, name: '🦁 Africa', type: '🦁 Africa' },
    { id: 6, name: '🕌 Middle East', type: '🕌 Middle East' },
    { id: 7, name: '⛩ Asia', type: '⛩ Asia' },
    { id: 8, name: '🌊 Oceania', type: '🌊 Oceania' },
    { id: 9, name: 'Countries', type: 'Countries' },
    { 'name': '🇺🇸 United States', 'type': '🇺🇸 United States' },
    { 'name': '🇨🇦 Canada', 'type': '🇨🇦 Canada' },
    { 'name': '🇬🇧 United Kingdom', 'type': '🇬🇧 United Kingdom' },
    { 'name': '🇦🇺 Australia', 'type': '🇦🇺 Australia' },
    { 'name': '🇳🇿 New Zealand', 'type': '🇳🇿 New Zealand' },
    { 'name': '🇮🇳 India', 'type': '🇮🇳 India' },
    { 'name': '🇵🇹 Portugal', 'type': '🇵🇹 Portugal' },
    { 'name': '🇩🇪 Germany', 'type': '🇩🇪 Germany' },
    { 'name': '🇳🇱 Netherlands', 'type': '🇳🇱 Netherlands' },
    { 'name': '🇸🇬 Singapore', 'type': '🇸🇬 Singapore' },
    { 'name': '🇫🇷 France', 'type': '🇫🇷 France' },
    { 'name': '🇭🇰 Hong Kong', 'type': '🇭🇰 Hong Kong' },
    { 'name': '🇧🇷 Brazil', 'type': '🇧🇷 Brazil' },
    { 'name': '🇬🇷 Greece', 'type': '🇬🇷 Greece' },
    { 'name': '🇦🇪 United Arab Emirates', 'type': '🇦🇪 United Arab Emirates' },
    { 'name': '🇸🇪 Sweden', 'type': '🇸🇪 Sweden' },
    { 'name': '🇵🇱 Poland', 'type': '🇵🇱 Poland' },
    { 'name': '🇪🇸 Spain', 'type': '🇪🇸 Spain' },
    { 'name': '🇲🇽 Mexico', 'type': '🇲🇽 Mexico' },
    { 'name': '🇺🇦 Ukraine', 'type': '🇺🇦 Ukraine' },
    { 'name': '🇯🇵 Japan', 'type': '🇯🇵 Japan' },
    { 'name': '🇹🇭 Thailand', 'type': '🇹🇭 Thailand' },
    { 'name': '🇨🇿 Czechia', 'type': '🇨🇿 Czechia' },
    { 'name': '🇷🇺 Russia', 'type': '🇷🇺 Russia' },
    { 'name': '🇮🇱 Israel', 'type': '🇮🇱 Israel' },
    { 'name': '🇫🇮 Finland', 'type': '🇫🇮 Finland' },
    { 'name': '🇨🇳 China', 'type': '🇨🇳 China' },
    { 'name': '🇮🇩 Indonesia', 'type': '🇮🇩 Indonesia' },
    { 'name': '🇦🇫 Afghanistan', 'type': '🇦🇫 Afghanistan' },
    { 'name': '🇦🇱 Albania', 'type': '🇦🇱 Albania' },
    { 'name': '🇩🇿 Algeria', 'type': '🇩🇿 Algeria' },
    { 'name': '🇦🇸 American Samoa', 'type': '🇦🇸 American Samoa' },
    { 'name': '🇦🇩 Andorra', 'type': '🇦🇩 Andorra' },
    { 'name': '🇦🇴 Angola', 'type': '🇦🇴 Angola' },
    { 'name': '🇦🇮 Anguilla', 'type': '🇦🇮 Anguilla' },
    { 'name': '🇦🇶 Antarctica', 'type': '🇦🇶 Antarctica' },
    { 'name': '🇦🇬 Antigua and Barbuda', 'type': '🇦🇬 Antigua and Barbuda' },
    { 'name': '🇦🇷 Argentina', 'type': '🇦🇷 Argentina' },
    { 'name': '🇦🇲 Armenia', 'type': '🇦🇲 Armenia' },
    { 'name': '🇦🇼 Aruba', 'type': '🇦🇼 Aruba' },
    { 'name': '🇦🇹 Austria', 'type': '🇦🇹 Austria' },
    { 'name': '🇦🇿 Azerbaijan', 'type': '🇦🇿 Azerbaijan' },
    { 'name': '🇧🇸 The Bahamas', 'type': '🇧🇸 The Bahamas' },
    { 'name': '🇧🇭 Bahrain', 'type': '🇧🇭 Bahrain' },
    { 'name': '🇧🇩 Bangladesh', 'type': '🇧🇩 Bangladesh' },
    { 'name': '🇧🇧 Barbados', 'type': '🇧🇧 Barbados' },
    { 'name': '🇧🇾 Belarus', 'type': '🇧🇾 Belarus' },
    { 'name': '🇧🇪 Belgium', 'type': '🇧🇪 Belgium' },
    { 'name': '🇧🇿 Belize', 'type': '🇧🇿 Belize' },
    { 'name': '🇧🇯 Benin', 'type': '🇧🇯 Benin' },
    { 'name': '🇧🇲 Bermuda', 'type': '🇧🇲 Bermuda' },
    { 'name': '🇧🇹 Bhutan', 'type': '🇧🇹 Bhutan' },
    { 'name': '🇧🇴 Bolivia', 'type': '🇧🇴 Bolivia' },
    { 'name': '🇧🇦 Bosnia', 'type': '🇧🇦 Bosnia' },
    { 'name': '🇧🇼 Botswana', 'type': '🇧🇼 Botswana' },
    { 'name': '🇧🇻 Bouvet Island', 'type': '🇧🇻 Bouvet Island' },
    { 'name': '🇮🇴 British Indian Ocean Territory', 'type': '🇮🇴 British Indian Ocean Territory' },
    { 'name': '🇧🇳 Brunei', 'type': '🇧🇳 Brunei' },
    { 'name': '🇧🇬 Bulgaria', 'type': '🇧🇬 Bulgaria' },
    { 'name': '🇧🇫 Burkina Faso', 'type': '🇧🇫 Burkina Faso' },
    { 'name': '🇧🇮 Burundi', 'type': '🇧🇮 Burundi' },
    { 'name': '🇰🇭 Cambodia', 'type': '🇰🇭 Cambodia' },
    { 'name': '🇨🇲 Cameroon', 'type': '🇨🇲 Cameroon' },
    { 'name': '🇨🇻 Cape Verde', 'type': '🇨🇻 Cape Verde' },
    { 'name': '🇰🇾 Cayman Islands', 'type': '🇰🇾 Cayman Islands' },
    { 'name': '🇨🇫 Central African Republic', 'type': '🇨🇫 Central African Republic' },
    { 'name': '🇹🇩 Chad', 'type': '🇹🇩 Chad' },
    { 'name': '🇨🇱 Chile', 'type': '🇨🇱 Chile' },
    { 'name': '🇨🇽 Christmas Island', 'type': '🇨🇽 Christmas Island' },
    { 'name': '🇨🇨 Cocos Islands', 'type': '🇨🇨 Cocos Islands' },
    { 'name': '🇨🇴 Colombia', 'type': '🇨🇴 Colombia' },
    { 'name': '🇰🇲 Comoros', 'type': '🇰🇲 Comoros' },
    { 'name': '🇨🇬 Congo', 'type': '🇨🇬 Congo' },
    { 'name': '🇨🇩 DR Congo', 'type': '🇨🇩 DR Congo' },
    { 'name': '🇨🇰 Cook Islands', 'type': '🇨🇰 Cook Islands' },
    { 'name': '🇨🇷 Costa Rica', 'type': '🇨🇷 Costa Rica' },
    { 'name': '🇭🇷 Croatia', 'type': '🇭🇷 Croatia' },
    { 'name': '🇨🇺 Cuba', 'type': '🇨🇺 Cuba' },
    { 'name': '🇨🇼 Curaçao', 'type': '🇨🇼 Curaçao' },
    { 'name': '🇨🇾 Cyprus', 'type': '🇨🇾 Cyprus' },
    { 'name': '🇩🇰 Denmark', 'type': '🇩🇰 Denmark' },
    { 'name': '🇩🇯 Djibouti', 'type': '🇩🇯 Djibouti' },
    { 'name': '🇩🇲 Dominica', 'type': '🇩🇲 Dominica' },
    { 'name': '🇩🇴 Dominican Republic', 'type': '🇩🇴 Dominican Republic' },
    { 'name': '🇪🇨 Ecuador', 'type': '🇪🇨 Ecuador' },
    { 'name': '🇪🇬 Egypt', 'type': '🇪🇬 Egypt' },
    { 'name': '🇸🇻 El Salvador', 'type': '🇸🇻 El Salvador' },
    { 'name': '🇬🇶 Equatorial Guinea', 'type': '🇬🇶 Equatorial Guinea' },
    { 'name': '🇪🇷 Eritrea', 'type': '🇪🇷 Eritrea' },
    { 'name': '🇪🇪 Estonia', 'type': '🇪🇪 Estonia' },
    { 'name': '🇪🇹 Ethiopia', 'type': '🇪🇹 Ethiopia' },
    { 'name': '🇫🇰 Falkland Islands', 'type': '🇫🇰 Falkland Islands' },
    { 'name': '🇫🇴 Faroe Islands', 'type': '🇫🇴 Faroe Islands' },
    { 'name': '🇫🇯 Fiji', 'type': '🇫🇯 Fiji' },
    { 'name': '🇬🇫 French Guiana', 'type': '🇬🇫 French Guiana' },
    { 'name': '🇹🇱 East Timor', 'type': '🇹🇱 East Timor' },
    { 'name': '🇹🇫 French Southern Territories', 'type': '🇹🇫 French Southern Territories' },
    { 'name': '🇬🇦 Gabon', 'type': '🇬🇦 Gabon' },
    { 'name': '🇬🇲 Gambia', 'type': '🇬🇲 Gambia' },
    { 'name': '🇬🇪 Georgia', 'type': '🇬🇪 Georgia' },
    { 'name': '🇬🇭 Ghana', 'type': '🇬🇭 Ghana' },
    { 'name': '🇬🇮 Gibraltar', 'type': '🇬🇮 Gibraltar' },
    { 'name': '🇬🇱 Greenland', 'type': '🇬🇱 Greenland' },
    { 'name': '🇬🇩 Grenada', 'type': '🇬🇩 Grenada' },
    { 'name': '🇬🇵 Guadeloupe', 'type': '🇬🇵 Guadeloupe' },
    { 'name': '🇬🇺 Guam', 'type': '🇬🇺 Guam' },
    { 'name': '🇬🇹 Guatemala', 'type': '🇬🇹 Guatemala' },
    { 'name': '🇬🇬 Guernsey', 'type': '🇬🇬 Guernsey' },
    { 'name': '🇬🇳 Guinea', 'type': '🇬🇳 Guinea' },
    { 'name': '🇬🇼 Guinea Bissau', 'type': '🇬🇼 Guinea Bissau' },
    { 'name': '🇬🇾 Guyana', 'type': '🇬🇾 Guyana' },
    { 'name': '🇭🇹 Haiti', 'type': '🇭🇹 Haiti' },
    { 'name': '🇭🇲 Heard Island and McDonald Islands', 'type': '🇭🇲 Heard Island and McDonald Islands' },
    { 'name': '🇭🇳 Honduras', 'type': '🇭🇳 Honduras' },
    { 'name': '🇭🇺 Hungary', 'type': '🇭🇺 Hungary' },
    { 'name': '🇮🇸 Iceland', 'type': '🇮🇸 Iceland' },
    { 'name': '🇮🇷 Iran', 'type': '🇮🇷 Iran' },
    { 'name': '🇮🇶 Iraq', 'type': '🇮🇶 Iraq' },
    { 'name': '🇮🇪 Ireland', 'type': '🇮🇪 Ireland' },
    { 'name': '🇮🇲 Isle of Man', 'type': '🇮🇲 Isle of Man' },
    { 'name': '🇮🇹 Italy', 'type': '🇮🇹 Italy' },
    {
        'name': "🇨🇮 Cote d'Ivoire", 'type': "🇨🇮 Cote d'Ivoire"
    },
    { 'name': '🇯🇲 Jamaica', 'type': '🇯🇲 Jamaica' },
    { 'name': '🇯🇪 Jersey', 'type': '🇯🇪 Jersey' },
    { 'name': '🇯🇴 Jordan', 'type': '🇯🇴 Jordan' },
    { 'name': '🇽🇰 Kosovo', 'type': '🇽🇰 Kosovo' },
    { 'name': '🇽🇰 Kosovo', 'type': '🇽🇰 Kosovo' },
    { 'name': '🇰🇿 Kazakhstan', 'type': '🇰🇿 Kazakhstan' },
    { 'name': '🇰🇪 Kenya', 'type': '🇰🇪 Kenya' },
    { 'name': '🇰🇮 Kiribati', 'type': '🇰🇮 Kiribati' },
    { 'name': '🇰🇵 North Korea', 'type': '🇰🇵 North Korea' },
    { 'name': '🇰🇷 South Korea', 'type': '🇰🇷 South Korea' },
    { 'name': '🏴 Kurdistan', 'type': '🏴 Kurdistan' },
    { 'name': '🇰🇼 Kuwait', 'type': '🇰🇼 Kuwait' },
    { 'name': '🇰🇬 Kyrgyzstan', 'type': '🇰🇬 Kyrgyzstan' },
    { 'name': '🇱🇦 Laos', 'type': '🇱🇦 Laos' },
    { 'name': '🇱🇻 Latvia', 'type': '🇱🇻 Latvia' },
    { 'name': '🇱🇧 Lebanon', 'type': '🇱🇧 Lebanon' },
    { 'name': '🇱🇸 Lesotho', 'type': '🇱🇸 Lesotho' },
    { 'name': '🇱🇷 Liberia', 'type': '🇱🇷 Liberia' },
    { 'name': '🇱🇾 Libya', 'type': '🇱🇾 Libya' },
    { 'name': '🇱🇮 Liechtenstein', 'type': '🇱🇮 Liechtenstein' },
    { 'name': '🇱🇹 Lithuania', 'type': '🇱🇹 Lithuania' },
    { 'name': '🇱🇺 Luxembourg', 'type': '🇱🇺 Luxembourg' },
    { 'name': '🇲🇴 Macau', 'type': '🇲🇴 Macau' },
    { 'name': '🇲🇰 North Macedonia', 'type': '🇲🇰 North Macedonia' },
    { 'name': '🇲🇬 Madagascar', 'type': '🇲🇬 Madagascar' },
    { 'name': '🇲🇼 Malawi', 'type': '🇲🇼 Malawi' },
    { 'name': '🇲🇾 Malaysia', 'type': '🇲🇾 Malaysia' },
    { 'name': '🇲🇻 Maldives', 'type': '🇲🇻 Maldives' },
    { 'name': '🇲🇱 Mali', 'type': '🇲🇱 Mali' },
    { 'name': '🇲🇹 Malta', 'type': '🇲🇹 Malta' },
    { 'name': '🇲🇭 Marshall Islands', 'type': '🇲🇭 Marshall Islands' },
    { 'name': '🇲🇶 Martinique', 'type': '🇲🇶 Martinique' },
    { 'name': '🇲🇷 Mauritania', 'type': '🇲🇷 Mauritania' },
    { 'name': '🇲🇺 Mauritius', 'type': '🇲🇺 Mauritius' },
    { 'name': '🇾🇹 Mayotte', 'type': '🇾🇹 Mayotte' },
    { 'name': '🇫🇲 Micronesia', 'type': '🇫🇲 Micronesia' },
    { 'name': '🇲🇩 Moldova', 'type': '🇲🇩 Moldova' },
    { 'name': '🇲🇨 Monaco', 'type': '🇲🇨 Monaco' },
    { 'name': '🇲🇳 Mongolia', 'type': '🇲🇳 Mongolia' },
    { 'name': '🇲🇪 Montenegro', 'type': '🇲🇪 Montenegro' },
    { 'name': '🇲🇸 Montserrat', 'type': '🇲🇸 Montserrat' },
    { 'name': '🇲🇦 Morocco', 'type': '🇲🇦 Morocco' },
    { 'name': '🇲🇿 Mozambique', 'type': '🇲🇿 Mozambique' },
    { 'name': '🇲🇲 Myanmar', 'type': '🇲🇲 Myanmar' },
    { 'name': '🇳🇦 Namibia', 'type': '🇳🇦 Namibia' },
    { 'name': '🇳🇷 Nauru', 'type': '🇳🇷 Nauru' },
    { 'name': '🇳🇵 Nepal', 'type': '🇳🇵 Nepal' },
    { 'name': '🇧🇶 Caribbean Netherlands', 'type': '🇧🇶 Caribbean Netherlands' },
    { 'name': '🇳🇨 New Caledonia', 'type': '🇳🇨 New Caledonia' },
    { 'name': '🇳🇮 Nicaragua', 'type': '🇳🇮 Nicaragua' },
    { 'name': '🇳🇪 Niger', 'type': '🇳🇪 Niger' },
    { 'name': '🇳🇬 Nigeria', 'type': '🇳🇬 Nigeria' },
    { 'name': '🇳🇺 Niue', 'type': '🇳🇺 Niue' },
    { 'name': '🇳🇫 Norfolk Island', 'type': '🇳🇫 Norfolk Island' },
    { 'name': '🇲🇵 Northern Mariana Islands', 'type': '🇲🇵 Northern Mariana Islands' },
    { 'name': '🇳🇴 Norway', 'type': '🇳🇴 Norway' },
    { 'name': '🇴🇲 Oman', 'type': '🇴🇲 Oman' },
    { 'name': '🇵🇸 Palestine', 'type': '🇵🇸 Palestine' },
    { 'name': '🇵🇰 Pakistan', 'type': '🇵🇰 Pakistan' },
    { 'name': '🇵🇼 Palau', 'type': '🇵🇼 Palau' },
    { 'name': '🇵🇦 Panama', 'type': '🇵🇦 Panama' },
    { 'name': '🇵🇬 Papua New Guinea', 'type': '🇵🇬 Papua New Guinea' },
    { 'name': '🇵🇾 Paraguay', 'type': '🇵🇾 Paraguay' },
    { 'name': '🇵🇪 Peru', 'type': '🇵🇪 Peru' },
    { 'name': '🇵🇭 Philippines', 'type': '🇵🇭 Philippines' },
    { 'name': '🇵🇳 Pitcairn Island', 'type': '🇵🇳 Pitcairn Island' },
    { 'name': '🇵🇫 Polynesia', 'type': '🇵🇫 Polynesia' },
    { 'name': '🇵🇷 Puerto Rico', 'type': '🇵🇷 Puerto Rico' },
    { 'name': '🇶🇦 Qatar', 'type': '🇶🇦 Qatar' },
    { 'name': '🇷🇪 Reunion', 'type': '🇷🇪 Reunion' },
    { 'name': '🇷🇴 Romania', 'type': '🇷🇴 Romania' },
    { 'name': '🇷🇼 Rwanda', 'type': '🇷🇼 Rwanda' },
    { 'name': '🇸🇭 Saint Helena', 'type': '🇸🇭 Saint Helena' },
    { 'name': '🇰🇳 Saint Kitts and Nevis', 'type': '🇰🇳 Saint Kitts and Nevis' },
    { 'name': '🇱🇨 Saint Lucia', 'type': '🇱🇨 Saint Lucia' },
    { 'name': '🇵🇲 Saint Pierre and Miquelon', 'type': '🇵🇲 Saint Pierre and Miquelon' },
    { 'name': '🇻🇨 Saint Vincent and the Grenadines', 'type': '🇻🇨 Saint Vincent and the Grenadines' },
    { 'name': '🇼🇸 Samoa', 'type': '🇼🇸 Samoa' },
    { 'name': '🇸🇲 San Marino', 'type': '🇸🇲 San Marino' },
    { 'name': '🇸🇹 Sao Tome and Principe', 'type': '🇸🇹 Sao Tome and Principe' },
    { 'name': '🇸🇦 Saudi Arabia', 'type': '🇸🇦 Saudi Arabia' },
    { 'name': '🇸🇳 Senegal', 'type': '🇸🇳 Senegal' },
    { 'name': '🇷🇸 Serbia', 'type': '🇷🇸 Serbia' },
    { 'name': '🇸🇨 Seychelles', 'type': '🇸🇨 Seychelles' },
    { 'name': '🇸🇱 Sierra Leone', 'type': '🇸🇱 Sierra Leone' },
    { 'name': '🇲🇫 Saint-Martin', 'type': '🇲🇫 Saint-Martin' },
    { 'name': '🇸🇽 Sint Maarten', 'type': '🇸🇽 Sint Maarten' },
    { 'name': '🇸🇰 Slovakia', 'type': '🇸🇰 Slovakia' },
    { 'name': '🇸🇮 Slovenia', 'type': '🇸🇮 Slovenia' },
    { 'name': '🇸🇧 Solomon Islands', 'type': '🇸🇧 Solomon Islands' },
    { 'name': '🇸🇴 Somalia', 'type': '🇸🇴 Somalia' },
    { 'name': '🇿🇦 South Africa', 'type': '🇿🇦 South Africa' },
    { 'name': '🇬🇸 South Georgia and the South Sandwich Islands', 'type': '🇬🇸 South Georgia and the South Sandwich Islands' },
    { 'name': '🇸🇸 South Sudan', 'type': '🇸🇸 South Sudan' },
    { 'name': '🇱🇰 Sri Lanka', 'type': '🇱🇰 Sri Lanka' },
    { 'name': '🇸🇩 Sudan', 'type': '🇸🇩 Sudan' },
    { 'name': '🇸🇷 Suriname', 'type': '🇸🇷 Suriname' },
    { 'name': '🇸🇯 Svalbard and Jan Mayen Islands', 'type': '🇸🇯 Svalbard and Jan Mayen Islands' },
    { 'name': '🇸🇿 Swaziland', 'type': '🇸🇿 Swaziland' },
    { 'name': '🇨🇭 Switzerland', 'type': '🇨🇭 Switzerland' },
    { 'name': '🇸🇾 Syria', 'type': '🇸🇾 Syria' },
    { 'name': '🇹🇼 Taiwan', 'type': '🇹🇼 Taiwan' },
    { 'name': '🇹🇯 Tajikistan', 'type': '🇹🇯 Tajikistan' },
    { 'name': '🇹🇿 Tanzania', 'type': '🇹🇿 Tanzania' },
    { 'name': '🇹🇬 Togo', 'type': '🇹🇬 Togo' },
    { 'name': '🇹🇰 Tokelau', 'type': '🇹🇰 Tokelau' },
    { 'name': '🇹🇴 Tonga', 'type': '🇹🇴 Tonga' },
    { 'name': '🇹🇹 Trinidad and Tobago', 'type': '🇹🇹 Trinidad and Tobago' },
    { 'name': '🇹🇳 Tunisia', 'type': '🇹🇳 Tunisia' },
    { 'name': '🇹🇷 Turkey', 'type': '🇹🇷 Turkey' },
    { 'name': '🇹🇲 Turkmenistan', 'type': '🇹🇲 Turkmenistan' },
    { 'name': '🇹🇨 Turks and Caicos Islands', 'type': '🇹🇨 Turks and Caicos Islands' },
    { 'name': '🇹🇻 Tuvalu', 'type': '🇹🇻 Tuvalu' },
    { 'name': '🇺🇬 Uganda', 'type': '🇺🇬 Uganda' },
    { 'name': '🇺🇾 Uruguay', 'type': '🇺🇾 Uruguay' },
    { 'name': '🏝 Hawaii', 'type': '🏝 Hawaii' },
    { 'name': '🇺🇲 USA Minor Outlying Islands', 'type': '🇺🇲 USA Minor Outlying Islands' },
    { 'name': '🇺🇿 Uzbekistan', 'type': '🇺🇿 Uzbekistan' },
    { 'name': '🇻🇺 Vanuatu', 'type': '🇻🇺 Vanuatu' },
    { 'name': '🇻🇦 Vatican City', 'type': '🇻🇦 Vatican City' },
    { 'name': '🇻🇪 Venezuela', 'type': '🇻🇪 Venezuela' },
    { 'name': '🇻🇳 Vietnam', 'type': '🇻🇳 Vietnam' },
    { 'name': '🇻🇬 British Virgin Islands', 'type': '🇻🇬 British Virgin Islands' },
    { 'name': '🇻🇮 United States Virgin Islands', 'type': '🇻🇮 United States Virgin Islands' },
    { 'name': '🇼🇫 Wallis and Futuna Islands', 'type': '🇼🇫 Wallis and Futuna Islands' },
    { 'name': '🇪🇭 Western Sahara', 'type': '🇪🇭 Western Sahara' },
    { 'name': '🇾🇪 Yemen', 'type': '🇾🇪 Yemen' },
    { 'name': '🇿🇲 Zambia', 'type': '🇿🇲 Zambia' },
    { 'name': '🇿🇼 Zimbabwe', 'type': '🇿🇼 Zimbabwe' },
];
interface ILocationDropDownProps {
    visible: Boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    searchValue: string;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>
}
const LocationFilter = ({ visible, setVisible, searchValue, setSearchValue }: ILocationDropDownProps) => {
    const darkMode = useSelector((state: RootState) => state.user?.darkMode);

    const [, setSelectedItem] = useState(null);
    const dropdownRef = useRef(null);

    const handleClick = useCallback((e: any) => {
        // if (selectedItem) { }
        //@ts-ignore
        if (dropdownRef?.current?.contains(e.target)) {
            return;
        }
        setVisible(false);
    }, [setVisible]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, [handleClick]);


    const selectItem = (item: any) => {
        setSearchValue(item.name);
        setSelectedItem(item.id);
        setVisible(false);
    };
    return <div ref={dropdownRef} className={`search-drop-down col-2 dropdown ${visible ? 'v active' : ''} ${darkMode ? ' dark-mode' : ''}`}>
        {visible && (
            <ul>
                {!list && (
                    <li key="zxc" className="dropdown_item p-3">
                        no result
                    </li>
                )}
                {list &&
                    searchFilter(searchValue, list).map((x: any, index: number) => (
                        <li
                            key={index}
                            onClick={() => selectItem(x)}
                            className="dropdown_item p-3"
                        >
                            <div className="item_text1">{x.name}</div>
                        </li>
                    ))}
            </ul>
        )}
    </div>
}
export default LocationFilter;