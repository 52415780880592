import { MdOutlineModeEditOutline } from "react-icons/md";
import { Button, Col, Row } from "reactstrap";
import { EditProfileAboutMe } from "../../../Forms/EditProfile/EditProfile";
import { Form, Formik, FormikProps } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
interface IEditProfileProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    profile: Object;
    setProfile: React.Dispatch<React.SetStateAction<Object>>;
}
const AboutMe = ({ current, setCurrent, profile, setProfile }: IEditProfileProps) => {
    //@ts-ignore
    const { aboutMe } = profile;
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        setProfile({ ...profile, ...e })
    }
    return <Row>
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">{current + 1}</div>
            </div>
        </Col>
        <Col xs={10} >
            <div className="d-flex justify-content-between">
                <div>
                    <h4>About Me</h4>
                </div>
                <div>
                    <h4><MdOutlineModeEditOutline /> Edit</h4>
                </div>
            </div>
            <Formik initialValues={{ aboutMe: aboutMe }} validationSchema={EditProfileAboutMe} onSubmit={(e: any) => { submitForm(e) }}>

                {(props: FormikProps<any>) => (
                    <Form>
                        <div className="editor">
                            <CKEditor
                                //@ts-ignore
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    // setAbout(editor.getData());
                                    //@ts-ignore
                                    props.setFieldValue("aboutMe", editor.getData());
                                }}
                                data={props.values?.aboutMe}
                            />
                        </div>
                        <div className="text-danger">
                            <>{props?.errors?.aboutMe}</>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                            <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                        </div>
                    </Form>
                )}

            </Formik>
        </Col>
    </Row>
}
export default AboutMe;