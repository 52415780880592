import { Formik, FormikProps } from "formik"
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap"
import { companySocialMediaValidationSchema } from "../../../Forms/CreateJobPost/CreateJobPost"
interface ISocialMediaProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    jobPost: object;
    setJobPost: React.Dispatch<React.SetStateAction<object>>;
}
const SocialMedia = ({ current, setCurrent, jobPost, setJobPost }: ISocialMediaProps) => {
    //@ts-ignore
    const { twitter, instagram, linkedIn, facebook, companyEmail, invoiceEmail, companyId } = jobPost;
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        setJobPost({ ...jobPost, ...e })
    }
    return <Formik
        initialValues={{
            twitter: twitter,
            instagram: instagram,
            linkedIn: linkedIn,
            facebook: facebook,
            companyEmail: companyEmail,
            invoiceEmail: invoiceEmail,
            companyId: companyId
        }}
        validationSchema={companySocialMediaValidationSchema}
        onSubmit={e => { submitForm(e) }}>
        {(props: FormikProps<any>) => (
            <Row className="mt-3">
                <Col xs={2} className="d-flex justify-content-center">
                    <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                        <div className="m-auto">{current + 1}</div>
                    </div>
                </Col>
                <Col xs={10}>
                    <h4>Company social media presence</h4>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="twitter">
                                    Twitter
                                </Label>
                                <Input onChange={e => {
                                    props.setFieldValue("twitter", e.target.value);
                                }}
                                    invalid={props.errors?.twitter !== undefined}
                                    value={props?.values?.twitter || ''}
                                />
                                <FormFeedback>
                                    <>{props?.errors?.twitter}</>
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="instagram">
                                    Instagram
                                </Label>
                                <Input onChange={e => {
                                    props.setFieldValue("instagram", e.target.value);
                                }}
                                    invalid={props.errors?.instagram !== undefined}
                                    value={props?.values?.instagram || ''} />
                                <FormFeedback>
                                    <>{props?.errors?.instagram}</>
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="linkedIn">
                                    LinkedIn
                                </Label>
                                <Input onChange={e => {
                                    props.setFieldValue("linkedIn", e.target.value);
                                }}
                                    invalid={props.errors?.linkedIn !== undefined}
                                    value={props?.values?.linkedIn || ''}
                                />
                                <FormFeedback>
                                    <>{props?.errors?.linkedIn}</>
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="facebook">
                                    Facebook
                                </Label>
                                <Input onChange={e => {
                                    props.setFieldValue("facebook", e.target.value);
                                }}
                                    value={props?.values?.facebook || ''}
                                    invalid={props.errors?.facebook !== undefined} />
                                <FormFeedback>
                                    <>{props?.errors?.facebook}</>
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="companyEmail">
                            Company Email* (Stays Private, For Invoice  +   Edit Link)
                        </Label>
                        <Input type="email" onChange={e => {
                            props.setFieldValue("companyEmail", e.target.value);
                        }}
                            value={props?.values?.companyEmail || ''}
                            invalid={props.errors?.companyEmail !== undefined} />
                        <FormFeedback>
                            <>{props?.errors?.companyEmail}</>
                        </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label for="invoiceEmail">
                            Invoice Email (Stays Private)
                        </Label>
                        <Input type="email" onChange={e => {
                            props.setFieldValue("invoiceEmail", e.target.value);
                        }}
                            value={props?.values?.invoiceEmail || ''}
                            invalid={props.errors?.invoiceEmail !== undefined} />
                        <FormFeedback>
                            <>{props?.errors?.invoiceEmail}</>
                        </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label for="companyId">
                            Company ID*
                        </Label>
                        <Input onChange={e => {
                            props.setFieldValue("companyId", e.target.value);
                        }}
                            value={props?.values?.companyId || ''}
                            invalid={props.errors?.companyId !== undefined} />
                        <FormFeedback>
                            <>{props?.errors?.companyId}</>
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup check>
                        <Label for="payLater">
                            <Input type="checkbox" aria-label="Id like to pay later" />Id like to pay later
                        </Label>
                        <FormFeedback>

                        </FormFeedback>
                    </FormGroup>
                    <div className="d-flex justify-content-between">
                        <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                        <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                    </div>
                </Col>
            </Row>
        )}
    </Formik>
}
export default SocialMedia;