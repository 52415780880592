import { Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import { Button, Col, Row } from 'reactstrap';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BsClock, BsChat, BsCheck2 } from 'react-icons/bs';
import { RxCalendar } from 'react-icons/rx';
import { useState } from 'react';
import { Mention, MentionsInput } from "react-mentions";

const mentionsInputStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 15,
    height: '100px',
    // fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 60,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      borderRadius: 12,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgb(175, 229, 129)',
      fontSize: 16,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgb(175, 229, 129)',
      '&focused': {
        backgroundColor: 'rgb(175, 229, 129)',
      },
    },
  },
}
const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  min-height: 106px;
  border-radius: 20px;
  max-width: 311px;
   background: ${({ isDragging }) =>
    isDragging ? 'rgba(255, 59, 59, 0.15)' : 'white'};
  background: white;
  
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;

const TaskCard = ({ item, index, setIsOpen }) => {
  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  const [formState, setFormState] = useState({
    username: "",
    comment: ""
  });

  const [comments, setComments] = useState([]);

  const users = [
    {
      id: "isaac",
      display: "Isaac Newton"
    },
    {
      id: "sam",
      display: "Sam Victor"
    },
    {
      id: "emma",
      display: "emmanuel@nobody.com"
    }
  ];

  const submit = (e) => {
    e.preventDefault();
    if (formState.username === "" || formState.comment === "") {
      alert("Please fill in all fields");
      return;
    }

    setComments((comments) => [
      ...comments,
      {
        username: formState.username,
        comment: formState.comment
      }
    ]);
    console.log(comments);
    console.clear();
    setFormState({
      username: "",
      comment: ""
    });
  };

  return (
    <>
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided) => (
          <div
            style={{
              borderRadius: "15px"
            }}
            // onDoubleClick={toggle}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TaskInformation className='p-3 m-auto mt-2' onDoubleClick={setIsOpen}>
              <Row>
                <Col sm={3} className='d-flex'>
                  <img src="../Avatar.png" alt="img" className='m-auto' style={{
                    background: "#D9D9D9",
                    height: 50,
                    width: 50,
                    borderRadius: "50%"
                  }} />
                </Col>
                <Row className='col-sm-9'>
                  <Col sm={9} className='p-0'>
                    <div>Maria Morales</div>
                    <span>EMAE Talent Acquisition Partner(SAP)</span>
                  </Col>
                  <Col sm={3} className='p-0'>
                    <FiEdit2 className='actions' />
                    &nbsp;
                    <RiDeleteBin6Line className='actions' />
                  </Col>
                </Row>
              </Row>
              <div className="secondary-details">
                <p>
                  <span>
                    <BsClock />  {new Date(item.Due_Date).toLocaleDateString('en-us', {
                      month: 'short',
                      day: '2-digit',
                    })}
                  </span>

                </p>
                <p>11 <BsChat /></p>
              </div>
              <Row>
                <Col xs={6}>
                  <RxCalendar fontSize={25} />
                  Interview 08.02  16:00
                </Col>
                <Col xs={6}>
                  <BsCheck2 />
                  Accepted
                </Col>
              </Row>
              <form className='w-100 mt-3' onSubmit={submit}>
                <MentionsInput
                  className='w-100'
                  placeholder="Add Comment. Use '@' for mention and '&' for emojis"
                  value={formState.comment}
                  onChange={(e) => {
                    setFormState({ ...formState, comment: e.target.value })
                    // Save the comment and mentioned usernames to the backend or data structure
                    // saveComment(comment, mentionedUsernames);
                  }}
                  style={mentionsInputStyle}
                  a11ySuggestionsListLabel={"Suggested mentions"}
                >
                  <Mention data={users} style={{ background: 'rgb(175, 229, 129)' }} />
                </MentionsInput>
                <Button color='primary' className='w-100 mt-3'>Add comment</Button>
              </form>
            </TaskInformation>
          </div>
        )}
      </Draggable >
      {/* <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Modal title</ModalHeader>
        <ModalBody>
          <Row>
            <Col className='d-flex'>
              <img src="avatar.png" alt="img" className='m-auto' style={{
                background: "#D9D9D9",
                height: 50,
                width: 50,
                borderRadius: "50%"
              }} />
            </Col>
            <Col>
              <div>Maria Morales</div>
              <span>EMAE Talent Acquisition Partner(SAP)</span>
            </Col>
            <Col>
              <FiEdit2 className='actions' />
              &nbsp;
              <RiDeleteBin6Line className='actions' />
            </Col>

          </Row>
          <div className="secondary-details">
            <p>
              <span>
                <BsClock />  {new Date(item.Due_Date).toLocaleDateString('en-us', {
                  month: 'short',
                  day: '2-digit',
                })}
              </span>

            </p>
            <p>11 <BsChat /></p>
          </div>
          <Row>
            <Col xs={6}>
              <RxCalendar fontSize={25} />
              Interview 08.02  16:00
            </Col>
            <Col xs={6}>
              <BsCheck2 />
              Accepted
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Ok
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}
    </>
  );
};

export default TaskCard;

// <span className="priority">
// {item.Priority === 'High' ? (<RedArrow />) : item.Priority === 'Medium' ? (<YellowArrow />) : (<BlueArrow />)}
// </span>
// <div><CustomAvatar name={item.Assignee} isTable={false} size={16} /></div>
