import { createRef, useState } from 'react';
import { Form, Formik, FormikProps } from "formik";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { EditCVValidationSchema } from "../../../Forms/EditProfile/EditProfile";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { HiOutlineFolderOpen } from 'react-icons/hi2';
interface IEditProfileProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    profile: Object;
    setProfile: React.Dispatch<React.SetStateAction<Object>>;
    filesObject: Object[];
    setFilesObject: React.Dispatch<React.SetStateAction<Object[]>>;
}
const UploadCV = ({ current, setCurrent, profile, setProfile, filesObject, setFilesObject }: IEditProfileProps) => {
    //@ts-ignore
    const { CV, summary } = profile;
    const fileInputRef = createRef();
    const [files, setFiles] = useState<Object[]>(filesObject);
    const submitForm = (e: any) => {
        setProfile({ ...profile, ...e });
        setCurrent(current => current + 1);
    }

    const fileChange = (event: any) => {
        event.preventDefault();
        setFiles([...event.target.files]);
        setFilesObject([...event.target.files]);
    };
    const objectToUrl = (img: any) => {
        const localImageUrl = URL.createObjectURL(img);
        return localImageUrl;
    }
    return <Row>
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">7</div>
            </div>
        </Col>
        <Col xs={10} >
            <div className="d-flex justify-content-between">
                <div>
                    <h4>Upload CV</h4>
                </div>
                <div>
                    <h4><MdOutlineModeEditOutline /> Edit</h4>
                </div>
            </div>
            <Formik initialValues={{ CV: CV, summary: summary }}
                validationSchema={EditCVValidationSchema} onSubmit={e => { submitForm(e) }} >
                {(props: FormikProps<any>) => (
                    <Form>
                        <Row>
                            <p>Upload your CV to showcase your relevant work experience, educational background, obtained certificates, and other pertinent information.</p>
                            <FormGroup>
                                <Label for="CV">
                                    Company Logo (.JPG OR .PNG, Square or Round)
                                </Label>
                                {/* <FileUpload /> */}
                                <div>
                                    <Card onClick={() =>
                                        //@ts-ignore
                                        fileInputRef.current.click()
                                    }>
                                        <CardBody className='p-5 d-flex justify-content-center'>
                                            {files.length > 0 ? files.map((currFiles: any, index: number) => {
                                                return <img key={index} src={objectToUrl(currFiles)} width="100" alt="logo" />;
                                            }) : <h2><HiOutlineFolderOpen />Upload</h2>}
                                        </CardBody>
                                    </Card>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        // name='companyLogo'
                                        //@ts-ignore
                                        ref={fileInputRef}
                                        onChange={event => {
                                            fileChange(event);
                                            props.setFieldValue("CV", event.target.files?.[0])
                                        }}
                                        hidden
                                    />
                                    <div className="text-danger">
                                        <>{props?.errors?.CV}</>
                                    </div>
                                </div>
                            </FormGroup>
                            {/* <div className="cv">
                                <h6>Drag and drop your files here.</h6>
                            </div> */}
                        </Row>
                        <div className="editor mt-5">
                            <Label for="summary">
                                Summary (optional)
                            </Label>
                            <CKEditor
                                //@ts-ignore
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    // setAbout(editor.getData());
                                    //@ts-ignore
                                    props.setFieldValue("summary", editor.getData());
                                }}
                                data={props.values?.summary}
                            />
                        </div>
                        <div className="text-danger">
                            <>{props?.errors?.summary}</>
                        </div>
                        <div>
                            Fields marked by * are mantadory
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                            <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Col>
    </Row>
}
export default UploadCV;