import { Button, Col, FormFeedback, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { RiUser3Fill } from "react-icons/ri";
import {
  BsRocketTakeoffFill,
  // BsApple,
  BsGoogle,
  BsLinkedin,
} from "react-icons/bs";
// import { FaFacebook } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./RegisterProfile.css";
import { Formik, Form, FormikProps } from "formik";
import { RegisterProfileValidationSchema } from "../../Forms/RegisterProfile/RegisterProfile";
import { AppDispatch, RootState } from "../../store";
import { useDispatch } from "react-redux";
import { registeUser } from "../../store/Slice/User/UserThunk";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { setError, setSuccessMessage } from "../../store/Slice/User/User";
import { toast } from "react-toastify";

// import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
const RegisterProfile = () => {
  const navigate = useNavigate();
  // const [isVisible, setIsVisible] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const { error, isLoading, success, successMsg, darkMode } = useSelector(
    (state: RootState) => state.user
  );
  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    password: "",
  };

  const onSubmit = (e: any) => {
    e.account_type_id = '1';
    dispatch(registeUser(e));
  };
  useEffect(() => {
    //@ts-ignore
    if (error?.msg) {
      //@ts-ignore
      toast.error(error.msg);
      dispatch(setError(null))
    }
  }, [error, dispatch]);
  if (success && error === null) {
    //@ts-ignore
    toast.success(successMsg.message);
    setTimeout(() => {
      dispatch(setSuccessMessage())
      navigate("/login");
    }, 2000);
    // return <Navigate to="/login" />
  }
  return (
    <>
      <Row className="justify-content-center">

        <Row className="justify-content-center">
          <Col xs={8} className={'card ' + (darkMode ? 'dark-mode ' : ' ')}>
            <h1 className="text-center mt-5">CREATE A PROFILE ON TEQHIRE</h1>
            <Row className="m-3 justify-content-center">
              <Col xs={8}>
                <Row>
                  <Col xs={6}>
                    <RiUser3Fill />
                    Already have an account?{" "}
                    <Link to={"/login"} className="top-options">
                      Login
                    </Link>
                  </Col>
                  <Col xs={6}>
                    <BsRocketTakeoffFill />{" "}
                    <Link to={"/recruiter-login"} className="top-options">
                      Im looking for an employee{" "}
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center m-3">
              Create a profile via
            </Row>

            <Row className="m-4">
              {/* <Col>
                <Button color="primary" className="primary">
                  {" "}
                  <FaFacebook /> Facebook
                </Button>
              </Col> */}
              <Col sm={6}>
                <Button className="w-100">
                  <BsGoogle />
                  Google
                </Button>
              </Col>
              <Col sm={6}>
                <Button color="primary" className="primary w-100">
                  <BsLinkedin /> in LinkedIn
                </Button>
              </Col>
              {/* <Col>
                <Button>
                  <BsApple /> in Apple
                </Button>
              </Col> */}
            </Row>
            <Row className="m-3">
              <p className="text-center">or</p>
            </Row>

            <Row className="p-5">
              <Formik
                initialValues={initialValues}
                validationSchema={RegisterProfileValidationSchema}
                onSubmit={e => onSubmit(e)}
              >
                {(props: FormikProps<any>) => (
                  <Form>
                    <FormGroup>
                      <Label for="fname">First Name *</Label>
                      <Input
                        invalid={props?.errors?.fname !== undefined}
                        onChange={(e: any) =>
                          props.setFieldValue("fname", e.target.value)
                        }
                      />
                      <FormFeedback>
                        <>{props?.errors?.fname}</>
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="lname">Last Name *</Label>
                      <Input
                        invalid={props?.errors?.lname !== undefined}
                        onChange={(e: any) =>
                          props.setFieldValue("lname", e.target.value)
                        }
                      />
                      <FormFeedback>
                        <>{props?.errors?.lname}</>
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="email">Email *</Label>
                      <Input
                        invalid={props?.errors?.email !== undefined}
                        onChange={(e: any) =>
                          props.setFieldValue("email", e.target.value)
                        }
                      />
                      <FormFeedback>
                        <>{props.errors.email}</>
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">
                        Password (at least 8 characters) *
                      </Label>
                      <Input
                        type="password"
                        invalid={props?.errors?.password !== undefined}
                        onChange={(e: any) =>
                          props.setFieldValue("password", e.target.value)
                        }
                      />

                      <FormFeedback>
                        <>{props?.errors?.password}</>
                      </FormFeedback>
                    </FormGroup>
                    <Col>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" />I want StapJobs to help me
                          fins a better job.Of course according to our privacy
                          rules.
                        </Label>
                      </FormGroup>
                    </Col>
                    <Row className="mt-5">
                      <Col className="d-flex justify-content-center">
                        <Button color="primary" type="submit">
                          {isLoading ?
                            <Spinner>
                              Loading...
                            </Spinner> :
                            <>Register</>}
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <p className="text-center">
                        Registration in free and you can cancel your account at
                        any time
                      </p>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  );
};
export default RegisterProfile;
