import { Form, Formik, FormikProps } from "formik";
import Select, { components } from 'react-select';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Badge, Button, Col, FormGroup, Label, Row } from "reactstrap";
import { EditSkillsValidaitonSchema } from "../../../Forms/EditProfile/EditProfile";
import { ProgrammingLang, ProgrammingLangIcon } from "../../CandidateProfile/Language";
interface IEditProfileProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    profile: Object;
    setProfile: React.Dispatch<React.SetStateAction<Object>>;
}
const languages = ["PHP", "HTML", "Graphic Design", "CSS", "HR Consulting",
    "Project Management", "SQL", "Graphics Design", "UX", "SEO", "Customer Support",
    "Web Design", "Account Management", "Public Relation", "Online Marketing", "Leadership",
    "React", "Content Marketing", "JavaScript", "TypeScript", "IOS Desgin", "Android app Design",
    "Linux"
]
const { SingleValue, Option, MultiValueContainer } = components;
const MySkills = ({ current, setCurrent, profile, setProfile }: IEditProfileProps) => {
    //@ts-ignore
    const { skills, additionalSkills } = profile;
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        setProfile({ ...profile, ...e })
    }
    const IconSingleValue = (props: any) => (
        <SingleValue {...props}>
            <ProgrammingLang programmingLanguage={props.data.image} />
            {" "}{props.data.label}
        </SingleValue>
    );

    const IconOption = (props: any) => (
        <Option {...props}>
            <ProgrammingLang programmingLanguage={props.data.image} />
            {" "} {props.data.label}
        </Option>
    );
    const ISelectContainer = (props: any) => {
        return <MultiValueContainer {...props}>
            <span className="badge bg-light text-dark" >
                <ProgrammingLangIcon programmingLanguage={props.data.image} />
                {" "}
                {props.data.label}
            </span>
        </MultiValueContainer>
    }
    return <Row>
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">{current + 1}</div>
            </div>
        </Col>
        <Col xs={10} >
            <div className="d-flex justify-content-between">
                <div>
                    <h4>My skills</h4>
                </div>
                <div>
                    <h4><MdOutlineModeEditOutline /> Edit</h4>
                </div>
            </div>
            <Row>
                <p>Choose a maximum 5 tags of your entire skills, and fill in each field.</p>
                {languages.map((language: any, index: number) => {
                    return <Badge key={index} className="m-2 p-2 language-badge">
                        {language}
                    </Badge>
                })}
            </Row>
            <Formik initialValues={{ skills: skills, additionalSkills: additionalSkills }}
                validationSchema={EditSkillsValidaitonSchema} onSubmit={e => { submitForm(e) }} >
                {(props: FormikProps<any>) => (
                    <Form>
                        <FormGroup>
                            <Label for="skills">
                                Required Skills:
                            </Label>
                            <Select
                                styles={{
                                    option: (defaultStyles, state) => ({
                                        ...defaultStyles,
                                        backgroundColor: (state.isFocused || state.isSelected) ? "#AFE581" : "#fff"
                                    }),
                                    control: (defaultStyles) => ({
                                        ...defaultStyles,
                                        borderColor: props.errors?.skills ? "#dc3545" : "#ced4da",
                                    }),
                                    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
                                }}
                                name="skills"
                                closeMenuOnSelect={false}
                                value={props?.values?.skills}
                                onChange={(newValue) => {
                                    props.setFieldValue("skills", newValue.length === 0 ? null : newValue);
                                }}
                                placeholder="Start typing e.g. PHP, Online marketing,Project Management etc."
                                required
                                isSearchable
                                isMulti
                                onBlur={() => {
                                    props?.setFieldTouched("skills", true)
                                }}
                                options={[
                                    { value: "react", label: "React", image: "react" },
                                    { value: "javascript", label: "javascript", image: "javascript" },
                                    { value: "typescript", label: "TypeScript", image: "typescript" },
                                    { value: "nodejs", label: "Node.js", image: "nodejs" },
                                    { value: "amazonwebservices", label: "Aamazon Web Services", image: "amazonwebservices" },
                                    { value: "mongodb", label: "MongoDB", image: "mongodb" },
                                    { value: "nextjs", label: "Next.js", image: "nextjs" },
                                    { value: "python", label: "Python", image: "python" },
                                    { value: "aftereffects", label: "After Effects", image: "aftereffects" },
                                    { value: "android", label: "Android", image: "android" },
                                    { value: "angularjs", label: "Angular", image: "angularjs" },
                                    { value: "java", label: "Java", image: "java" },
                                    { value: "aarch64", label: "Aarch64", image: "aarch64" },
                                    { value: "apache", label: "Apache", image: "apache" },
                                    { value: "apple", label: "Apple", image: "apple" },
                                    { value: "bootstrap", label: "Bootstrap", image: "bootstrap" },
                                    { value: "c", label: "c", image: "c" },
                                    { value: "cplusplus", label: "c++", image: "cplusplus" },
                                ]}
                                components={{
                                    Option: IconOption,
                                    SingleValue: IconSingleValue,
                                    MultiValueContainer: ISelectContainer
                                }}
                            />
                            <div className="text-danger">
                                <>{props?.errors?.skills}</>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="additionalSkills">
                                Additional Skills:
                            </Label>
                            <Select
                                placeholder="Start typing e.g. PHP, Online marketing,Project Management etc."
                                styles={{
                                    option: (defaultStyles, state) => ({
                                        ...defaultStyles,
                                        backgroundColor: (state.isFocused || state.isSelected) ? "#AFE581" : "#fff"
                                    }),
                                    control: (defaultStyles) => ({
                                        ...defaultStyles,
                                        borderColor: props.errors?.additionalSkills ? "#dc3545" : "#ced4da",
                                    }),
                                    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
                                }}
                                name="additionalSkills"
                                closeMenuOnSelect={false}
                                onChange={(newValue) => {
                                    props.setFieldValue("additionalSkills", newValue.length === 0 ? null : newValue);
                                }}
                                value={props?.values?.additionalSkills}
                                required
                                isSearchable
                                isMulti
                                onBlur={() => {
                                    props?.setFieldTouched("additionalSkills", true)
                                }}
                                options={[
                                    { value: "react", label: "React", image: "react" },
                                    { value: "javascript", label: "javascript", image: "javascript" },
                                    { value: "typescript", label: "TypeScript", image: "typescript" },
                                    { value: "nodejs", label: "Node.js", image: "nodejs" },
                                    { value: "amazonwebservices", label: "Aamazon Web Services", image: "amazonwebservices" },
                                    { value: "mongodb", label: "MongoDB", image: "mongodb" },
                                    { value: "nextjs", label: "Next.js", image: "nextjs" },
                                    { value: "python", label: "Python", image: "python" },
                                    { value: "aftereffects", label: "After Effects", image: "aftereffects" },
                                    { value: "android", label: "Android", image: "android" },
                                    { value: "angularjs", label: "Angular", image: "angularjs" },
                                    { value: "java", label: "Java", image: "java" },
                                    { value: "aarch64", label: "Aarch64", image: "aarch64" },
                                    { value: "apache", label: "Apache", image: "apache" },
                                    { value: "apple", label: "Apple", image: "apple" },
                                    { value: "bootstrap", label: "Bootstrap", image: "bootstrap" },
                                    { value: "c", label: "c", image: "c" },
                                    { value: "cplusplus", label: "c++", image: "cplusplus" },
                                ]}
                                components={{
                                    Option: IconOption,
                                    SingleValue: IconSingleValue,
                                    MultiValueContainer: ISelectContainer
                                }}
                            />
                            <div className="text-danger">
                                <>{props?.errors?.additionalSkills}</>
                            </div>
                        </FormGroup>
                        <div className="d-flex justify-content-between mt-4">
                            <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                            <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Col>
    </Row>;
}
export default MySkills;