import { AiFillCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useState } from 'react';
import { Button, Col, Row } from "reactstrap";
import { AppDispatch, RootState } from "../../store";
import { lazy, Suspense } from 'react';
import JobCardSkeleton from "../Common/JobCard/JobCardSkeleton";
import { useDispatch } from "react-redux";
import { useEffect } from 'react';
import { getJobs, jobFilter } from "../../store/Slice/Jobs/JobsThunk";
import { getJobSalary } from "../../store/Slice/Jobs/Jobs";
import SearchDropDown from "../Common/SearchDropDown/SearchDropDown";
import LocationFilter from "../Common/LocatioFilter/LocationFilter";
import SalaryFilter from "../Common/SalaryFilter/SalaryFilter";
import BenefitsMenu from "../Common/BenifitsMenu/BenifitsMenu";
import { BsChevronDown } from "react-icons/bs";
const JobCard = lazy(() => import("../../Components/Common/JobCard/JobCard"));
interface IFilterCriteria {
    searchValue: string[];
    salaryFilter: number | null;
    locationFilter: string[];
    benifitFilter: string[];
}
const Job = () => {
    const job = useSelector(
        (state: RootState) => state.job
    );
    const dispatch = useDispatch<AppDispatch>();
    const [loadMoreCount, setLoadMoreCount] = useState(0);
    const loadCards = Array(loadMoreCount).fill(1);
    useEffect(() => {
        dispatch(getJobs());
    }, [dispatch]);

    const [visible, setVisible] = useState(false);
    const [salaryRange, setSalaryRange] = useState<number | null>(null);
    const [showSalaryFilter, setShowSalaryFilter] = useState(false);
    const [showLocationFilter, setShowLocationFilter] = useState(false);
    const [location, setLocation] = useState('');
    const [benefitsMenu, setBenifitsMenu] = useState(false);
    const [benfitSearch, setBenifitSearch] = useState('');
    const [searchValue, setSearchValue] = useState('');

    const [filterCriteria, setFilterCriteria] = useState<IFilterCriteria>({
        searchValue: [],
        salaryFilter: null,
        locationFilter: [],
        benifitFilter: []
    });
    useEffect(() => {
        setFilterCriteria((state) => {
            const { ...prevState } = state;
            return {
                ...prevState,
                searchValue: searchValue !== '' ? [
                    ...prevState.searchValue,
                    searchValue
                ] : [...prevState.searchValue],
                salaryFilter: salaryRange,
                locationFilter: location !== '' ? [
                    ...prevState.locationFilter,
                    location
                ] : [...prevState.locationFilter],
                benifitFilter: benfitSearch !== '' ? [
                    ...prevState.benifitFilter,
                    benfitSearch
                ] : [...prevState.benifitFilter]
            }
        });
        setSearchValue('');
        setLocation('');
        setBenifitSearch('');
    }, [searchValue, salaryRange, location, benfitSearch])

    useEffect(() => {
        if (filterCriteria.searchValue.length > 0) {
            dispatch(jobFilter({ title: filterCriteria.searchValue[0] }))
        }
        else {
            console.log(filterCriteria.salaryFilter !== null && filterCriteria.salaryFilter > 0)
            if (filterCriteria.salaryFilter !== null && filterCriteria.salaryFilter > 0) {
                dispatch(getJobSalary({ salary: Number(filterCriteria.salaryFilter) }))
            }
            else {
                dispatch(getJobs());
            }

        }
    }, [filterCriteria.searchValue, dispatch, filterCriteria.salaryFilter])
    // console.log(searchValue)
    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchValue(e.target.value);
    //     if (!visible) {
    //         setVisible(true);
    //     }
    // };
    return <Row className="justify-content-center">
        <h1 className="text-center">Jobs</h1>

        <div className="d-flex mt-3 justify-content-between">
            <div className="d-flex">
                <div className="m-1">
                    <Button className="searchButton" id="searchSection" onClick={() => setVisible(true)}>🔍 Search <BsChevronDown /></Button>
                    <SearchDropDown searchValue={searchValue} setSearchValue={setSearchValue} setVisible={setVisible} visible={visible} />
                </div>
                <div className="m-1">
                    <Button className="searchButton" onClick={() => setShowLocationFilter(true)}>🌏 Location <BsChevronDown /></Button>
                    <LocationFilter searchValue={location} setSearchValue={setLocation} setVisible={setShowLocationFilter} visible={showLocationFilter} />

                </div>
                <div className="m-1">
                    <Button className="searchButton" onClick={() => setShowSalaryFilter(true)}>💵 Salary <BsChevronDown /></Button>
                    <SalaryFilter visible={showSalaryFilter} setVisible={setShowSalaryFilter} salaryRange={salaryRange} setSalaryRange={setSalaryRange} />
                </div>
                <div className="m-1">
                    <Button className="searchButton" onClick={() => setBenifitsMenu(true)}>🎪 Benefits <BsChevronDown /></Button>
                    <BenefitsMenu visible={benefitsMenu} setVisible={setBenifitsMenu} searchValue={benfitSearch} setSearchValue={setBenifitSearch} />
                </div>
            </div>
            <div >
                <div className="m-1">
                    <select defaultValue="⚙️ Sort by" className="btn btn-secondary">
                        <option value="⚙️ Sort by">⚙️ Sort by</option>
                        <option value="date">🆕 Latest jobs</option>
                        <option value="salary">💵 Highest paid</option>
                        <option value="views">👀 Most viewed</option>
                        <option value="applied">✅ Most applied</option>
                        <option value="hot">🔥 Hottest</option>
                        <option value="benefits">🎪 Most benefits</option>
                    </select>
                </div>
            </div>
        </div>
        <div className=" mt-3 filter-option-tags">
            {filterCriteria.searchValue.length > 0 ?
                filterCriteria.searchValue.map((search: any, index: number) => {
                    return <Button className="w-auto" key={index}>{search} <AiFillCloseCircle
                        onClick={() => {
                            setFilterCriteria(state => {
                                return {
                                    ...state,
                                    searchValue: filterCriteria.searchValue.filter((val: any, i: number) => i !== index)
                                }
                            })
                            setSearchValue('')
                        }} /></Button>
                }) : null}
            {filterCriteria.salaryFilter !== null ? <Button>🤑 &gt;
                ${salaryRange !== null ? salaryRange * 10 : 0}k/yr <AiFillCloseCircle onClick={() => {
                    setFilterCriteria(state => {
                        return {
                            ...state,
                            salaryFilter: null
                        }
                    })
                    setSalaryRange(null)
                }} />
            </Button> : null}
            {filterCriteria.locationFilter.length > 0 ?
                filterCriteria.locationFilter.map((location: any, index: number) => {
                    return <Button className="w-auto" key={index}>{location} <AiFillCloseCircle onClick={() => {
                        setFilterCriteria((state: any) => {
                            return {
                                ...state,
                                locationFilter: filterCriteria.locationFilter.filter((val, i) => i !== index)
                            }
                        });
                        setLocation('');
                    }} /></Button>
                })
                : null}
            {filterCriteria.benifitFilter.length > 0 ?
                filterCriteria.benifitFilter.map((benifit: string, index: number) => {
                    return <Button className="w-auto" key={index}>{benifit} <AiFillCloseCircle onClick={() => {
                        setFilterCriteria((state: any) => {
                            return {
                                ...state,
                                benifitFilter: filterCriteria.benifitFilter.filter((val, i) => i !== index)
                            }
                        });
                        setBenifitSearch('');
                    }} /></Button>
                })
                : null}
        </div>
        <div className="mt-4">
            {job.jobs.map((job: any, index: number) => {
                return <Suspense key={index} fallback={<JobCardSkeleton />}> <JobCard id={job.id} key={index}
                    applications={job.applications} image={job.image}
                    languages={job?.skills?.list} location={job.location}
                    salary={job.salary} title={job.title} type={job.type}
                    currency={job.currency}
                    createdAt={job.createdAt} details={job.job_details} />
                </Suspense>
            })}
            {loadCards.map((_, index: number) => {
                return <JobCardSkeleton key={`skeleton-` + index} />
            })}
            {job.jobs.length === 0 ? <>
                <div className="text-center">
                    <h1 style={{ fontSize: 100 }}>😔</h1>
                    <div>
                        <h4 style={{ fontSize: 50 }}>Sorry!! We can't found any records !!</h4>
                    </div>
                </div>
            </> : null}
        </div>
        <Row className="justify-content-center mt-3">
            <Col xs={3} className="text-center">
                <Button onClick={() => setLoadMoreCount(3)}>Load more</Button>
            </Col>
        </Row>
    </Row>
}
export default Job;