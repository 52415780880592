import * as Yup from "yup";
export const RegisterProfileValidationSchema= Yup.object({
    // name: Yup.string().required("name is required."),
    fname : Yup.string().required("First name is required."),
    lname : Yup.string().required("Last name is required."),
    email: Yup.string()
      .email("invalid format of email")
      .required("email field is required"),
    password: Yup.string().required("password is required"),
  });

  export const LoginValidationSchema= Yup.object({
    email: Yup.string()
      .email("invalid format of email")
      .required("email field is required"),
    password: Yup.string().required("password is required"),
  });

