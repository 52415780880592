import { useState } from 'react';
import { Formik, FormikProps, Form } from "formik";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { EditPortfolioValidationSchema } from "../../../Forms/EditProfile/EditProfile";
interface IEditProfileProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    profile: Object;
    setProfile: React.Dispatch<React.SetStateAction<Object>>;
}
const Porfolio = ({ current, setCurrent, profile, setProfile }: IEditProfileProps) => {
    //@ts-ignore
    const { portfolio } = profile;
    const [link, setLink] = useState<Object[]>(new Array(1).fill(''));
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        setProfile({ ...profile, ...e });
    }
    const addNewLink = async () => {
        setLink([...link, '']);
    };
    return <Row>
        <Col xs={2} className="d-flex justify-content-center">
            <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                <div className="m-auto">{current + 1}</div>
            </div>
        </Col>
        <Col xs={10} >
            <div className="d-flex justify-content-between">
                <div>
                    <h4>Porfolio</h4>
                </div>
                <div>
                    <h4><MdOutlineModeEditOutline /> Edit</h4>
                </div>
            </div>
            <Formik initialValues={{ portfolio: portfolio }}
                validationSchema={EditPortfolioValidationSchema}
                onSubmit={e => { submitForm(e) }} >
                {(props: FormikProps<any>) => (
                    <Form>
                        {link.map((lnk, index) => {
                            let fieldName = `portfolio[${index}]`
                            return <FormGroup key={`${fieldName}`}>
                                <Label for={`${fieldName}`}>
                                    Add link
                                </Label>
                                <Input type="text" onChange={(e) => {
                                    props.setFieldValue(`${fieldName}`, e.target.value);
                                }}
                                    //@ts-ignore
                                    invalid={props.errors?.portfolio?.[index] !== undefined}
                                    value={props?.values?.portfolio?.[index] || ''}
                                />
                                <FormFeedback>
                                    <>{
                                        //@ts-ignore
                                        props.errors?.portfolio?.[index]}</>
                                </FormFeedback>
                            </FormGroup>
                        })}

                        <div>
                            <Button onClick={addNewLink}>+ Add link</Button>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                            <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                        </div>
                    </Form>
                )}
            </Formik>

        </Col>
    </Row >
}
export default Porfolio;