import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { APPLICANTS, BASE_URL } from "../../constants";
import { setLoginRequired } from "../User/User";

export const getApplicants = createAsyncThunk<any, any, { state: RootState }>('applicant/getApplicants',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.userToken
            if (!token) {
                thunkAPI.dispatch(setLoginRequired({}));
                return thunkAPI.rejectWithValue({ loginRequired: true });
            }
            const applicants = await fetch(BASE_URL + APPLICANTS);
            if (applicants.status === 200) {
                return await applicants.json()
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    });

export const getApplicantsDetails = createAsyncThunk<any, { applicantId: string }, { state: RootState }>('applicant/getApplicantsDetails',
    async ({ applicantId }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.userToken
            if (!token) {
                thunkAPI.dispatch(setLoginRequired({}));
                return thunkAPI.rejectWithValue({ loginRequired: true });
            }
            const applicants = await fetch(BASE_URL + APPLICANTS + applicantId);
            if (applicants.status === 200) {
                return await applicants.json()
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    });


export const createApplicants = createAsyncThunk<any, any, { state: RootState }>('applicant/createApplicants', async (applicantData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.userToken
        if (!token) {
            thunkAPI.dispatch(setLoginRequired({}));
            return thunkAPI.rejectWithValue({ loginRequired: true });
        }
        const applicants = await fetch(BASE_URL + APPLICANTS, {
            body: JSON.stringify(applicantData),
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
            },
            method: "POST"
        });
        if (applicants.status === 200) {
            return await applicants.json()
        }
    } catch (err) {
        return thunkAPI.rejectWithValue(err);
    }
});

export const updateApplicants = createAsyncThunk<any, any, { state: RootState }>('applicant/updateApplicants', async (applicantData, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.userToken
        if (!token) {
            thunkAPI.dispatch(setLoginRequired({}));
            return thunkAPI.rejectWithValue({ loginRequired: true });
        }
        const applicants = await fetch(BASE_URL + APPLICANTS, {
            body: JSON.stringify(applicantData),
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json",
            },
            method: "PUT"
        });
        if (applicants.status === 200) {
            return await applicants.json()
        }
    } catch (err) {
        return thunkAPI.rejectWithValue(err);
    }
});
