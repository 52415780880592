import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { CreateAJobPost } from "../../../Forms/CreateJobPost/CreateJobPost";
import { Form, Formik, FormikProps } from "formik";
interface ICreateJobPostProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    jobPost: object;
    setJobPost: React.Dispatch<React.SetStateAction<object>>;
}
const CreateJobPost = ({ current, setCurrent, jobPost, setJobPost }: ICreateJobPostProps) => {
    
    //@ts-ignore
    const { title, position, jobType, primaryTag, tagsOrKeyWord, location, currency, salary, highlights } = jobPost;
    const submitForm = (e: any) => {
        setJobPost({ ...jobPost, ...e });
        setCurrent(current => current + 1);
    }
    return <Formik
        initialValues={{
            title: title,
            position: position,
            jobType: jobType,
            primaryTag: primaryTag,
            tagsOrKeyWord: tagsOrKeyWord,
            location: location,
            currency: currency,
            salary: salary,
            highlights: highlights
        }}
        validationSchema={CreateAJobPost}
        onSubmit={e => { submitForm(e); }}
    >
        {(props: FormikProps<any>) => (
            <Form>
                <Row className="mt-3">
                    <Col xs={2} className="d-flex justify-content-center">
                        <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                            <div className="m-auto">{current + 1}</div>
                        </div>
                    </Col>
                    <Col xs={10}>
                        <h4>Create a job post</h4>

                        <FormGroup>
                            <Label for="title">
                                Title
                            </Label>
                            <Input onChange={e => {
                                props.setFieldValue("title", e.target.value);
                            }}
                                value={props.values?.title || ''}
                                invalid={props.errors?.title !== undefined} />
                            <FormFeedback>
                                <>{props?.errors?.title}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="position">
                                Position
                            </Label>
                            <Input onChange={e => {
                                props.setFieldValue("position", e.target.value);
                            }}
                                value={props.values?.position || ''}
                                invalid={props.errors?.position !== undefined} />
                            <FormFeedback>
                                <>{props?.errors?.position}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="jobType">
                                Please select your job type
                            </Label>
                            <Input type="select" onChange={e => {
                                props.setFieldValue("jobType", e.target.value);
                            }}
                                value={props.values?.jobType || ''}
                                invalid={props.errors?.jobType !== undefined} >
                                <option>--select--</option>
                                <option>Full time</option>
                                <option>part time</option>
                            </Input>
                            <FormFeedback>
                                <>{props?.errors?.jobType}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="primaryTag">
                                Primary Tag
                            </Label>
                            <Input onChange={e => {
                                props.setFieldValue("primaryTag", e.target.value);
                            }}
                                value={props.values?.primaryTag || ''}
                                invalid={props.errors?.primaryTag !== undefined} />
                            <FormFeedback>
                                <>{props?.errors?.primaryTag}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="tagsOrKeyWord">
                                Tags, Keywords*
                            </Label>
                            <Input onChange={e => {
                                props.setFieldValue("tagsOrKeyWord", e.target.value);
                            }}
                                value={props.values?.tagsOrKeyWord || ''}
                                invalid={props.errors?.tagsOrKeyWord !== undefined} />
                            <FormFeedback>
                                <>{props?.errors?.tagsOrKeyWord}</>
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="location">
                                Location
                            </Label>
                            <Input onChange={e => {
                                props.setFieldValue("location", e.target.value);
                            }}
                                value={props.values?.location || ''}
                                invalid={props.errors?.location !== undefined} />
                            <FormFeedback>
                                <>{props?.errors?.location}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="currency">
                                Currency
                            </Label>
                            <Input type="select" onChange={e => {
                                props.setFieldValue("currency", e.target.value);
                            }}
                                value={props.values?.currency || ''}
                                invalid={props.errors?.currency !== undefined} >
                                <option>-select--</option>
                                <option value="INR">INR</option>
                                <option value={"USD"}>USD</option>
                                <option value={"GBP"}>GBP</option>
                            </Input>
                            <FormFeedback>
                                <>{props?.errors?.currency}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="salary">
                                Salary
                            </Label>
                            <Input onChange={e => {
                                props.setFieldValue("salary", e.target.value);
                            }}
                                value={props.values?.salary || ''}
                                invalid={props.errors?.salary !== undefined} />
                            <FormFeedback>
                                <>{props?.errors?.salary}</>
                            </FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="highlights">
                                Highlight
                            </Label>
                            <Input onChange={e => {
                                props.setFieldValue("highlights", e.target.value);
                            }}
                                value={props.values?.highlights || ''}
                                invalid={props.errors?.highlights !== undefined} />
                            <FormFeedback>
                                <>{props?.errors?.highlights}</>
                            </FormFeedback>
                        </FormGroup>
                        <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                    </Col>
                </Row>
            </Form>
        )}
    </Formik>
}
export default CreateJobPost;