import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom'
import { Button, Collapse, Nav, NavItem, Navbar, NavbarToggler } from 'reactstrap';
import { RootState } from '../../../store';
import { GrMail } from 'react-icons/gr';
import { MdNotifications } from 'react-icons/md';
const MainNavigation = () => {
    const [isOpen, setIsOpen] = useState(false);

    // const toggle = () => setIsOpen(!isOpen);
    const user = useSelector(
        (state: RootState) => state.user
    );
    const darkText = user?.darkMode ? 'dark-mode-text' : '';
    return <Navbar expand="md" dark={user?.darkMode}>
        {/* <NavbarBrand to="/"> */}
        <NavLink to={"/"}><img src='/Logo.png' alt="logo" /></NavLink>
        {/* </NavbarBrand> */}
        <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
        <Collapse isOpen={isOpen} navbar style={{ justifyContent: "space-between", paddingLeft: '5rem' }}>
            <Nav className={"mr-auto " + (user?.darkMode ? 'dark-font' : '')} navbar  >
                <NavItem >
                    <NavLink className={darkText} to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/jobs" className={darkText}>Find Jobs</NavLink>
                </NavItem>
                {user.userData?.account_type_id && user.userData?.account_type_id !== 6 ?
                    <>
                        <NavItem>
                            <NavLink to="/candidates" className={darkText}>Find Candidates</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/company/our-jobs" className={darkText}>Company</NavLink>
                        </NavItem>
                    </> : null}
                {user.userData?.id && user.userData?.account_type_id && user.userData?.account_type_id === 6 ?
                    <NavItem>
                        <NavLink to="/profile/" className={darkText}>My Profile</NavLink>
                    </NavItem> : null
                }
            </Nav>
            {user.userData?.id ?
                <>
                    <Nav className="mr-auto mt d-flex auth-gap" navbar>
                        <NavItem className='m-auto d-flex auth-nav'>
                            <NavLink to="/login"> <GrMail /><span className='badges' style={{ background: "#AFE581" }}>5</span></NavLink>
                            <NavLink to="/notificition"><MdNotifications /><span className='badges' style={{ background: "#AFE581" }}>21</span></NavLink>
                        </NavItem>
                        <Link to={"/profile"}>
                            <div className='d-flex'>
                                <img src="../Avatar.png" alt="profile" />
                                <div className='m-auto'>{
                                    //@ts-ignore
                                    user?.userData?.fname + ' ' + user?.userData?.lname}</div>
                            </div>
                        </Link>
                    </Nav>
                </>
                :
                <Nav className="mr-auto mt d-flex" navbar>
                    <NavItem className='m-auto'>
                        <NavLink to="/login" className={darkText}> Login</NavLink>
                    </NavItem>
                    <Link to={"/create-profile"}> <Button color='primary'>Register</Button></Link>
                </Nav>
            }

        </Collapse>
    </Navbar>
}
export default MainNavigation;