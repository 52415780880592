import { BsFillCheckCircleFill } from "react-icons/bs";
import { Button, Col, Row } from "reactstrap";

const Thanks = () => {
    return <>
        <Row className="justify-content-center">
            <h1 className="text-center"><BsFillCheckCircleFill color="#AFE581" /></h1>
            <Col xs={6} className="mt-5">
                <Row className="d-flex justify-content-center">
                    <h2 className="text-center">Thanks for applying</h2>
                    <p className="text-center">Can we notify you about other opportunities maching your skills?</p>
                </Row>
                <Row className="justify-content-evenly mt-5">
                    <Button color="primary" className="w-25"> Yes, Please</Button>
                    <Button className="w-25">No</Button>
                </Row>
            </Col>
        </Row>
    </>
}
export default Thanks;