import * as Yup from "yup";
export const AccountInfoValidationSchema = Yup.object({
    cin_number: Yup.string().nullable().required("Company Reg No. is required."),
    name: Yup.string().nullable().required("Company Name is required."),
    address: Yup.string().nullable().required("Address is required."),
    contact: Yup.string().nullable().required("Contact No is required.").length(10, "Contact should be 10 characters long."),
    domain: Yup.string().nullable().required("Domain is required.").url("Domain is invalid."),
    accountOwner: Yup.string().nullable().required("Account Owner is required."),
    email: Yup.string().nullable().required("Email is required.").email("Invalid email address."),
    invoiceEmail: Yup.string().required("Email is required").email("Invalid email address."),
    individualName: Yup.string().required("Individual Name is required"),
});