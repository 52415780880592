import { Outlet } from "react-router-dom";
import { Col, Row } from "reactstrap";
import SideMenuCompany from "../Common/SideMenuMain/SideMenuCompany";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { getAccountDetails } from "../../store/Slice/Account/AccountThunk";

const CompanyProfile = () => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getAccountDetails({}));
    }, [dispatch])
    return <Row>
        <Col xl={3} sm={6}>
            <SideMenuCompany />
        </Col>
        <Col xl={9} sm={9} className="p-0">
            <Outlet />
        </Col>
    </Row>
}
export default CompanyProfile;