import * as Yup from "yup";
export const CreateAJobPost = Yup.object({
    title: Yup.string().required('Title is required.'),
    position: Yup.string().required("Position is required."),
    jobType: Yup.string().required("Job type is required."),
    primaryTag: Yup.string().required("Primary tag is required."),
    tagsOrKeyWord: Yup.string().required("Tags, keyword is required."),
    location: Yup.string().required("Locaiton is required."),
    currency: Yup.string().required("Currency is required."),
    salary: Yup.string().required("Salary is required."),
    highlights: Yup.string().required("Highlights is required.")
});
export const jobRolesValidationSchema = Yup.object({
    // roles: Yup.string().required("Roles is required.")
    roles: Yup.array()
        .of(Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
            image: Yup.string().required()
        })).required("Role is required."),
});
export const jobSkillsValidaitonSchema = Yup.object({
    // skills: Yup.string().required("Skills is required."),
    skills: Yup.array()
        .of(Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
            image: Yup.string().required()
        })).required("Skills is required."),
});

export const languagesValidationSchema = Yup.object({
    // language: Yup.string().required("Language is required"),
    language: Yup.array().max(1, "Sorry but you need to select only one language.")
        .of(Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
            image: Yup.string().required()
        })).required("Language is required."),
    proficiency: Yup.string().required("Proficiency is required")
});

export const jobDetailsValidationSchema = Yup.object({
    // companyLogo: Yup.mixed().required("Company logo is required."),
    job_details: Yup.string().required("Job Details is required."),
    howToApply: Yup.string().required("How to apply is required."),
    apply_link: Yup.string().url("Apply URL is invalid.").required("Apply URL is required."),
    apply_email: Yup.string().email("Apply Email is invalid.").required("Apply email is required.")
});

export const companySocialMediaValidationSchema = Yup.object({
    twitter: Yup.string().optional().url("Twitter url is invalid."),
    instagram: Yup.string().optional().url("Instagram url is invalid."),
    linkedIn: Yup.string().optional().url("LinkedIn url is invalid."),
    facebook: Yup.string().optional().url("Facebook url is invalid."),
    companyEmail: Yup.string().required("Company email is required.").email("Company email is invalid."),
    invoiceEmail: Yup.string().optional().email("Invoice email is invalid."),
    companyId: Yup.string().required("Company Id is required")
});

export const JobPostValidationSchema = Yup.object({
    aboutMe: Yup.string().required("About me is required"),
    // email: Yup.string()
    //     .email("invalid format of email")
    //     .required("email field is required"),
    // password: Yup.string().required("password is required"),
});


