import { Col } from "reactstrap";
import { lazy, Suspense } from 'react';
import { CandidateBasicMainLoader, CandidateBasicsLanguangesSkills } from "../CandidateBasicsSkeleton";
const CandidateBasics = lazy(() => import("../CandidateBasics"));
const LanguagesSkills = lazy(() => import("../LanguagesSkills"));
const Profile = () => {
    return <>
        <Col xl={6} sm={6} className="pe-md-5">
            <Suspense fallback={<CandidateBasicMainLoader />}>
                <CandidateBasics />
            </Suspense>
        </Col>
        <Col xl={3}>
            <Suspense fallback={<CandidateBasicsLanguangesSkills />}>
                <LanguagesSkills />
            </Suspense>
        </Col>
    </>
}
export default Profile;