import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoginRequired } from "../User/User";
import { ACCOUNT, BASE_URL } from "../../constants";
import { RootState } from "../..";

export const getAccountDetails = createAsyncThunk<any, any, { state: RootState }>('account/getAccountDetails',
    async (_, thunkAPI) => {
        try {
            const user = thunkAPI.getState().user
            if (!user.userToken) {
                thunkAPI.dispatch(setLoginRequired({}));
                return thunkAPI.rejectWithValue({ loginRequired: true });
            }
            const applicants = await fetch(BASE_URL + ACCOUNT + user.userData.id, {
                headers: {
                    "Authorization": "Bearer " + user.userToken,
                    "Content-Type": "application/json",
                },
            });
            if (applicants.status === 200) {
                return await applicants.json()
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    });

export const getComapnyMembers = createAsyncThunk<any, any, { state: RootState }>('account/getComapnyMembers',
    async (_, thunkAPI) => {
        try {
            const user = thunkAPI.getState().user;
            if (!user.userToken) {
                thunkAPI.dispatch(setLoginRequired({}));
                return thunkAPI.rejectWithValue({ loginRequired: true });
            }
            const account = thunkAPI.getState().account;

            const applicants = await fetch(BASE_URL + ACCOUNT + "?org_id=" + account.accountDetails?.org_id, {
                headers: {
                    "Authorization": "Bearer " + user.userToken,
                    "Content-Type": "application/json",
                },
            });
            if (applicants.status === 200) {
                return await applicants.json()
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    });

export const addCompanyMember = createAsyncThunk<any, any, { state: RootState }>('account/addCompanyMember',
    async (memeberData, thunkAPI) => {
        try {
            const user = thunkAPI.getState().user;
            if (!user.userToken) {
                thunkAPI.dispatch(setLoginRequired({}));
                return thunkAPI.rejectWithValue({ loginRequired: true });
            }
            const account = thunkAPI.getState().account.accountDetails;
            memeberData = {
                ...memeberData,
                org_id: account?.org_id,
                account_type_id: 1,
                org_verified: true,
                verified: true,
            }
            const createAccount = await fetch(BASE_URL + ACCOUNT, {
                headers: {
                    "Authorization": "Bearer " + user.userToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    data: [memeberData]
                }),
                method: "POST"
            });
            if (createAccount.status === 200) {
                const res = await createAccount.json();
                if (!res?.is_valid) {
                    console.log(res?.data?.data?.errors?.[0]?.message, res?.data?.err)
                    return thunkAPI.rejectWithValue({
                        err: res?.data?.errors?.[0]?.message || 'Some thing went wrong'
                    })
                }
                return res;
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    });