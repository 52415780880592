import * as Yup from "yup";
export const AddComapnyMemberValidationSchema = Yup.object({
    fname: Yup.string().required('First name is required.'),
    lname: Yup.string().required('Last name is required.'),
    email: Yup.string().required('email is required.').email("Invalida email address"),
    password: Yup.string().required('Password is required.').min(9, "Password must be min 8 characters long."),
    mobile: Yup.string().required('Mobile no. is required.').length(10, "Mobile no. must be 10 characters long."),
    gender: Yup.string().required('Gender is required.'),
    address: Yup.string().required('Address is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    pin: Yup.string().required('Pincode is required.'),

})