import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { APPLICATION_API, BASE_URL } from "../../constants";

export const getApplications = createAsyncThunk<any, any, { state: RootState }>('application/getApplication',
    async (_, thunkAPI) => {
        try {
            const applications = await fetch(BASE_URL + APPLICATION_API);
            if (applications.status === 200) {
                return await applications.json()
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    });


export const createApplications = createAsyncThunk<any, { application: Object }, { state: RootState }>('application/createApplication',
    async ({ application }, thunkAPI) => {
        try {
            const applications = await fetch(BASE_URL + APPLICATION_API, {
                body: JSON.stringify(application),
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST"
            });
            if (applications.status === 200) {
                return await applications.json()
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    });

export const getApplicationDetails = createAsyncThunk<any, { applicationId: string }, { state: RootState }>('applicatioAPPLICATION_APIetails',
    async ({ applicationId }, thunkAPI) => {
        try {
            const applications = await fetch(BASE_URL + APPLICATION_API + applicationId);
            if (applications.status === 200) {
                return await applications.json();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    })


export const updateApplicationDetails = createAsyncThunk<any, { application: Object }, { state: RootState }>('applicatioAPPLICATION_APIetails',
    async ({ application }, thunkAPI) => {
        try {
            const applications = await fetch(BASE_URL + APPLICATION_API, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(application),
            });
            if (applications.status === 200) {
                return await applications.json();
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    })