import { Formik, FormikProps } from "formik";
import { TfiInfoAlt } from "react-icons/tfi";
import icons from 'devicon/devicon.json';
import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import { jobSkillsValidaitonSchema } from "../../../Forms/CreateJobPost/CreateJobPost";
import { ProgrammingLang, ProgrammingLangIcon } from "../../CandidateProfile/Language";
import { IoIosArrowDown } from "react-icons/io";
import Select, { components } from 'react-select';
const { SingleValue, Option, MultiValueContainer } = components;

interface IChooseSkillsProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    jobPost: object;
    setJobPost: React.Dispatch<React.SetStateAction<object>>;
}
const ChooseSkills = ({ current, setCurrent, jobPost, setJobPost }: IChooseSkillsProps) => {
    //@ts-ignore
    const { skills } = jobPost;
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        console.log(e);
        setJobPost({ ...jobPost, ...e })
    };
    const IconSingleValue = (props: any) => (
        <SingleValue {...props}>
            <ProgrammingLang programmingLanguage={props.data.image} />
            {" "}{props.data.label}
        </SingleValue>
    );

    const IconOption = (props: any) => (
        <Option {...props}>
            <ProgrammingLang programmingLanguage={props.data.image} />
            {" "} {props.data.label}
        </Option>
    );
    const ISelectContainer = (props: any) => {
        return <MultiValueContainer {...props}>
            <span className="badge bg-light text-dark" >
                <ProgrammingLangIcon programmingLanguage={props.data.image} />
                {" "}
                {props.data.label}
            </span>
        </MultiValueContainer>
    }
    return <Formik
        initialValues={{ skills: skills }}
        validationSchema={jobSkillsValidaitonSchema}
        onSubmit={e => { submitForm(e) }}
    >
        {(props: FormikProps<any>) => (
            <Row className="mt-3">
                <Col xs={2} className="d-flex justify-content-center">
                    <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                        <div className="m-auto">{current + 1}</div>
                    </div>
                </Col>
                <Col xs={10}>
                    <h4>Choose the skills </h4>
                    <FormGroup>
                        <Label for="skills">
                            What role would like to see in your new hire?
                        </Label>
                        <Card>
                            <Row className="p-3">
                                <Col xs={2} className="p-2 text-center"><TfiInfoAlt fontSize={25} /></Col>
                                <Col xs={10}> Not sure what role to include? Skip ahead to ger help from Toptal domain experts.</Col>
                            </Row>
                        </Card>
                        {/* <Input className="mt-2" onChange={e => {
                            props.setFieldValue("skills", e.target.value);
                        }}
                            value={props?.values?.skills || ''}
                            invalid={props.errors?.skills !== undefined} />
                        <FormFeedback>
                            <>{props?.errors?.skills}</>
                        </FormFeedback> */}
                        <Select
                        className="mt-3"
                            styles={{
                                option: (defaultStyles, state) => ({
                                    ...defaultStyles,
                                    backgroundColor: (state.isFocused || state.isSelected) ? "#AFE581" : "#fff"
                                }),
                                control: (defaultStyles) => ({
                                    ...defaultStyles,
                                    borderColor: props.errors?.skills ? "#dc3545" : "#ced4da",
                                }),

                                singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
                            }}
                            name="skills"
                            closeMenuOnSelect={false}
                            onChange={(newValue) => {
                                props.setFieldValue("skills", newValue.length === 0 ? null : newValue);
                            }}
                            value={props?.values?.skills}
                            required
                            isSearchable
                            isMulti
                            onBlur={() => {
                                props?.setFieldTouched("skills", true)
                            }}
                            options={[
                                { value: "react", label: "React", image: "react" },
                                { value: "javascript", label: "javascript", image: "javascript" },
                                { value: "typescript", label: "TypeScript", image: "typescript" },
                                { value: "nodejs", label: "Node.js", image: "nodejs" },
                                { value: "amazonwebservices", label: "Aamazon Web Services", image: "amazonwebservices" },
                                { value: "mongodb", label: "MongoDB", image: "mongodb" },
                                { value: "nextjs", label: "Next.js", image: "nextjs" },
                                { value: "python", label: "Python", image: "python" },
                                { value: "aftereffects", label: "After Effects", image: "aftereffects" },
                                { value: "android", label: "Android", image: "android" },
                                { value: "angularjs", label: "Angular", image: "angularjs" },
                                { value: "java", label: "Java", image: "java" },
                                { value: "aarch64", label: "Aarch64", image: "aarch64" },
                                { value: "apache", label: "Apache", image: "apache" },
                                { value: "apple", label: "Apple", image: "apple" },
                                { value: "bootstrap", label: "Bootstrap", image: "bootstrap" },
                                { value: "c", label: "c", image: "c" },
                                { value: "cplusplus", label: "c++", image: "cplusplus" },

                            ]}
                            components={{
                                Option: IconOption,
                                SingleValue: IconSingleValue,
                                MultiValueContainer: ISelectContainer
                            }}
                        />
                        <div className="text-danger">
                            <>{props?.errors?.skills}</>
                        </div>
                    </FormGroup>
                    <Row className="mt-3 d-flex mb-3">
                        <div className="mb-3">
                            <span>Popular skiils <IoIosArrowDown /></span>
                        </div>
                        <Col className="d-flex flex-lg-wrap">
                            {icons.slice(0, 10).map((e: any, index: number) => {
                                return <div className="d-flex" key={index}>
                                    <button key={index} className="benefits"><ProgrammingLang programmingLanguage={e.name} /><div>{e.name}</div></button>
                                </div>
                            })}
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between">
                        <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                        <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                    </div>
                </Col>
            </Row>
        )}
    </Formik>
}
export default ChooseSkills;