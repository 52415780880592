import { createSlice } from '@reduxjs/toolkit'
import { IJobs } from '../../../types/Types';
import { getCompanyJobs } from './CompanyJob';
// import { getAccountDetails } from './AccountThunk';
interface IJobsDetails {
    id: string,
    title: String,
    image: string,
    salary: String,
    location: String,
    type: String,
    currency: string;
    highlights: string;
    skills: object;
    languages: String[],
    job_details: string;
    applications: String;
    applied: boolean;
    count: number;
}
interface IJobsState {
    jobs: IJobs[];
    isLoading: Boolean;
    isValid: Boolean;
    error: string | null;
    jobDetailsValid: boolean,
    jobDetails: IJobsDetails | null;
    success: boolean;
    jobId: string | null;
}

const jobsInitialState: IJobsState = {
    isLoading: false,
    error: null,
    isValid: false,
    success: false,
    jobDetailsValid: false,
    jobDetails: null,
    jobs: [],
    jobId: null,

}
export const CompanyJobsSlice = createSlice({
    name: "ComapnyJobs",
    initialState: jobsInitialState,
    reducers: {

    },
    extraReducers(builder) {
        builder.addCase(getCompanyJobs.pending, (state: IJobsState, action: {
            payload: any, type: string;
        }) => {
            state.isLoading = true;
            state.error = null;
            state.success = false;
        });
        builder.addCase(getCompanyJobs.fulfilled, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            state.jobs = action.payload;
            state.isValid = action.payload.is_valid;
            state.success = true;
        });
        builder.addCase(getCompanyJobs.rejected, (state: IJobsState, action: {
            payload: any;
            type: string;
        }) => {
            state.isLoading = false;
            //@ts-ignore
            state.error = action.payload?.error;
            state.jobs = [];
            state.success = false;
        });
    },
});
