import { Card, CardBody, Row } from "reactstrap";
import { IAccountMembers } from "../../../types/Types";
import TimeAgo from "timeago-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
interface IMemberDetailsProps {
    data: IAccountMembers
}
const MemberDetails = ({ data }: IMemberDetailsProps) => {
    const darkMode = useSelector((state: RootState) => state.user.darkMode)
    return <>
        <Row className="p-4 w-75">
            <Card className={' detail-view ' + (darkMode ? 'dark-mode ' : ' ')}>

                <CardBody className='profile-body'>
                    <div className='info'>
                        <img src={data.profile_picture ? data.profile_picture : '/Avatar.png'} alt='avatar' className='avatar' />
                    </div>
                    <div className='mt-3'>
                        <h2 className='username text-center'> {data.fname + ' ' + data.lname}</h2>
                    </div>
                    <div className='w-100 mt-3'>
                        <table className="profile_info">
                            <tbody>
                                <tr className="link">
                                    <td>
                                        Name
                                    </td>
                                    <td>
                                        {data.fname + ' ' + data.lname}
                                    </td>
                                </tr>
                                <tr className="link">
                                    <td>
                                        Email
                                    </td>
                                    <td>
                                        {data.email}
                                    </td>
                                </tr>
                                <tr className="link">
                                    <td>
                                        Mobile
                                    </td>
                                    <td>
                                        {data.mobile}
                                    </td>
                                </tr>
                                <tr className="link">
                                    <td>
                                        🌎 Nationality
                                    </td>
                                    <td>
                                        🇷🇺 Russia </td>
                                </tr>
                                <tr className="link">
                                    <td>
                                        🏡 Residency
                                    </td>
                                    <td>
                                        🇬🇪 Georgia </td>
                                </tr>
                                <tr className="link">
                                    <td>
                                        📍 Location
                                    </td>
                                    <td>
                                        🇷🇸 Serbia </td>
                                </tr>

                                <tr>
                                    <td>
                                        Last seen
                                    </td>
                                    <td>
                                        {data.createdAt ? <TimeAgo
                                            datetime={data.createdAt ? data.createdAt : Date.now()}
                                            locale='as-IN'
                                        /> : null}

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Signed up
                                    </td>
                                    <td>
                                        1 month ago
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Badges
                                    </td>
                                    <td>
                                        👨‍💻 Remote worker,
                                        🎖 Early adopter
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </CardBody>

            </Card>
        </Row>
    </>
}
export default MemberDetails;