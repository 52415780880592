import React from 'react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import { HiOutlineFolderOpen } from "react-icons/hi2";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap"
import { TbHeartPlus, TbHeartbeat, TbSunset2 } from "react-icons/tb";
import { GiMeditation } from 'react-icons/gi';
import { BsBook, BsCupHot } from "react-icons/bs";
import { SlScreenSmartphone } from "react-icons/sl";
import { CiBadgeDollar, CiDumbbell, CiParking1 } from 'react-icons/ci';
import { AiOutlineWoman } from "react-icons/ai";
import { IoIosBicycle } from 'react-icons/io';
import { SiFuturelearn } from 'react-icons/si';
import { FiSun } from "react-icons/fi";
import { BiDollarCircle } from "react-icons/bi";
import { MdAlarm, MdOutlineFastfood, MdOutlinePets, MdOutlineTheaterComedy } from 'react-icons/md';
import { RiHomeWifiLine, RiParentLine, RiTeamLine } from "react-icons/ri";
import { IoFastFoodOutline, IoGameControllerOutline, IoRibbonOutline } from "react-icons/io5";
import { Form, Formik, FormikProps } from 'formik';
import { jobDetailsValidationSchema } from '../../../Forms/CreateJobPost/CreateJobPost';

const benifits = [
    {
        icon: <MdAlarm fontSize={25} />,
        data: "Flexible work hours",
    },
    {
        icon: <RiTeamLine fontSize={25} />,
        data: "Team building activities",
    },
    {
        icon: <IoGameControllerOutline fontSize={25} />,
        data: "Gamezone",
    },
    {
        icon: <IoRibbonOutline fontSize={25} />,
        data: "Equity",
    },
    {
        icon: <TbSunset2 fontSize={25} />,
        data: "Extra days of holidays",
    },
    {
        icon: <TbHeartbeat fontSize={25} />,
        data: "Mental wellness budget",
    },
    {
        icon: <RiHomeWifiLine fontSize={25} />,
        data: "Home office",
    },
    {
        icon: <GiMeditation fontSize={25} />,
        data: "Relax zone",
    },
    {
        icon: <TbHeartPlus fontSize={25} />,
        data: "Diversity support",
    },
    {
        icon: <RiParentLine fontSize={25} />,
        data: "Parental benefits",
    },
    {
        icon: <MdOutlineTheaterComedy fontSize={25} />,
        data: "Financial contribution for cultural events",
    },
    {
        icon: <BsCupHot fontSize={25} />,
        data: "Cafeteria",
    },
    {
        icon: <SlScreenSmartphone fontSize={25} />,
        data: "Gadget of your choice"
    },
    {
        icon: <CiBadgeDollar fontSize={25} />,
        data: "Financial bonuses"
    },
    {
        icon: <AiOutlineWoman fontSize={25} />,
        data: "Women encouraged to apply"
    },
    {
        icon: <MdOutlinePets fontSize={25} />,
        data: "Pet friendly office"
    },
    {
        icon: <CiDumbbell fontSize={25} />,
        data: "Sports budget"
    },
    {
        icon: <IoIosBicycle fontSize={25} />,
        data: "Cyclist Welcome"
    },
    {
        icon: <IoFastFoodOutline fontSize={25} />,
        data: "Office meals"
    },
    {
        icon: <BsBook fontSize={25} />,
        data: "Learning budget"
    },
    {
        icon: <FiSun fontSize={25} />,
        data: "Work life balance"
    },
    {
        icon: <CiParking1 fontSize={25} />,
        data: "Parking"
    },
    {
        icon: <MdOutlineFastfood fontSize={25} />,
        data: "Free snacks"
    },
    {
        icon: <BiDollarCircle fontSize={25} />,
        data: "Salary bonuses"
    },
    {
        icon: <SiFuturelearn fontSize={25} />,
        data: "Career growth"
    }
]
interface IjobDetailsFormProps {
    current: number;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    jobPost: object;
    setJobPost: React.Dispatch<React.SetStateAction<object>>;
    filesObject: Object[];
    setFilesObject: React.Dispatch<React.SetStateAction<Object[]>>;
}
const JobDetailsForm = ({ current, setCurrent, jobPost, setJobPost, filesObject, setFilesObject }: IjobDetailsFormProps) => {
    //@ts-ignore
    const { job_details, howToApply, apply_link, apply_email, } = jobPost;
    // const fileInputRef = React.createRef();
    // const [files, setFiles] = React.useState<Object[]>(filesObject);
    // const fileChange = (event: any) => {
    //     event.preventDefault();
    //     setFiles([...event.target.files]);
    //     setFilesObject([...event.target.files]);
    // };
    // const objectToUrl = (img: any) => {
    //     const localImageUrl = URL.createObjectURL(img);
    //     return localImageUrl;
    // }
    const submitForm = (e: any) => {
        setCurrent(current => current + 1);
        setJobPost({ ...jobPost, ...e })
    }
    return <Formik
        initialValues={{
            // companyLogo: companyLogo,
            job_details: job_details,
            howToApply: howToApply,
            apply_link: apply_link,
            apply_email: apply_email,
        }}
        validationSchema={jobDetailsValidationSchema}
        onSubmit={e => { submitForm(e) }}
    >
        {(props: FormikProps<any>) => (
            <Form>
                <Row className="mt-3">
                    <Col xs={2} className="d-flex justify-content-center">
                        <div className="d-flex h4" style={{ backgroundColor: "#AFE581", borderRadius: "50%", height: 50, width: 50 }}>
                            <div className="m-auto">{current + 1}</div>
                        </div>
                    </Col>
                    <Col xs={10}>
                        <h4>Job details</h4>

                        <FormGroup>
                            {/* <Label for="logo">
                                Company Logo (.JPG OR .PNG, Square or Round)
                            </Label> */}
                            {/* <FileUpload /> */}
                            {/* <div>
                                <Card onClick={() =>
                                    //@ts-ignore
                                    fileInputRef.current.click()
                                }>
                                    <CardBody className='p-5 d-flex justify-content-center'>
                                        {files.length > 0 ? files.map((currFiles: any, index: number) => {
                                            return <img key={index} src={objectToUrl(currFiles)} width="100" alt="logo" />;
                                        }) : <h2><HiOutlineFolderOpen />Upload</h2>}
                                    </CardBody>
                                </Card>
                                <input
                                    type="file"
                                    accept="image/*"
                                    // name='companyLogo'
                                    //@ts-ignore
                                    ref={fileInputRef}
                                    onChange={event => {
                                        fileChange(event);
                                        console.log(event.target.files)
                                        props.setFieldValue("companyLogo", event.target.files?.[0])
                                    }}
                                    hidden
                                />
                                <div className="text-danger">
                                    <>{props?.errors?.companyLogo}</>
                                </div>
                            </div> */}
                        </FormGroup>
                        {/* <FormGroup check>
                            <Label for="highlightwithcolor">
                                <Input type="checkbox" aria-label="Highlight with your company brand colour" />Highlight with your company brand colour
                            </Label>
                            <Label for="theme">
                                <Input type="color" />
                            </Label>
                        </FormGroup> */}
                        <FormFeedback>
                        </FormFeedback>
                        <FormGroup>
                            <Label for="job_details">
                                Job Details
                            </Label>
                            <div className="editor">
                                <CKEditor
                                    //@ts-ignore
                                    editor={ClassicEditor}
                                    onBlur={(event, editor) => {
                                        props.setFieldTouched("job_details", true)
                                    }}
                                    onChange={(event, editor) => {
                                        //@ts-ignore
                                        props.setFieldValue("job_details", editor.getData());
                                    }}
                                    data={props.values?.job_details}
                                />
                            </div>
                            <div className="text-danger">
                                <>{props?.errors?.job_details}</>
                            </div>
                        </FormGroup>
                        <Row>
                            <Label for="benifits">
                                Benifits
                            </Label>
                        </Row>
                        {benifits.map((benifit: any, index: number) => {
                            return <Button key={index} className="w-auto m-2 benifitBtn">{benifit.icon}&nbsp;{benifit.data}</Button>
                        })}
                        <FormGroup>
                            <Label for="howToApply">
                                How to apply?
                            </Label>
                            <div className="editor">
                                <CKEditor
                                    //@ts-ignore
                                    editor={ClassicEditor}
                                    onChange={(event, editor) => {
                                        // setAbout(editor.getData());
                                        //@ts-ignore
                                        props.setFieldValue("howToApply", editor.getData());
                                    }}
                                    data={props.values?.howToApply}
                                />
                            </div>
                            <div className="text-danger">
                                <>{props?.errors?.howToApply}</>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="apply_url">
                                Apply URL
                            </Label>
                            <Input type="url" onChange={e => {
                                props.setFieldValue("apply_link", e.target.value);
                            }}
                                value={props?.values?.apply_link || ''}
                                invalid={props?.errors?.apply_link !== undefined} />
                            <FormFeedback>
                                <>{props?.errors?.apply_link}</>
                            </FormFeedback>
                        </FormGroup>
                        <Row className="justify-content-center">
                            or
                        </Row>
                        <FormGroup>
                            <Label for="apply_email">
                                Apply email address
                            </Label>
                            <Input type="email" onChange={e => {
                                props.setFieldValue("apply_email", e.target.value);
                            }}
                                value={props?.values?.apply_email || ''}
                                invalid={props?.errors?.apply_email !== undefined} />
                            <FormFeedback>
                                <>{props?.errors?.apply_email}</>
                            </FormFeedback>
                        </FormGroup>
                        <div className="d-flex justify-content-between">
                            <Button className="" onClick={() => { setCurrent(current => current - 1) }}>Back</Button>
                            <Button type="submit" color="primary" onClick={() => { props.submitForm() }}>Next</Button>
                        </div>
                    </Col>

                </Row>
            </Form>
        )}
    </Formik>
}
export default JobDetailsForm;